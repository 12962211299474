import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../../shared/common-service.service';
import { AdminService } from '../../shared/admin.service';
import { ApiService } from '../../shared/api.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Meta, Title } from '@angular/platform-browser';
import { mainConstants } from 'src/app/shared/constants/main.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginPassword: string;
  loginUsername: string;
  forgotUserName: string;
  submitted: boolean = false;
  showPass: boolean = false;
  loginErr: string;
  confirmloginPassword: string;
  sub: Subscription;
  curAccessToken$;
  title: string;
  loginId: number;
  isForgotPassword: boolean = false;
  isResetPassPage: boolean = false;
  resetPasswordObj: any;
  constructor(
    private commonService: CommonServiceService,
    private adminService: AdminService,
    private router: Router,
    private apiService: ApiService,
    private metaService: Meta,
    private titleService: Title,
    private activatedRouter: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['payload']) {
        console.log(params['payload']);
        console.log(this.commonService.base64ToBytes(params['payload']));
        this.isResetPassPage = true;
        this.resetPasswordObj = this.commonService.base64ToBytes(
          params['payload']
        );
        this.loginUsername = this.resetPasswordObj.email;
      }
    });
    // let fullUrl = this.router['location']?._platformLocation?.location.href;
    this.activatedRouter.data.subscribe((res) => {
      this.title = res['title'];
      this.loginId = res['id'];
    });
    // const title: string =
    //   this.loginId === 1
    //     ? 'AlgoEd Admin | Login'
    //     : this.isResetPassPage
    //     ? 'AlgoEd Organization | Reset Password'
    //     : 'AlgoEd Organization | Login';

    // this.metaService.updateTag({
    //   property: 'og:title',
    //   content: title,
    // });
    // this.titleService.setTitle(title);
    // this.metaService.updateTag({ property: 'og:url', content: fullUrl });

    this.sub = this.commonService.$curAccessToken.subscribe((item) => {
      if (item) {
        this.curAccessToken$ = item;
      }
    });

    this.setTitle();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setTitle() {
    const isOrganisationAdmin = this.router.url.includes(
      'organisation-admin-login'
    );
    const title = isOrganisationAdmin
      ? 'AlgoEd Organization | Login'
      : 'AlgoEd Admin | Login';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      property: 'og:title',
      content: `${title}`,
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: mainConstants.DESCRIPTIONS.DEFAULT,
    });
  }

  resetPassword() {
    if (!this.loginPassword) {
      this.commonService.showToast('Please enter password', 'warn', 3000);
      return;
    }
    if (this.loginPassword.length < 8) {
      this.commonService.showToast(
        'Password should be atleast 8 characters',
        'warn',
        3000
      );
      return;
    }
    if (this.confirmloginPassword != this.loginPassword) {
      this.commonService.showToast(
        'Password and Confirm password should be same',
        'warn',
        3000
      );
      return;
    }
    const data = {
      ...this.resetPasswordObj,
      password: this.loginPassword,
    };
    this.commonService.getResetLink('org/reset', { data: data }).subscribe(
      (res) => {
        this.commonService.showToast(
          'Password changed successfully.',
          'info',
          3000
        );
        this.isResetPassPage = false;
      },
      (error) => {
        this.commonService.showToast(error.error.error, 'error', 3000, 'Error');
      }
    );
  }

  adminLogin() {
    if (this.isResetPassPage) {
      this.resetPassword();
      return;
    } else {
    }
    if (!this.loginPassword || !this.loginUsername) {
      return;
    }

    if (!this.curAccessToken$) {
      this.commonService.showToast('Please wait...', 'info', 3000, 'Info');
      return;
    }
    this.submitted = true;

    this.adminService
      .adminLogin(
        {
          data: { email: this.loginUsername, password: this.loginPassword },
        },
        this.loginId
      )
      .subscribe(
        (item) => {
          this.commonService.showToast('Login successful.', 'success', 3000);
          //changed
          localStorage.setItem('token', item.token);
          localStorage.setItem('user', JSON.stringify(item.data));
          this.commonService.$curUser.next(item.data);
          this.commonService.$curUserToken.next(item.token);
          this.commonService.$curAccessToken.next(item.token);
          localStorage.setItem('adToken', item.token);
          //changed
          if (this.loginId == 1) {
            this.router.navigate(['/admin/dashboard']);
          } else {
            this.router.navigate(['organisation/app-shell']);
          }
        },
        (err) => {
          this.setTitle();
          this.commonService.showToast('Login failed!', 'warn', 3000);

          this.submitted = false;
          this.loginPassword = '';
          this.loginUsername = '';

          console.log(err);
        }
      );
  }

  forgotPassword() {
    if(!this.forgotUserName){
      this.commonService.showToast('Please enter email', 'warn', 3000);
      return
    }
    const data = {
      email: this.forgotUserName,
    };
    this.apiService.getResetLink({ data: data }).subscribe(
      (res) => {
        this.commonService.showToast(
          'Reset link sent to your email',
          'success',
          3000
        );
        this.isForgotPassword = false;
        this.forgotUserName = '';
      },
      (err) => {
        this.commonService.showToast(
          err?.error?.error ?? 'Please try again later',
          'error',
          3000
        );
      }
    );
  }

  showPassFn() {
    this.showPass = !this.showPass;
  }
}
