import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { categoryResponse } from '../students/models/students.interface';
import { Competitions } from '../student-competitions/models/competions.models';
export interface IuploadTask {
  api$: Observable<any>;
  metadata: {
    name: string;
    type: string;
    from: string;
    taskId?: string;
    isComplete?: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CommonServiceService {
  ref: DynamicDialogRef;
  blockSpecial: RegExp = /^[^<>*!#%^&()+/:;|"{}]+$/;

  token = localStorage.getItem('token') || null;
  access_token = localStorage.getItem('access_token') || null;
  user = JSON.parse(JSON.stringify(localStorage.getItem('user') ?? '')) || null;
  question_bank_answers =
    JSON.parse(localStorage.getItem('question_bank_answers')) || null;
  subscriptionPlan =
    JSON.parse(localStorage.getItem('subscriptionPlan')) || null;
  $curUserToken = new BehaviorSubject<string>(this.token);
  $curAccessToken = new BehaviorSubject<string>(this.access_token);
  $curManageStudentsViewStudent = new BehaviorSubject<any>(null);
  $curUser = new BehaviorSubject<any>(this.user);
  $questionBankAnswers = new BehaviorSubject<any>(this.question_bank_answers);
  updateProgress$ = new Subject<any>();
  readMessages$ = new Subject<any>();
  $essayReviewType: BehaviorSubject<any> = new BehaviorSubject<string>(null);

  currentURL: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $selectedContry: BehaviorSubject<string> = new BehaviorSubject<string>('US');
  $selectedGroup: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isSideMenuShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  $organisationDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  $competitionDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  $isCompetitionRegistered: BehaviorSubject<Competitions> =
    new BehaviorSubject<Competitions>(null);

  standardizedTestDataToPatch: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  studentDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  $isContinueButtonVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  baseURL: string = environment.apiUrl;

  fileUploadTasks = JSON.parse(localStorage.getItem('uploadTasks')) || [];

  callChildMethod: Subject<any> = new Subject<any>();

  isBackFromTeamDetails: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  judgesTabChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isSubmissionTabChanged: BehaviorSubject<number> = new BehaviorSubject<number>(
    1
  );

  getSubmissionTabChanged(): Observable<number> {
    return this.isSubmissionTabChanged.asObservable();
  }

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    public dialogService: DialogService
  ) {
    this.$curUserToken.subscribe((item) => {
      if (typeof item == null) {
        this.clearSessionVar('token');
      }
    });
    this.$curAccessToken.subscribe((item) => {
      if (typeof item == null) {
        this.clearSessionVar('access_token');
      }
    });
    this.$curUser.subscribe((item) => {
      if (typeof item == null) {
        this.clearSessionVar('user');
      }
    });
    this.$questionBankAnswers.subscribe((item) => {
      if (typeof item == null) {
        this.clearSessionVar('question_bank_answers');
      }
    });
  }

  stripHtml(html: string): string {
    // Create a temporary DOM element and set its innerHTML to the provided HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Return the text content, stripping away all HTML tags
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  triggerChildMethod(): Observable<any> {
    return this.callChildMethod.asObservable();
  }

  setSelectedCountry(countryName: string) {
    this.$selectedContry.next(countryName);
  }

  bindNgModelValues(data, keyName: string) {
    return (
      (data &&
        data[keyName] &&
        data[keyName].length &&
        data[keyName].split(',').map((item) => +item)) ??
      []
    );
  }

  getSelectedCountry(): Observable<string> {
    return this.$selectedContry.asObservable();
  }

  convertIfNumeric(value: string): any {
    if (value) {
      return isNaN(Number(value)) || value.trim() === ''
        ? value
        : Number(value);
    } else {
      return value;
    }
  }

  getCountries(object?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}common/countries`);
    // return this.http.post(`${this.baseURL}/getCountries`, object); prod
  }

  showToast(
    detail: string,
    severity: 'success' | 'error' | 'warn' | 'info',
    life: number,
    summary?: string
  ) {
    this.messageService.add({ detail, severity, life, summary });
  }

  /**
   * Validates the given form and shows a toast message if any required field is missing.
   *
   * @param {FormGroup} formName - The form to validate.
   * @param {...{ name: string; controlName: string }[]} controlNames - The names and control names of the required fields.
   * @return {boolean} - Returns true if all required fields are present, false otherwise.
   */
  validateAndShowErrorMessage(
    formName: FormGroup,
    ...controlNames: { name: string; controlName: string }[]
  ): boolean {
    for (const control of controlNames) {
      if (
        formName.controls[control.controlName] &&
        formName.controls[control.controlName]?.errors &&
        formName.controls[control.controlName]?.errors['required']
      ) {
        this.showToast(`Please enter ${control.name}`, 'error', 3000);
        return false;
      }
    }
    return true;
  }

  changeNotificationStatus(id: any): Observable<any> {
    return this.http.put(`${this.baseURL}users/notifications/${id}`, {});
  }

  base64ToBytes(base64) {
    return JSON.parse(atob(base64));
  }

  clearSessionVar(key) {
    localStorage.removeItem(key);
  }

  logout() {
    localStorage.clear();
    localStorage.clear();
    this.$curUserToken.next(null);
    this.$curUser.next(null);
    this.$questionBankAnswers.next(null);
  }

  getAllCategories(queryParamsFields: any): Observable<categoryResponse> {
    let params = new HttpParams();

    if (queryParamsFields?.countryShortCode) {
      params = params.set(
        'countryShortCode',
        queryParamsFields.countryShortCode
      );
    }

    return this.http.get<categoryResponse>(`${this.baseURL}admin/categories`, {
      params,
    });
  }

  getAllCategoriesStudent(
    queryParamsFields: any,
    publicOrPrivatePathForCategory: string
  ): Observable<categoryResponse> {
    let params = new HttpParams();

    if (queryParamsFields?.countryShortCode) {
      params = params.set(
        'countryShortCode',
        queryParamsFields.countryShortCode
      );
    }

    return this.http.get<categoryResponse>(
      `${this.baseURL}${publicOrPrivatePathForCategory}`,
      {
        params,
      }
    );
  }

  getFileFromUrl(url) {
    return this.http.get(url);
  }

  getPaymentResult(session_id) {
    return this.http.get(
      `${this.baseURL}users/stripe?session_id=${session_id}`
    );
  }

  getPaymentForCompetition(session_id) {
    return this.http.get(
      `${this.baseURL}users/competitions/payment/finalize?session_id=${session_id}`
    );
  }

  finalizeEssayPayment(sessionId: string) {
    return this.http.get(
      `${this.baseURL}users/essay/edits/finalize?session_id=${sessionId}`
    );
  }

  show(component, data) {
    return (this.ref = this.dialogService.open(component, data));
  }

  async fileUploader(taskItem: IuploadTask) {
    // api$:Observable<any>,metadata:{name:string, type:string,from:string}
    this.showToast(
      "File upload has started, you'll be notified once its complete!",
      'info',
      2500
    );
    // this.show(BackgroundUploadHandlerComponent, {
    //   width: window.innerWidth > 600 ? '50vw' : '95vw',
    // });
    this.fileUploadTasks.push({
      metadata: taskItem.metadata,
      progress: 0,
      key: this.fileUploadTasks.length + 1,
    });
    let curIndex = this.fileUploadTasks.length - 1;

    return await new Promise((resolve, reject) => {
      taskItem.api$.subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.fileUploadTasks[curIndex].progress = Math.round(
              (event.loaded / event.total) * 100
            );
            this.updateProgress$.next(this.fileUploadTasks);

            break;
          case HttpEventType.Response:
            localStorage.setItem(
              'uploadTasks',
              JSON.stringify(this.fileUploadTasks)
            );
            //   setTimeout(() => {
            //     this.progress = 0;
            //   }, 1500);
            this.showToast('File upload complete', 'info', 2500);

            return resolve(event.body);
        }
      });
    });
  }

  getFiveDayIntervalsForCurrentMonth(): string[] {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const lastDateOfMonth = new Date(year, month + 1, 0).getDate();

    const intervals: string[] = [];
    for (let startDay = 1; startDay <= lastDateOfMonth; startDay += 5) {
      intervals.push(`${startDay}`);
      console.log(startDay);
    }
    intervals.push(`${lastDateOfMonth}`);
    return intervals;
  }

  downloadExcelFile(id: number): Observable<Blob> {
    const url = `admin/competitions/judges/export/${id}`;
    return this.http.get(`${this.baseURL}${url}`, { responseType: 'blob' });
  }

  getList(
    path: string,
    queryParamsFields?: {
      page?: number;
      pageSize?: number;
      searchText?: string;
      q?: string;
      studentsMin?: number;
      studentsMax?: number;
      spentMinMonth?: number;
      spentMaxMonth?: number;
      spentMinYear?: number;
      spentMaxYear?: number;
      name?: string;
      countryShortCode?: string;
      category?: number | string;
      country?: string;
      gradYear?: number;
      email?: string;
      spentMin?: number;
      spentMax?: number;
      NextToken?: string;
      review_type?: string;
      status?: string;
      asc?: string;
      results?: number;
      show_all?: number;
      payment_status?: string;
      team_size?: number;
      showall?: number;
      registration_round?: string;
      start_date?: string;
      end_date?: string;
      registrationStatus?: string;
      schoolCategory?: string;
      teamSize?: string;
      competition_id?: number;
    },
    isPaginated: boolean = true
  ): Observable<any> {
    let params = new HttpParams();

    if (isPaginated) {
      if (queryParamsFields?.showall) {
        params = params.set('showall', queryParamsFields.showall);
      }

      if (queryParamsFields?.payment_status) {
        params = params.set('payment_status', queryParamsFields.payment_status);
      }

      if (queryParamsFields?.team_size) {
        params = params.set('team_size', queryParamsFields.team_size);
      }

      if (queryParamsFields?.studentsMin) {
        params = params.set('studentsMin', queryParamsFields.studentsMin);
      }

      if (queryParamsFields?.studentsMax) {
        params = params.set('studentsMax', queryParamsFields.studentsMax);
      }

      if (queryParamsFields?.spentMinMonth) {
        params = params.set('spentMinMonth', queryParamsFields.spentMinMonth);
      }
      if (queryParamsFields?.spentMaxMonth) {
        params = params.set('spentMaxMonth', queryParamsFields.spentMaxMonth);
      }

      if (queryParamsFields?.spentMinYear) {
        params = params.set('spentMinYear', queryParamsFields.spentMinYear);
      }
      if (queryParamsFields?.spentMaxYear) {
        params = params.set('spentMaxYear', queryParamsFields.spentMaxYear);
      }

      if (queryParamsFields?.page) {
        params = params.set('page', queryParamsFields.page);
      }

      if (queryParamsFields?.pageSize) {
        params = params.set('pageSize', queryParamsFields.pageSize);
      }

      if (queryParamsFields?.searchText) {
        params = params.set('searchText', queryParamsFields.searchText);
      }
      if (queryParamsFields?.q) {
        params = params.set('q', queryParamsFields.q);
      }

      if (queryParamsFields?.countryShortCode) {
        params = params.set(
          'countryShortCode',
          queryParamsFields.countryShortCode
        );
      }

      if (queryParamsFields?.country) {
        params = params.set('country', queryParamsFields.country);
      }

      if (queryParamsFields?.category) {
        params = params.set('category', queryParamsFields.category);
      }
      if (queryParamsFields?.name) {
        params = params.set('name', queryParamsFields.name);
      }
      if (queryParamsFields?.gradYear) {
        params = params.set('gradYear', queryParamsFields.gradYear);
      }
      if (queryParamsFields?.email) {
        params = params.set('email', queryParamsFields.email);
      }
      if (queryParamsFields?.spentMin) {
        params = params.set('spentMin', queryParamsFields.spentMin);
      }
      if (queryParamsFields?.spentMax) {
        params = params.set('spentMax', queryParamsFields.spentMax);
      }
      if (queryParamsFields?.NextToken) {
        params = params.set('NextToken', queryParamsFields.NextToken);
      }

      if (queryParamsFields?.review_type) {
        params = params.set('review_type', queryParamsFields.review_type);
      }
      if (queryParamsFields?.status) {
        params = params.set('status', queryParamsFields.status);
      }
      if (queryParamsFields?.asc) {
        params = params.set('asc', queryParamsFields.asc);
      }
      if (queryParamsFields?.results) {
        params = params.set('results', queryParamsFields.results);
      }
      if (queryParamsFields?.show_all) {
        params = params.set('show_all', queryParamsFields.show_all);
      }
      if (queryParamsFields?.registration_round) {
        params = params.set(
          'registration_round',
          queryParamsFields.registration_round
        );
      }
      if (queryParamsFields?.start_date) {
        params = params.set('start_date', queryParamsFields.start_date);
      }
      if (queryParamsFields?.end_date) {
        params = params.set('end_date', queryParamsFields.end_date);
      }
      if (queryParamsFields?.registrationStatus) {
        params = params.set(
          'registrationStatus',
          queryParamsFields.registrationStatus
        );
      }

      if (queryParamsFields?.schoolCategory) {
        params = params.set('schoolCategory', queryParamsFields.schoolCategory);
      }

      if (queryParamsFields?.teamSize) {
        params = params.set('teamSize', queryParamsFields.teamSize);
      }
      if (queryParamsFields?.competition_id) {
        params = params.set('competition_id', queryParamsFields.competition_id);
      }
    }
    return this.http.get<any>(`${this.baseURL}${path}`, { params });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseURL}admin/categories/${id}`);
  }

  getQuestionDetails(path: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}${path}`);
  }

  getByIdDetail(path: string, id: number): Observable<any> {
    return this.http.get<any>(`${this.baseURL}${path}${id}`);
  }

  updateRecord(path: string, data: any): Observable<any> {
    return this.http.put(`${this.baseURL}${path}`, data);
  }

  saveRecord(path: string, data: any): Observable<any> {
    return this.http.post(`${this.baseURL}${path}`, data);
  }

  deleteRecord(path: string, id?: number): Observable<any> {
    return this.http.delete(`${this.baseURL}${path}${id}`);
  }

  getResetLink(path: string, data: any): Observable<any> {
    return this.http.post(`${this.baseURL}${path}`, data);
  }

  editStudentDataFromStudentProfile(object: any): Observable<any> {
    return this.http.put(`${this.baseURL}users/profile`, object);
  }

  getByNameDetail(path: string, name: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}${path}${name}`);
  }

  patchData(path: string, data: any): Observable<any> {
    return this.http.patch(`${this.baseURL}${path}`, data);
  }

  getVideoTitle() {
    const oEmbedUrl = `https://www.youtube.com/oembed?url=${encodeURIComponent(
      'https://www.youtube.com/watch?v=t5akgsQsOSk'
    )}&format=json`;
    const corsProxy = 'https://cors-anywhere.herokuapp.com/';
    const apiUrl = `${corsProxy}${oEmbedUrl}`;
    return this.http.get(apiUrl);
  }
}
