import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  baseURL: string = environment.apiUrl + 'admin';

  constructor(private http: HttpClient) {}

  //adminLogin
  adminLogin(object: any, loginId): Observable<any> {
    if (loginId === 1) {
      return this.http.post(`${this.baseURL}/adminLogin`, object);
    } else {
      const arr: any[] = this.baseURL.split('/');
      let updatedBaseURL: string = arr.splice(0, arr.length - 1).join('/');
      return this.http.post(`${updatedBaseURL}/org/login`, object);
    }
  }

  //get Payment Failed Data of user
  getPaymentFailedData(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getPaymentFailedData`, object);
  }

  // add credits in user's acc
  addCredit_purchasedForUser_byAdmin(object): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addCredit_purchasedForUser_byAdmin`,
      object
    );
  }

  getEssaySubmissionComment(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getEssaySubmissionComment`, obj);
  }

  cancelEssaySubmission(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/cancelEssaySubmission`, obj);
  }

  cancelActivitySubmission(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/cancelActivitySubmission`, obj);
  }

  getAllQuestions(obj): Observable<any> {
    return this.http.get(`${this.baseURL}/questionbank`, obj);
  }

  getActiveQuestions(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getActiveQuestions`, obj);
  }

  getActiveQuestionsAnswersForVideo(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getActiveQuestionsAnswersForVideo`,
      obj
    );
  }

  addVideoQuestion(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addVideoQuestion`, obj);
  }

  getVideoQuestion(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getVideoQuestion`, obj);
  }

  removeVideoQuestion(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/removeVideoQuestion`, obj);
  }

  createQuestion(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/questionbank`, obj);
  }

  addAnswer(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addAnswer`, obj);
  }

  addSubQuestionForComprehensive(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addSubQuestionForComprehensive`,
      obj
    );
  }

  deleteAnswer(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteAnswer`, obj);
  }

  updateQuestion(obj): Observable<any> {
    return this.http.put(
      `${this.baseURL}/questionbank/${obj?.name?.questionId}`,
      obj
    );
  }
  getAnswers(obj): Observable<any> {
    return this.http.get(
      `${this.baseURL}/questionbank/answers/${obj?.data?.questionId}`,
      obj
    );
  }

  updateCalendarActivityStatus(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateCalendarActivityStatus`, obj);
  }
  cancelOtherSubmission(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/cancelOtherSubmission`, obj);
  }

  deleteQuestion(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteQuestion`, obj);
  }

  deleteRequestFormFile(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteRequestFormFile`, object);
  }
  getRequestForms(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestForms`, object);
  }
  addUpdateRequestFormType(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addUpdateRequestFormType`, object);
  }

  handleFailedTransactionByAdmin(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/handleFailedTransactionByAdmin`,
      object
    );
  }

  getUserBreakdownByCountryAndYear(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getUserBreakdownByCountryAndYear`,
      object
    );
  }
}
