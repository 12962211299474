<div class="card download-award-card">
  <div
    class="card-header d-flex align-items-center justify-content-center gap-2"
  >
    <span class="icon icon-lock-default"></span>
    <span>This section is only visible to you</span>
  </div>
  <div class="card-body d-flex flex-column gap-3">
    <h6 class="card-title m-0">Download award as</h6>

    <div class="d-flex flex-lg-column gap-3">
      <!-- pdf download btn -->
      <button class="btn w-100 btn-primary" (click)="pdfDownload()">
        PDF <span class="icon icon-download"></span>
      </button>
      <!-- End: pdf download btn -->

      <!-- JPG download btn -->
      <button class="btn w-100 btn-outline-primary" (click)="downloadImage()">
        JPG <span class="icon icon-download"></span>
      </button>
      <!-- End: JPG download btn -->
    </div>

    <!-- Text -->
    <!-- <div>
      <p class="m-0 fw-medium">
        Need Changes?
        <a href="" class="text-primary">Request Changes</a>
      </p>
    </div> -->
    <!-- End: Text -->
  </div>
</div>
