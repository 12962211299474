import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { mainConstants } from 'src/app/shared/constants/main.constants';
import { GoogleService } from 'src/app/shared/google.service';
import { environment } from 'src/environments/environment';

declare const google: any;
@Component({
  selector: 'app-student-signup',
  templateUrl: './student-signup.component.html',
  styleUrls: ['./student-signup.component.scss'],
})
export class StudentSignupComponent {
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.checkForEmailQueryParams();
    this.addAndRemoveValidators();
  }
  singupForm: FormGroup;
  isEmailEntered: boolean = false;
  showPass: boolean;
  stepNumber: number = 1;
  redirectLinks = mainConstants.redirectLinks;
  id: number;
  otp: any;
  emailid: string;
  isFromLogin: boolean = true;
  otpTimer: string = `00:60`;
  otpTimerNum = 60;
  otpTimerInterval;
  unverfiedUser: any;
  marketingLabel: string = mainConstants.labels.marketingLabel;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonServiceService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private googleService: GoogleService
  ) {}

  ngOnInit() {
    this.singupForm = this.buildForm();
    this.checkForEmailQueryParams();
    this.checkForEmailIdParams();
    this.loadGoogleScript();
  }

  ngAfterViewInit(): void {
    this.loadGoogle();
  }

  loadGoogleScript() {
    const script2 = this.googleService.createScriptElement();
    script2.onload = this.loadGoogle;
    document.head.appendChild(script2);
  }

  loadGoogle() {
    console.log('Google Loaded');
    window['handleCredentialResponse'] = (response) =>
      this.handleCredentialResponse(response);
    google &&
      google.accounts.id.initialize({
        client_id:
          environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
      });

    google &&
      google.accounts.id.renderButton(
        document.getElementsByClassName('g_id_signin')[0],
        { theme: 'outline', size: 'large' }
      );
  }

  handleCredentialResponse(response: any): void {
    const idToken = response.credential;
    this.googleService.sendToken(idToken).subscribe(
      (data) => {
        this.commonService.$curUser.next(data.data);
        this.commonService.$curUserToken.next(data.token);
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.data));
        setTimeout(() => {
          this.router.navigate(['/student/admission/US/dashboard']);
        }, 700);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  addAndRemoveValidators() {
    switch (this.stepNumber) {
      case 1:
        this.singupForm.controls['email'].setValidators([
          Validators.required,
          Validators.email,
        ]);
        this.singupForm.controls['fullName'].setValidators(null);
        this.singupForm.controls['password'].setValidators(null);
        break;
      case 2:
        this.singupForm.controls['password'].setValidators([
          Validators.required,
          Validators.minLength(8),
        ]);
        this.singupForm.controls['fullName'].setValidators([
          Validators.required,
        ]);
        break;
      case 3:
        this.singupForm.controls['fullName'].setValidators(null);
        this.singupForm.controls['password'].setValidators(null);
        this.singupForm.controls['email'].setValidators(null);
        break;
    }
    this.singupForm.controls['email'].updateValueAndValidity();
    this.singupForm.controls['fullName'].updateValueAndValidity();
    this.singupForm.controls['password'].updateValueAndValidity();
  }

  checkForEmailIdParams() {
    this.id = this.router.url.split('/')[2]
      ? parseInt(this.router.url.split('/')[2])
      : null;
    this.stepNumber = this.id ? 3 : this.stepNumber == 2 ? 2 : 1;
    if (this.stepNumber == 3) {
      this.sendOtp();
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  sendOtp() {
    const data = {
      data: { email: this.emailid, userId: this.id },
    };
    this.commonService.saveRecord('users/sendVerificationCode', data).subscribe(
      (res) => {
        this.singupForm.controls['password'].removeValidators(null);
        this.unverfiedUser =
          localStorage.getItem('unverfiedUser') &&
          JSON.parse(localStorage.getItem('unverfiedUser'));
        this.singupForm.patchValue({
          fullName:
            this.unverfiedUser?.fullName + ' ' + this.unverfiedUser?.lastName,
          email: this.unverfiedUser?.email,
          password: this.unverfiedUser?.password,
          sendMarketingEmails: !this.unverfiedUser?.sendMarketingEmails,
        });

        this.commonService.showToast('OTP sent successfully', 'success', 2000);
        this.stepNumber = 3;
        this.startTimer();
      },
      (error) => {
        this.commonService.showToast(
          error?.error?.message ?? 'There was an error sending OTP',
          'error',
          2000
        );
        this.stepNumber == 1;
      }
    );
  }

  checkForEmailQueryParams() {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['email']) {
        this.singupForm.get('email').setValue(params['email']);
        this.singupForm.get('email').disable();
        this.isEmailEntered = true;
        this.stepNumber = 2;
        this.unverfiedUser =
          localStorage.getItem('unverfiedUser') &&
          JSON.parse(localStorage.getItem('unverfiedUser'));
        console.log(this.unverfiedUser);
        this.unverfiedUser &&
          this.singupForm.patchValue({
            fullName:
              (this.unverfiedUser?.firstName ?? this.unverfiedUser?.fullName) +
              ' ' +
              this.unverfiedUser?.lastName,
            email: this.unverfiedUser?.email,
            password: this.unverfiedUser?.password,
            sendMarketingEmails: !this.unverfiedUser?.sendMarketingEmails,
          });
        const controlsToRequired = ['fullName', 'password'];
        controlsToRequired.forEach((control) => {
          if (control == 'password') {
            this.singupForm.controls[control].setValidators([
              Validators.required,
              Validators.minLength(8),
            ]);
            console.log(this.singupForm);
          } else {
            this.singupForm.controls[control].setValidators(
              Validators.required
            );
          }
        });
      } else {
        this.stepNumber = this.id ? 3 : 1;
        this.singupForm.get('email').enable();
        this.isEmailEntered = false;
        this.stepNumber == 1 && this.loadGoogle();
      }
      if (params['emailid']) {
        this.emailid = params['emailid'];
      }
      if (params['from'] == 'login') {
        this.isFromLogin = false;
      }
    });
  }

  goBack() {
    switch (this.stepNumber) {
      case 2:
        this.stepNumber = 1;
        this.loadGoogle();
        this.router.navigate(['/signup']);
        break;
      case 3:
        this.stepNumber = 2;
        this.router.navigate([`/signup`], {
          queryParams: { email: this.unverfiedUser.email },
        });
        break;
    }
    this.addAndRemoveValidators();
  }

  showPassFn() {
    this.showPass = !this.showPass;
  }

  buildForm(): FormGroup {
    return this.fb.group({
      fullName: [null],
      email: [null, [Validators.required, Validators.email]],
      password: [null],
      sendMarketingEmails: [false],
      otp: [],
    });
  }

  get password() {
    return this.singupForm.get('password');
  }

  navigatetoForm() {
    this.router.navigate(['/signup'], {
      queryParams: { email: this.singupForm.value.email },
    });
  }

  navigateToVerifyEmail(record: any) {
    record?.data &&
      localStorage.setItem('unverfiedUser', JSON.stringify(record.data));
    record?.data && localStorage.setItem('user', JSON.stringify(record.data));

    this.commonService.$curUser.next(record.data);
    this.commonService.$curUserToken.next(record.token);
    this.router.navigate([`/signup/${record.data.id}`], {
      queryParams: { emailid: this.singupForm.getRawValue().email },
    });
  }

  navigateToLogin() {
    localStorage.setItem('user', null);
    localStorage.setItem('unverfiedUser', null);
    this.router.navigate(['/login']);
  }

  signInWithGoogle() {}

  verifyOTP() {
    const data = {
      data: {
        email: this.singupForm.value.email,
        otpCode: this.otp,
        userId: this.id,
      },
    };
    this.commonService
      .saveRecord('users/checkVerificationCode', data)
      .subscribe(
        (res) => {
          // console.log(this.unverfiedUser);
          this.unverfiedUser.isVerifiedEmail = true;
          localStorage.setItem('user', JSON.stringify(this.unverfiedUser));
          this.commonService.$curUser.next(this.unverfiedUser);
          localStorage.setItem('unverfiedUser', null);
          setTimeout(() => {
            this.router.navigate(['/student/admission/US/dashboard']);
          }, 300);
          this.commonService.showToast(
            'OTP verified successfully',
            'success',
            2000
          );
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Invalid OTP',
            'error',
            2000
          );
        }
      );
  }

  createAccount() {
    if (this.stepNumber == 3) {
      if (!this.otp) {
        this.commonService.showToast('Please enter OTP', 'warn', 3000);
        return;
      }
      if (this.otp?.length != 6) {
      }
      this.verifyOTP();
      return;
    }
    if (this.singupForm.valid) {
      let path: string;
      let methodName: string;
      let data: any = {};
      let successMessage: string;
      const service: CommonServiceService = this
        .commonService as CommonServiceService;
      switch (this.stepNumber) {
        case 1:
          path = 'users/preSignUp';
          methodName = 'saveRecord';
          data = {
            email: this.singupForm.value.email,
          };
          successMessage = 'Email saved successfully';
          break;
        case 2:
          const splittedString = this.singupForm.value.fullName
            .trim()
            .split(' ');
          const splittedFullName = {
            firstName:
              splittedString && splittedString.length && splittedString[0],
            lastName:
              splittedString &&
              splittedString.length &&
              splittedString.length > 1
                ? splittedString.slice(1).join(' ')
                : '',
          };
          if (splittedFullName.lastName == '' || !splittedFullName.lastName) {
            this.commonService.showToast('Please enter Lastname', 'warn', 3000);
            return;
          }

          data = {
            data: {
              email: this.singupForm.getRawValue().email,
              password: this.singupForm.value.password,
              firstName: splittedFullName.firstName,
              lastName: splittedFullName.lastName,
              sendMarketingEmails: !this.singupForm.value.sendMarketingEmails,
            },
          };

          this.commonService.validateAndShowErrorMessage(
            this.singupForm,
            { name: 'Full Name', controlName: 'fullName' },
            { name: 'Password', controlName: 'password' }
          );
          successMessage = 'Account created successfully';
          methodName = 'saveRecord';
          path = 'users/newSignup';
          break;
        case 3:
          path = 'users/updateAccountInfo';
          break;
      }

      service[methodName](path, data).subscribe(
        (res) => {
          this.commonService.showToast(successMessage, 'success', 3000);
          if (this.stepNumber == 1) {
            localStorage.setItem('unverfiedUser', null);
            this.navigatetoForm();
          }
          if (res?.data) {
            this.navigateToVerifyEmail(res);
            localStorage.setItem('token', res.token);
          }
        },
        (err) => {
          this.commonService.showToast(
            err?.error?.details ?? 'Please try again later',
            'error',
            3000
          );
        }
      );
    }
  }
  startTimer() {
    if (this.otpTimerInterval) {
      clearInterval(this.otpTimerInterval);
    }

    this.otpTimerInterval = setInterval(() => {
      if (this.otpTimerNum > 0) {
        this.otpTimerNum--;
        this.otpTimer = `00:${this.otpTimerNum.toLocaleString(undefined, {
          minimumIntegerDigits: 2,
        })}`;
      }
      if (this.otpTimerNum == 0) {
        this.otpTimerNum = 60;
        clearInterval(this.otpTimerInterval);
      }
    }, 1000);
  }
}
