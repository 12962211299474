<div>
  <div>
    <svg
      width="115"
      height="124"
      viewBox="0 0 115 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.3502 10.6517C74.6575 9.95074 73.7179 9.55685 72.7381 9.55664H26.0516C24.0918 9.55664 22.2122 10.3441 20.8264 11.7459C19.4406 13.1477 18.6621 15.0489 18.6621 17.0313V106.727C18.6621 108.709 19.4406 110.611 20.8264 112.012C22.2122 113.414 24.0918 114.202 26.0516 114.202H92.5566C94.5164 114.202 96.3959 113.414 97.7817 112.012C99.1675 110.611 99.946 108.709 99.946 106.727V71.9026V37.0783C99.9458 36.0872 99.5564 35.1367 98.8635 34.436L75.3502 10.6517Z"
        fill="#E3E3E3"
      />
      <path
        d="M73.3713 12.0586H24.9237C23.9105 12.0586 22.9388 12.4611 22.2224 13.1775C21.506 13.8939 21.1035 14.8656 21.1035 15.8788V107.563C21.1035 108.576 21.506 109.548 22.2224 110.264C22.9388 110.981 23.9105 111.383 24.9237 111.383H93.687C94.7002 111.383 95.6719 110.981 96.3883 110.264C97.1047 109.548 97.5072 108.576 97.5072 107.563V36.7102L73.3713 12.0586Z"
        fill="#F7F7F8"
      />
      <path
        d="M34.4706 46.4375H84.133C84.6396 46.4375 85.1254 46.6387 85.4837 46.997C85.8419 47.3552 86.0431 47.841 86.0431 48.3476C86.0431 48.8542 85.8419 49.34 85.4837 49.6982C85.1254 50.0564 84.6396 50.2577 84.133 50.2577H34.4706C33.9641 50.2577 33.4782 50.0564 33.12 49.6982C32.7618 49.34 32.5605 48.8542 32.5605 48.3476C32.5605 47.841 32.7618 47.3552 33.12 46.997C33.4782 46.6387 33.9641 46.4375 34.4706 46.4375ZM34.4706 57.8981C33.9641 57.8981 33.4782 58.0993 33.12 58.4575C32.7618 58.8157 32.5605 59.3016 32.5605 59.8081C32.5605 60.3147 32.7618 60.8006 33.12 61.1588C33.4782 61.517 33.9641 61.7182 34.4706 61.7182H84.133C84.6396 61.7182 85.1254 61.517 85.4837 61.1588C85.8419 60.8006 86.0431 60.3147 86.0431 59.8081C86.0431 59.3016 85.8419 58.8157 85.4837 58.4575C85.1254 58.0993 84.6396 57.8981 84.133 57.8981H34.4706ZM32.5605 71.2687C32.5605 70.7621 32.7618 70.2763 33.12 69.9181C33.4782 69.5598 33.9641 69.3586 34.4706 69.3586H84.133C84.6396 69.3586 85.1254 69.5598 85.4837 69.9181C85.8419 70.2763 86.0431 70.7621 86.0431 71.2687C86.0431 71.7753 85.8419 72.2611 85.4837 72.6193C85.1254 72.9775 84.6396 73.1788 84.133 73.1788H34.4706C33.9641 73.1788 33.4782 72.9775 33.12 72.6193C32.7618 72.2611 32.5605 71.7753 32.5605 71.2687ZM34.4706 80.8191C33.9641 80.8191 33.4782 81.0204 33.12 81.3786C32.7618 81.7368 32.5605 82.2227 32.5605 82.7292C32.5605 83.2358 32.7618 83.7217 33.12 84.0799C33.4782 84.4381 33.9641 84.6393 34.4706 84.6393H65.0321C65.5387 84.6393 66.0245 84.4381 66.3827 84.0799C66.741 83.7217 66.9422 83.2358 66.9422 82.7292C66.9422 82.2227 66.741 81.7368 66.3827 81.3786C66.0245 81.0204 65.5387 80.8191 65.0321 80.8191H34.4706Z"
        fill="#D2D4D6"
      />
      <path
        d="M97.5031 36.7102H79.8921C79.0354 36.7107 78.187 36.5424 77.3954 36.2149C76.6038 35.8875 75.8845 35.4072 75.2785 34.8016C74.6726 34.196 74.1919 33.477 73.8639 32.6856C73.536 31.8941 73.3672 31.0459 73.3672 30.1892V12.0586L97.5031 36.7102Z"
        fill="#E3E3E3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.37797 38.069C8.96486 43.0031 13.5123 46.1636 19.0202 47.5505C24.5297 46.1652 29.0772 43.0055 32.6625 38.0713C36.2478 33.1372 38.0404 27.6586 38.0404 21.6355V7.13258L19.0202 0L1.04907e-06 7.13258V21.6355C-0.00158316 27.657 1.79107 33.1348 5.37797 38.069Z"
        fill="url(#paint0_linear_770_338)"
      />
      <g clip-path="url(#clip0_770_338)">
        <circle
          cx="18.6867"
          cy="23.7394"
          r="13.5792"
          fill="black"
          fill-opacity="0.23"
        />
        <path
          d="M18.9834 15.498C17.0028 15.498 15.3915 17.1094 15.3915 19.09V21.2452H14.6731C14.292 21.2452 13.9266 21.3965 13.6572 21.666C13.3877 21.9354 13.2363 22.3009 13.2363 22.6819V28.4291C13.2363 28.8101 13.3877 29.1756 13.6572 29.445C13.9266 29.7145 14.292 29.8658 14.6731 29.8658H23.2938C23.6748 29.8658 24.0403 29.7145 24.3097 29.445C24.5792 29.1756 24.7306 28.8101 24.7306 28.4291V22.6819C24.7306 22.3009 24.5792 21.9354 24.3097 21.666C24.0403 21.3965 23.6748 21.2452 23.2938 21.2452H22.5754V19.09C22.5754 17.1094 20.964 15.498 18.9834 15.498ZM16.8283 19.09C16.8283 17.9018 17.7952 16.9348 18.9834 16.9348C20.1717 16.9348 21.1386 17.9018 21.1386 19.09V21.2452H16.8283V19.09ZM19.7018 26.7933V28.4291H18.2651V26.7933C18.0139 26.6495 17.8122 26.4329 17.6866 26.1721C17.561 25.9114 17.5174 25.6187 17.5615 25.3326C17.6056 25.0466 17.7354 24.7806 17.9337 24.5698C18.132 24.359 18.3896 24.2133 18.6724 24.1518C18.8825 24.1053 19.1003 24.1066 19.3098 24.1556C19.5193 24.2045 19.7151 24.2999 19.8829 24.4346C20.0506 24.5693 20.186 24.7399 20.279 24.9339C20.372 25.128 20.4202 25.3404 20.4202 25.5555C20.4198 25.8067 20.3532 26.0535 20.2271 26.2708C20.1009 26.4881 19.9198 26.6683 19.7018 26.7933Z"
          fill="white"
        />
      </g>
      <g clip-path="url(#clip1_770_338)">
        <circle cx="97.395" cy="105.895" r="17.6059" fill="white" />
        <circle
          cx="97.395"
          cy="105.895"
          r="16.8059"
          stroke="#34A853"
          stroke-opacity="0.34"
          stroke-width="1.60008"
        />
        <path
          d="M106.75 100.691L94.2691 113.172L88.5488 107.451L90.0153 105.985L94.2691 110.228L105.283 99.2246L106.75 100.691Z"
          fill="#34A853"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_770_338"
          x1="19.0202"
          y1="0"
          x2="19.0202"
          y2="47.5505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#66B4FF" />
          <stop offset="1" stop-color="#197EDE" />
        </linearGradient>
        <clipPath id="clip0_770_338">
          <rect
            width="27.5041"
            height="27.5041"
            fill="white"
            transform="translate(5.23242 9.95117)"
          />
        </clipPath>
        <clipPath id="clip1_770_338">
          <rect
            width="35.2118"
            height="35.2118"
            fill="white"
            transform="translate(79.7891 88.2891)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
  <div>
    <h3 class="mb-2 mt-3">Before you continue</h3>
    <div class="terms-text"
    >
      I have read, understood and agree to be bound by the
      <a
        class="competition-terms"
        [href]="redirectLinks.competitionRegistrationAndPaymentSystem"
        target="_blank"
      >
        Competition Registration and Payment System - Terms of Use</a
      >
      and consent to the
      <a
        class="competition-terms"
        [href]="redirectLinks.privacyPolicy"
        target="_blank"
      >
        Privacy Policy.
      </a>
    </div>
  </div>
  <div class="text-end mt-4 mt-lg-5">
    <button
      pButton
      pRipple
      type="button"
      label="Agree and continue"
      class="p-primary"
      [ngClass]="{ 'w-100': isMobile }"
      (click)="acceptAndContinue()"
    ></button>
  </div>
</div>
