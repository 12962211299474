import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { mainConstants } from 'src/app/shared/constants/main.constants';
import { MastersService } from 'src/app/shared/masters.service';
import { UsersService } from 'src/app/shared/users.service';
import { EmailVerifiedComponent } from 'src/app/students/email-verified/email-verified.component';
import { LoginComponent } from 'src/app/students/login/login.component';

@Component({
  selector: 'app-sign-up-main',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setDialogWidth();
  }
  @Input() stepId: number;

  signupForm: FormGroup;
  signupStep1Form: FormGroup;
  signupStep3Form: FormGroup;
  countries: any;
  genderCategory: any;
  years: any = [];
  terms: any;
  termId: any;
  showPass: boolean = false;
  showPassConfirm: boolean = false;
  blockSpecial: RegExp = /^[^<>*!#%^&()+/:;|"{}]+$/;
  studentData;
  countrySubscription: Subscription[] = [];
  selectedCountry: any;
  selectedGender: any;
  dynamicDialogWidth: string;

  isEligible: boolean;
  skipDisable = false;

  signup_level = 0;
  isDialog: boolean;
  form1_submitted = false;
  form2_submitted = false;
  form3_submitted = false;
  isVerifiedEmail = false;
  isConversionVisible: boolean = false;
  isEighteenPlus: boolean;
  currentYear = new Date().getFullYear();
  nextSixYears = Array.from(
    { length: 6 },
    (_, index) => this.currentYear + index
  );
  redirectLinks = mainConstants.redirectLinks;
  countryCodes = [
    { country: 'Afghanistan', code: '93', iso: 'AF' },
    { country: 'Albania', code: '355', iso: 'AL' },
    { country: 'Zimbabwe', code: '263', iso: 'ZW' },
  ];
  submitted: boolean;
  disableEmail: boolean;

  constructor(
    private commonService: CommonServiceService,
    private usersService: UsersService,
    private router: Router,
    private mastersService: MastersService,
    private formBuilder: FormBuilder,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const stepId = +this.activateRoute.snapshot.paramMap.get('stepId');

    this.isDialog = this.config?.data?.isPopup ?? false;
    this.getCountries();
    this.getTerms();
    this.getYears();
    this.genderCategory = [
      { key: 'Male', value: 'Male' },
      { key: 'Female', value: 'Female' },
      { key: 'Others', value: 'Others' },
    ];

    this.signupStep3Form = this.formBuilder.group({
      // fullName: ['', Validators.required],
      //parentEmailId: ['', [Validators.email]],
      // phone: [null, [Validators.required]],
      country: ['', Validators.required],
      gradYear: ['', Validators.required],
      sendMarketingEmails: [false],
    });

    this.signupStep1Form = this.formBuilder.group({
      fullName: [null, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // gradYear: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      acceptTerms: [false, Validators.required],
      // isEighteenPlus: [false, Validators.required],
      termsId: ['1'],
    });
    if (stepId) {
      this.signup_level = stepId + 1;
      this.commonService.$curUser.subscribe(
        (res) => {
          this.studentData = res;
          this.isVerifiedEmail = this.studentData?.isVerifiedEmail;
        },
        (err) => {
          console.log(`${this.constructor.name} ${err}`);
        }
      );
    }
    if (localStorage.getItem('invitedEmail')) {
      this.signupStep1Form.controls['email'].setValue(
        (localStorage.getItem('invitedEmail') &&
          JSON.parse(localStorage.getItem('invitedEmail'))) ||
          ''
      );
      this.disableEmail = true;
      this.signupStep1Form.controls['email'].disable();
    } else {
      this.signupStep1Form.controls['email'].enable();
      this.disableEmail = false;
    }
    this.selectedCountry = this.signupStep3Form.controls['country'].value;
    this.countrySubscription.push(
      this.signupStep3Form.controls['country'].valueChanges.subscribe((res) => {
        this.selectedCountry = res;
      })
    );
  }

  navigateToLogin() {
    localStorage.setItem('user', null);
    this.router.navigate(['/login']);
  }

  showConversion() {
    this.isConversionVisible = !this.isConversionVisible;
  }

  ngOnDestroy() {
    this.countrySubscription.forEach((sub) => sub.unsubscribe());
  }

  get f() {
    return this.signupForm.controls;
  }

  get f1() {
    return this.signupStep1Form.controls;
  }

  get f2() {
    return 0; // this.stripeTest.controls;
  }

  get f3() {
    return this.signupStep3Form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.signupForm.invalid) {
      return;
    }

    let data1 = this.signupForm.value;

    if (data1.acceptTerms === false) {
      this.commonService.showToast(
        'Please accept terms and conditions and privacy policy',
        'warn',
        3000
      );
      return;
    }
    if (data1.isEighteenPlus === false) {
      this.commonService.showToast('Please accept legal age', 'warn', 3000);
      return;
    }

    this.form1_submitted = true;

    this.usersService.signup({ data: data1 }).subscribe(
      (item) => {
        this.commonService.showToast('Signup successful.', 'success', 3000);

        if (this.config.data) {
          this.ref.close({ close: true });
        }

        this.router.navigate(['/student/admission/US/dashboard']);
      },
      (err) => {
        this.commonService.showToast(err.error.message, 'warn', 3000);
        this.submitted = false;
        console.log(err);
      }
    );
  }

  onReset(): void {
    this.submitted = false;
    this.signupForm.reset();
  }

  public getCountries() {
    this.mastersService.getCountries({}).subscribe(
      (item) => {
        this.countries = item;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public getTerms() {
    this.mastersService.getTerms({ where: { isDeleted: 0 } }).subscribe(
      (item) => {
        this.termId = item.data[0].id;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private getYears() {
    var max = new Date().getFullYear();
    var min = max + 9;
    for (var i = max; i <= min; i++) {
      this.years.push({ year: i });
    }
  }

  showPassFn() {
    this.showPass = !this.showPass;
  }

  showPassFnConfirm() {
    this.showPassConfirm = !this.showPassConfirm;
  }

  openLogin() {
    if (this.config.data) {
      this.commonService.show(LoginComponent, {
        width: window.innerWidth > 900 ? '40vw' : '95vw',
        data: { isPopup: true },
      });
      this.ref.close();
    } else {
      this.router.navigate(['/login']);
    }
  }

  private getUserData(data?, isSignupComplete?: boolean) {
    let userId;
    if (data) {
      userId = data.id;
    }
    if (!data && !this.studentData) {
      return;
    }
    if (localStorage.getItem('user')) {
      localStorage.setItem('user', JSON.stringify(this.studentData));
    }
    this.commonService.$curUser.next(this.studentData);
    localStorage.setItem('user', JSON.stringify(this.studentData));

    this.isVerifiedEmail = this.studentData.isVerifiedEmail ? true : false;
    this.signup_level = this.studentData.signup_level + 1;

    if (isSignupComplete) {
      this.router.navigate(['/student/admission/US/dashboard']);
    }
  }
  showNavLogin = false;
  customSkipLogicCount = 0;

  countSpaces(str) {
    let spaceCount = 0;

    for (let i = 0; i < str.length; i++) {
      if (str[i] === ' ') {
        spaceCount++;
      }
    }

    return spaceCount;
  }
  newSignup() {
    const data = this.signupStep1Form.getRawValue();
    if (!this.isEighteenPlus) {
      this.commonService.showToast('Please verify your age', 'warn', 3000);
      return;
    }
    if (!this.signupStep1Form.getRawValue().password) {
      this.commonService.showToast('Please enter a password', 'warn', 3000);
    }
    if (
      this.signupStep1Form.controls['password']?.errors &&
      this.signupStep1Form.controls['password']?.errors['minlength']
    ) {
      this.commonService.showToast(
        'Password must be at least 8 characters',
        'warn',
        3000
      );
    }
    if (!this.signupStep1Form.getRawValue().fullName) {
      this.commonService.showToast('Please enter full name', 'warn', 3000);
    }
    if (!this.signupStep1Form.getRawValue().email) {
      this.commonService.showToast('Please enter email id', 'warn', 3000);
    }
    if (
      this.signupStep1Form.getRawValue().email &&
      this.signupStep1Form.controls['email']?.invalid
    ) {
      this.commonService.showToast('Please enter valid email id', 'warn', 3000);
    }
    if (!this.signupStep1Form.valid) {
      return;
    }
    if (data.fullName && this.countSpaces(data.fullName) < 1) {
      this.commonService.showToast(
        'Please enter valid full name',
        'warn',
        3000
      );
      return;
    }
    let obj: any = {
      ...data,
    };

    obj.firstName = obj.fullName.split(' ')[0];
    obj.lastName = obj.fullName.split(' ')[1] ?? '';
    delete obj['fullName'];
    obj['isEligible'];

    if (obj.acceptTerms === false) {
      this.commonService.showToast(
        'Please accept terms and conditions and privacy policy',
        'warn',
        3000
      );
      return;
    }
    delete obj['acceptTerms'];
    this.form1_submitted = true;
    this.usersService.newSignup({ data: obj }).subscribe(
      (item) => {
        this.commonService.showToast(
          'Account successfully created',
          'success',
          3000
        );
        this.studentData = item.data;
        if (!localStorage.getItem('signedUp_page_visit')) {
          localStorage.setItem('signedUp_page_visit', JSON.stringify(0));
        }
        localStorage.setItem('token', item.token);
        this.getUserData(item.data, this.studentData.isVerifiedEmail);
      },
      (err) => {
        this.form1_submitted = false;
        console.log(err);
        this.commonService.showToast(err.error.error, 'error', 3000);
        if (
          err.error.message ==
          'Email Id is Already Used. Please use another Email Id.'
        ) {
          this.showNavLogin = true;
        }
      }
    );
  }

  countryCodeModel;
  updateAccount(data) {
    if (!this.isVerifiedEmail) {
      this.commonService.showToast(
        'Please verify your email to continue.',
        'warn',
        3000
      );
      return;
    }

    // }

    let obj = {
      ...data,
      firstName: this.studentData.firstName,
      lastName: this.studentData.lastName,
      userId: this.studentData.id || this.studentData.userId,
    };

    if (obj.sendMarketingEmails == true) {
      obj.sendMarketingEmails = 1;
    } else {
      obj.sendMarketingEmails = 0;
    }

    // obj.phone = '+' + this.countryCodeModel.code + obj.phone;

    this.form3_submitted = true;
    this.usersService.updateAccountInfo({ data: obj }).subscribe(
      (item) => {
        this.getUserData(null, true);
      },
      (err) => {
        this.form3_submitted = false;
        console.log(err);
      }
    );
  }

  private setDialogWidth(): void {
    const width = window.innerWidth > 800 ? '50vw' : '95vw';
    // Assuming you have a property to store the currently open dialog reference
    this.dynamicDialogWidth = width;
    console.log('this.dynamicDialogWidth', this.dynamicDialogWidth);
  }

  verifyEmail(type: string) {
    if (type != 'student' && !this.signupStep3Form.value.parentEmailId) {
      return;
    }
    let ref = this.commonService.show(EmailVerifiedComponent, {
      width: this.dynamicDialogWidth,
      height: '70vh',
      closable: true,
      data: {
        requestEmail:
          type == 'student'
            ? this.studentData?.email
            : this.signupStep3Form.value.parentEmailId,
        requestEmailType: type,
      },
    });
    ref.onClose.subscribe((item) => {
      this.studentData.isVerifiedEmail = item?.isVerified;
      this.getUserData();
    });
  }
}
