import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss'],
})
export class PaymentResultComponent {
  constructor(
    private activateRouter: ActivatedRoute,
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activateRouter.queryParams.subscribe((params) => {
      console.log(params['session_id']);
      if (
        params['session_id'] &&
        !params['competition'] &&
        !params['essay'] &&
        !params['bundle']
      ) {
        this.commonService.getPaymentResult(params['session_id']).subscribe(
          (res) => {
            this.commonService.showToast(
              'Payment Successfull',
              'success',
              3000,
              'Payment Successfull'
            );
            this.router.navigate(['student/essay-review']);
          },
          (error) => {
            console.log(error);
            this.commonService.showToast(
              'Payment Failed, Please try again',
              'warn',
              3000
            );
            this.router.navigate(['student/essay-review']);
          }
        );
      } else if (params['session_id'] && params['competition']) {
        this.commonService
          .getPaymentForCompetition(params['session_id'])
          .subscribe(
            (res: any) => {
              if (
                res?.data?.status === 'complete' ||
                res?.data?.status === 'paid'
              ) {
                this.commonService.showToast(
                  'Competition Payment Successfull',
                  'success',
                  3000,
                  'Payment Successfull'
                );
              } else {
                this.commonService.showToast('Payment Failed', 'warn', 3000);
              }
              this.router.navigate(['student/competitions']);
            },
            (error) => {
              console.log(error);
              this.commonService.showToast(
                'Payment Failed, Please try again',
                'warn',
                3000
              );
            }
          );
      } else if (params['session_id'] && params['essay'] == 1) {
        this.commonService.finalizeEssayPayment(params['session_id']).subscribe(
          (res) => {
            this.commonService.showToast(
              'Payment Successfull',
              'success',
              3000,
              'Payment Successfull'
            );
            this.router.navigate(['student/essay-review']);
          },
          (error) => {
            console.log(error);
            this.commonService.showToast(
              'Payment Failed, Please try again',
              'warn',
              3000
            );
          }
        );
      } else if (params['session_id'] && params['bundle'] == 1) {
        this.commonService
          .getList(
            `users/essay/bundles/purchase/finalize?session_id=${params['session_id']}`
          )
          .subscribe((res) => {
            if (
              res?.data?.status === 'complete' ||
              res?.data?.status === 'paid'
            ) {
              this.commonService.showToast(
                'Package Payment Successfull',
                'success',
                3000,
                'Payment Successfull'
              );
              //  this.router.navigate(['student/essay-review']);
            } else {
              this.commonService.showToast('Payment Failed', 'warn', 3000);
            }
            this.router.navigate([`student/essay-review`]);
          });
      }
    }),
      (error) => {
        console.log(error);
      };
  }
}
