import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "globalSearchPipe",
})
export class GlobalSearchPipePipe implements PipeTransform {
  transform(value: any, searchString: string) {
    if (!value) return null;
    if (!searchString) return value;

    searchString = searchString.toLowerCase();

    return value.filter(function (item) {
      return JSON.stringify(item).toLowerCase().includes(searchString);
    });
  }
}