<div class="d-flex flex-column overflow-auto h-100">
  <div class="card border-0 p-3 mb-3">
    <div
      class="d-flex flex-column flex-lg-row align-items-center flex-wrap flex-lg-nowrap gap-3 mb-3"
    >
      <div class="w-100">
        <div>
          <h5 class="page-card-title">Send Invite</h5>
          <p class="page-card-subtext">
            <a href="/assets/SampleStudents.xlsx" download="SampleStudents.xlsx"
              >Click here</a
            >

            to download a sample file.
          </p>
        </div>
        <div>
          <input
            type="file"
            accept=".xls, .xlsx"
            (change)="handleFileInput($event)"
            style="display: none"
            #fileInput
          />
          <p-button
            [loading]="isFileUploading"
            class="text-nowrap custom-drag-drop"
            severity="secondary"
            (onClick)="fileInput.click()"
          >
            <div class="drag-drop-icon-wrapper">
              <span class="icon icon-file text-success"></span>
            </div>
            <p class="btn-label">Upload your file here</p>
            <p class="btn-sub-label">10 Mb max File</p>
          </p-button>
        </div>
      </div>
      <span class="mt-lg-5 pt-lg-3">or</span>
      <div class="w-100">
        <div>
          <h5 class="page-card-title">Students Email Id *</h5>
          <p class="page-card-subtext">Enter Student’s email Id</p>
        </div>
        <div class="d-flex">
          <textarea
            rows="5"
            type="text"
            class="invite-student-input"
            pInputText
            placeholder="Enter student emails separated by commas"
            [(ngModel)]="inviteStudentEmailId"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="text-end">
      <p-button
        (click)="inviteStudent(null)"
        class="custom-ternary text-nowrap"
        label="Send Invite"
      ></p-button>
    </div>
  </div>
  <p-card class="mt-2 shadow invite-student-list">
    <p-table
      [value]="inviteStudentsList"
      responsiveLayout="stack"
      [breakpoint]="'768px'"
      [rows]="5"
      [lazy]="true"
      (onLazyLoad)="onPage($event)"
      [(selection)]="studentsToDelete"
      dataKey="id"
      [tableStyle]="{'min-width': '20rem'}"
    >
      <ng-template pTemplate="caption">
        <div class="d-flex flex-column gap-2">
          <div>
            <h5 class="page-card-title mb-0">All Students</h5>
            <!-- <p class="page-card-subtext text-success mb-0">All</p> -->
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex gap-3 align-items-center">
              <div>
                <app-search
                  [searchMaxWidth]="'300px'"
                  [placeholder]="'Search Questions'"
                  [searchFunction]="onSearch.bind(this)"
                  (searchResults)="handleSearchResults($event)"
                ></app-search>
              </div>
              <p-dropdown
                [options]="[
                  { id: 1, name: 'Accepted' },
                  { id: -1, name: 'Rejected' },
                  { id: 0, name: 'Pending' }
                ]"
                optionLabel="name"
                optionValue="id"
                [(ngModel)]="inviteFilter"
                [showClear]="true"
                (ngModelChange)="getInviteStudentsList()"
                placeholder="Select Status"
                class="fixed-width-dropdown"
              ></p-dropdown>
            </div>
            <div
              class="custom-pagination flex align-items-center justify-content-end"
            >
              <p-paginator
                [first]="0"
                [rows]="5"
                [totalRecords]="totalRecords"
                (onPageChange)="onPage($event)"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords} Students"
                [showPageLinks]="false"
                [showFirstLastIcon]="false"
              ></p-paginator>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="text-end" style="width: 80px">Sr. NO</th>
          <th>Name</th>
          <th>Email</th>
          <th class="text-center">Status</th>
          <th class="text-center">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-student let-rowIndex="rowIndex">
        <tr>
          <td style="width: 20px">
            <!-- <p-checkbox inputId="ny" [value]="student" [binary]="true"></p-checkbox> -->
            <p-tableCheckbox [value]="student"></p-tableCheckbox>
          </td>
          <td class="color-light text-end">{{ rowIndex + 1 }}</td>
          <td>{{ student?.firstName ?? "-" }}</td>
          <td>{{ student?.email }}</td>
          <td class="text-center">
            <span
              class="badge dot"
              [ngClass]="{
                'badge-success': student.status == 1,
                'badge-warning': student.status == -1,
                'badge-danger': student.status == 0
              }"
              >{{
                student.status == 1
                  ? "Accepted"
                  : student.status == -1
                  ? "Rejected"
                  : "Pending"
              }}</span
            >
          </td>
          <td style="width: 300px">
            <div class="d-flex gap-2">
              <p-button
                class="custom-ternary p-light"
                [severity]="student.status ? 'help' : 'Info'"
                [disabled]="student.status == 0 || student.status == -1"
                (click)="videDetails(student)"
                >View Details</p-button
              >
              <p-button
                class="custom-ternary p-button-remove"
                [severity]="'ds'"
                (click)="toggleDeleteDialog(student, true)"
                >Remove</p-button
              >
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No Records found.</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
<p-dialog
  header="Remove User?"
  [(visible)]="isDeleteDialogVisible"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="d-flex mt-6 justify-content-between">
    <p-button
      class="custom-ternary p-light"
      [severity]="'Info'"
      (click)="deleteStudents(false)"
      >Cancel</p-button
    >
    <p-button
      class="custom-ternary p-button-remove"
      [severity]="'ds'"
      (click)="deleteStudents(true)"
      >Confirm</p-button
    >
  </div>
</p-dialog>
