<div class="card-padding auth-content">
  <div class="text-lg-start text-center">
    <img src="../../../assets/icons/logo-2.svg" alt="" />
  </div>
  <h3 class="text-lg-start text-center my-4" *ngIf="deviceType == 'Desktop'">
    {{ !isResetPassPage && !isForgotPassword ? "Login" : "Reset Password" }}
  </h3>
  <form class="d-flex flex-column gap-4">
    <div class="form-group">
      <div>
        <label for="Email" class="block">Email</label>
      </div>
      <div class="p-input-icon-left w-100">
        <i class="pi pi-user"></i>
        <input
          pInputText
          class="p-inputtext-lg"
          placeholder="Email ID"
          [(ngModel)]="loginUsername"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="isResetPassPage"
        />
      </div>
    </div>

    <div *ngIf="!isForgotPassword">
      <div class="d-flex align-items-center justify-content-between gap-3 mb-2">
        <div>
          <label for="password" class="block">Password</label>
        </div>
        <div
          class="d-flex justify-content-end auth-link-text cursor-pointer"
          *ngIf="!isForgotPassword && !isResetPassPage"
        >
          <small (click)="showForgotPassword()"> Forgot your password? </small>
        </div>
      </div>
      <div
        class="p-input-icon-left w-100 p-input-icon-right"
        *ngIf="!isForgotPassword"
      >
        <i class="pi pi-lock"></i>
        <input
          [type]="showPass ? 'text' : 'password'"
          pInputText
          class="p-inputtext-lg"
          [placeholder]="!isResetPassPage ? 'Password' : 'New Password'"
          [(ngModel)]="loginPassword"
          [ngModelOptions]="{ standalone: true }"
        />
        <i
          style="cursor: pointer"
          class="pi"
          [ngClass]="{ 'pi-eye': !showPass, 'pi-eye-slash': showPass }"
          (click)="showPassFn()"
        ></i>
      </div>
    </div>

    <div *ngIf="isResetPassPage">
      <div>
        <label for="Confirm Password" class="block">Confirm Password</label>
      </div>
      <div
        class="p-input-icon-left w-100 p-input-icon-right"
        *ngIf="isResetPassPage"
      >
        <i class="pi pi-lock"></i>
        <input
          [type]="showPassConfirm ? 'text' : 'password'"
          pInputText
          class="p-inputtext-lg"
          placeholder="Confirm Password"
          [(ngModel)]="confirmloginPassword"
          [ngModelOptions]="{ standalone: true }"
        />
        <i
          class="pi"
          [ngClass]="{
            'pi-eye': !showPassConfirm,
            'pi-eye-slash': showPassConfirm
          }"
          (click)="showPassFnConfirm()"
        ></i>
      </div>
    </div>

    <ng-container *ngIf="isForgotPassword">
      <div class="d-flex flex-column gap-2">
        <div class="d-flex justify-content-end auth-link-text">
          <a class="cursor-pointer" (click)="toggleForgotPasswordScreen()">
            Login
          </a>
        </div>

        <button
          type="submit"
          class="btn btn-auth-primary"
          [disabled]="!loginUsername"
          (click)="forgotPassword()"
        >
          Send Verification Link
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!isForgotPassword">
      <button
        class="btn btn-auth-primary"
        type="submit"
        [disabled]="!loginUsername || !loginPassword || submitted"
        (click)="studentLogin()"
      >
        <span>{{ isResetPassPage ? "Set Password" : "Login" }}</span>
      </button>
      <div>
        <div class="follow-text dark mb-4">
          <span>or</span>
        </div>
        <div class="g-auth-btn-wrapper">
          <div
            class="d-none"
            id="g_id_onload"
            data-client_id="649575828392-a3nal40mm05noc64bmb8tgcmhme6fdf3.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleCredentialResponse"
            data-auto_prompt="false"
          ></div>

          <div
            id="g_id_signin"
            class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="center"
            data-width="280"
          ></div>
        </div>
      </div>
      <span class="auth-text text-center my-0"
        >Don’t have an account?
        <a class="auth-link-text" style="cursor: pointer" (click)="openSignup()"
          >Create an account
        </a></span
      >
    </ng-container>
  </form>
</div>
