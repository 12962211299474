import { Pipe, PipeTransform } from '@angular/core';
import { Competitions } from '../models/competions.models';

@Pipe({
  name: 'calculatePercentage',
})
export class CalculatePercentagePipe implements PipeTransform {
  transform(value: Competitions, args: any[]): any {
    let a = 0;
    let b = 0;
    let c = 0;
    if (args[1]) {
      if (value.required_team_members - value.member_count <= 0) {
        a = 33.33333;
      }
    }
    if (args[0]) {
      if (value.payment_status !== 'Pending') {
        b = 33.33333;
      }
    }
    if (args[2]) {
      if (value.member_paid_count == value.member_count) {
        //value.max_team_members <= value.member_count
        c = 33.33333;
      }
    }
    if (args[3]) {
      let values = [];
      let temp = 0;
      values.push(a, b, c);
      values.forEach((x) => {
        if (x > 0) {
          temp = temp + 1;
        }
      });
      const percentage = (temp / values.length) * 100;
      if (args[4]) {
        if (percentage == 0) {
          return 'w-0';
        } else if (percentage <= 34) {
          return 'w-25';
        } else if (percentage <= 67) {
          return 'w-75';
        } else {
          return 'w-100';
        }
      }
      return temp;
    } else {
      return a + b + c;
    }
  }
}
