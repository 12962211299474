import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
} from '@angular/core';

@Directive({
  selector: '[appMaxWords]',
})
export class MaxWordsDirective {
  @Input() appMaxWords: any = 300;

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLTextAreaElement;
    const words = inputElement.value.split(/\s+/);

    if (words.length > this.appMaxWords) {
      inputElement.value = words.slice(0, this.appMaxWords).join(' ');

      // Trigger change detection
      this.ngZone.run(() => {
        inputElement.dispatchEvent(new Event('input'));
      });
    }
  }
}
