import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-records-graph',
  templateUrl: './top-records-graph.component.html',
  styleUrls: ['./top-records-graph.component.scss'],
})
export class TopRecordsGraphComponent {
  @Input() topRecords: any[];
  @Input() keyName: string;

  topRecord: any;

  topUser: any;
  topLessonsCompleted: any;
  topQuestionsAsked: any;

  firstRank: any;
  secondRank: any;
  thirdRank: any;

  constructor() {}

  ngOnChanges(changes: any): void {
    if (changes.topRecords && changes.topRecords.currentValue) {
      switch (this.keyName) {
        case 'topUser':
          this.topRecord = changes.topRecords.currentValue['topUser'];
          console.log(this.keyName,this.topRecord);
          // this.topRecord = this.sharedMethodsService.topUser; //comment
          this.topRecord = this.topRecord?.length
            ? this.setRanksAndFilter(this.topRecord)
            : [];
          break;
        case 'topLessonsCompleted':
          this.topRecord =
            changes.topRecords.currentValue['topLessonsCompleted'];
          // this.topRecord = this.sharedMethodsService.topLessonsCompleted; //comment
          this.topRecord = this.topRecord?.length
            ? this.setRanksAndFilter(this.topRecord)
            : [];
          break;
        case 'topQuestionAsked':
          this.topRecord = changes.topRecords.currentValue['topQuestionAsked'];
          // this.topRecord = this.sharedMethodsService.topQuestionAsked; //comment
          this.topRecord = this.topRecord?.length
            ? this.setRanksAndFilter(this.topRecord)
            : [];

          break;
      }
    }
  }

  setRanksAndFilter(array) {
    return array?.length
      ? array.filter((x, i) => {
          if (i === 0) this.firstRank = x;
          if (i === 1) this.secondRank = x;
          if (i === 2) this.thirdRank = x;
          // console.log(this.firstRank, this.secondRank, this.thirdRank);
          return i >= 3;
        })
      : [];
  }
}
