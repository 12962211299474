import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { ApiService } from './api.service';
import { CommonServiceService } from './common-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private apiService: ApiService,
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  canActivate(
    state: any
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem('token');
    const currentUser =
      (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user') || '')) ||
      {};

    if (state.url.length > 0) {
      const parentRoute = state.url[0].path as string;

      switch (parentRoute) {
        case 'admin':
          return this.handleRoute('admin-login', token, currentUser);
        case 'organisation':
          return this.handleRoute(
            'organisation-admin-login',
            token,
            currentUser
          );
        case 'student':
          return this.apiService.handleStudentRoute(token, currentUser);
      }
    }

    return this.apiService.verifyToken(token);
  }

  private handleRoute(
    route: string,
    token: string,
    currentUser: any
  ): Observable<boolean> {
    if (!token || !currentUser) {
      this.router.navigate([`/${route}`]);
      return of(false);
    }

    return this.apiService.verifyToken(token);
  }
}
