<p-button
  label="Back to Forum"
  [text]="true"
  icon="pi pi-angle-left"
  (click)="navigateToBack()"
  *ngIf="isBackButtonShow"
></p-button>
<div class="public-forum-page">
  <div class="p-3 flex-grow-1">
    <div class="container">
      <div class="card">
        <div class="card-body p-0">
          <div class="p-3">
            <h6
              class="public-forum-title m-0 fs-6 fw-medium"
              [innerHTML]="question?.title | renderContent"
            >
              {{ question?.title }}
            </h6>
            <p
              *ngIf="question?.content"
              class="text-desc m-0 mt-2"
              [innerHTML]="question?.content | renderContent"
              appDynamicStyle
            ></p>
            <p class="d-flex align-items-center gap-2 font-sm mt-2">
              <!-- avatar -->
              <figure class="avatar sm m-0">
                <span>{{
                  question?.User?.firstName[0].toUpperCase() ?? ""
                }}</span>
              </figure>
              <!-- End: avatar -->
              <span class="name-and-time">
                <span>
                  {{
                    (question?.User?.firstName | titlecase) +
                      " " +
                      (question?.User.lastName | titlecase)
                  }}
                </span>
                <span class="dot"></span>
                <span>
                  {{ question?.createdAt | date : "d MMMM" }}
                </span>
              </span>
            </p>

            <div class="d-flex align-items-center gap-2 font-sm mt-2">
              <span class="pi pi-comments"></span>
              <span class="pe-2">{{
                question?.Answers?.length == 0
                  ? 0 + "Answers"
                  : question?.Answers?.length > 1
                  ? question?.Answers?.length + " Answers"
                  : question?.Answers?.length + " Answer"
              }}</span>
              <div
                *ngIf="question?.Answers?.length > 0"
                class="avatar-group d-flex align-items-center"
              >
                <span
                  class="avatar-images"
                  *ngFor="let avatar of question.Answers"
                >
                  <!-- <img
                    style="height: 24px"
                    src="../../../../assets/user-128.png"
                    alt="Comment Avatar"
                  /> -->
                  <figure class="avatar sm m-0">
                    <span>{{
                      avatar.User.firstName[0].toUpperCase() ?? ""
                    }}</span>
                  </figure>
                </span>
              </div>
            </div>

            <!-- Action Btns -->
            <div>
              <div
                class="d-flex justify-content-between align-items-center gap-2 flex-wrap mt-3"
              >
                <!-- <div class="d-flex align-items-center gap-3">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-user-edit"
                    label="Add your Answer"
                    size="small"
                    class="p-primary p-button-sm"
                    (click)="showAddAnswerTextBox(question)"
                  ></button>
                  <div class="d-flex gap-2 comments-btn cursor-pointer">
                    <i class="pi pi-comments"></i>
                    <span>{{
                      totalAnswers ? totalAnswers + " Answers" : 0 + " Answers"
                    }}</span>
                  </div>
                </div> -->
                <div class="d-flex flex-wrap gap-2">
                  <p-badge
                    *ngFor="let tag of question?.Tags; let j = index"
                    class="cursor-pointer"
                    [value]="tag.tag_name"
                    [severity]="'warning'"
                  ></p-badge>
                </div>
              </div>
            </div>
            <!-- End: Action Btns -->
          </div>
          <!-- question body -->
          <div
            class="d-flex align-items-center gap-3 p-3 flex-grow-1 border-bottom border-top"
          >
            <!-- avatar -->
            <figure class="avatar m-0">
              <span>{{
                loggedInUserDetail?.firstName[0].toUpperCase() ?? ""
              }}</span>
            </figure>
            <!-- End: avatar -->
            <!-- <div class="add-answer-box flex-grow-1">
            <input
              type="text"
              [(ngModel)]="answer"
              pInputText
              placeholder="Write a reply"
            />
            <span
              class="send-img-wrapper cursor-pointer"
              (click)="saveAnswer(question, i)"
            >
              <img src="../../../../assets/icons/send.svg" alt="Send" />
            </span>
          </div> -->
            <div class="add-answer-box flex-grow-1">
              <p-editor
                placeholder="Add your answer"
                [(ngModel)]="answer"
                [appendTo]="'body'"
                [style]="{ width: '100%', height: '100px' }"
              >
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <button
                      type="button"
                      class="ql-bold"
                      aria-label="Bold"
                    ></button>
                    <button
                      type="button"
                      class="ql-italic"
                      aria-label="Italic"
                    ></button>
                    <button
                      type="button"
                      class="ql-underline"
                      aria-label="Underline"
                    ></button>
                    <button
                      type="button"
                      class="ql-image"
                      aria-label="image"
                    ></button>
                    <button
                      type="button"
                      class="ql-code-block"
                      aria-label="code-block"
                    ></button>
                  </span>
                </ng-template>
              </p-editor>
              <span
                class="send-img-wrapper cursor-pointer"
                (click)="saveAnswer(question)"
              >
                <img src="../../../../assets/icons/send.svg" alt="Send" />
              </span>
            </div>
          </div>
          <ng-container>
            <!-- Comments list -->
            <div
              class="d-flex gap-3 p-3 forum-add-answer-area"
              *ngFor="
                let answer of question?.Answers;
                let k = index;
                let isLast = last
              "
            >
              <!-- avatar -->
              <div class="d-flex flex-column gap-2 align-items-center">
                <figure class="avatar m-0">
                  <span>{{
                    answer.User?.firstName[0].toUpperCase() ?? ""
                  }}</span>
                </figure>
                <h6 class="m-0 fs-6 fw-medium">
                  <small>{{ answer.User?.firstName | titlecase }} </small>
                  <!-- <p class="m-0 fs-6 text-muted">
                  <small>{{ answer.User?.email }}</small>
                </p> -->
                </h6>
                <span class="forum-user-country" *ngIf="answer.User?.country">
                  {{ answer.User?.country }}
                </span>
              </div>
              <!-- End: avatar -->

              <!-- Comment section -->
              <div
                class="flex-grow-1 pb-3"
                [ngClass]="{ 'border-bottom': !isLast }"
              >
                <div>
                  <p class="d-flex align-items-center gap-2 font-sm my-2">
                    <span class="pi pi-calendar"></span>
                    <span>{{ answer?.createdAt | date }}</span>
                  </p>
                  <div class="text-muted m-0">
                    <small
                      class="py-2"
                      *ngIf="!answer.isEditMode"
                      [innerHTML]="answer?.content | renderContent"
                    ></small>
                  </div>
                </div>
                <div class="d-flex gap-2 my-2 mt-3">
                  <p-button
                    icon="pi pi-user-edit"
                    label="Add your reply"
                    [rounded]="true"
                    severity="info"
                    size="small"
                    [text]="true"
                    (click)="showReplyTextBox(answer)"
                    class="reply-btn"
                  ></p-button>
                  <p-button
                    icon="pi pi-reply"
                    [label]="
                      answer.commentCount
                        ? answer.commentCount + ' Reply'
                        : 0 + ' Reply'
                    "
                    [rounded]="true"
                    [text]="true"
                    severity="success"
                    size="small"
                    (click)="showNestedComments()"
                  ></p-button>
                </div>
                <div
                  *ngIf="answer.isRepliesOpen"
                  class="d-flex align-items-center flex-wrap gap-3 pt-3 mb-3"
                >
                  <div class="add-answer-box flex-grow-1">
                    <figure
                      *ngIf="loggedInUserDetail?.firstName"
                      class="avatar m-0 mb-2"
                    >
                      <span>{{
                        loggedInUserDetail?.firstName[0].toUpperCase()
                      }}</span>
                    </figure>
                    <p-editor
                      placeholder="Add your answer"
                      [(ngModel)]="replies[k]"
                      [appendTo]="'body'"
                      [style]="{ width: '100%', height: '100px' }"
                    >
                      <ng-template pTemplate="header">
                        <span class="ql-formats">
                          <button
                            type="button"
                            class="ql-bold"
                            aria-label="Bold"
                          ></button>
                          <button
                            type="button"
                            class="ql-italic"
                            aria-label="Italic"
                          ></button>
                          <button
                            type="button"
                            class="ql-underline"
                            aria-label="Underline"
                          ></button>
                          <button
                            type="button"
                            class="ql-image"
                            aria-label="image"
                          ></button>
                          <button
                            type="button"
                            class="ql-code-block"
                            aria-label="code-block"
                          ></button>
                        </span>
                      </ng-template>
                    </p-editor>
                    <span
                      class="send-img-wrapper cursor-pointer"
                      (click)="addReply(answer, k, question)"
                    >
                      <img src="../../../../assets/icons/send.svg" alt="Send" />
                    </span>
                  </div>
                  <!-- <div class="d-flex align-items-center gap-3 flex-grow-1"> -->

                  <!-- <div class="flex-grow-1">
                    <input
                      type="text"
                      class="p-inputtext-sm"
                      pInputText
                      placeholder="Add Reply"
                      [(ngModel)]="replies[k]"
                    />
                  </div> -->
                  <!-- </div> -->
                  <!-- <div> -->
                  <!-- <p-button
                    label="Reply"
                    size="small"
                    (click)="addReply(answer, k, question)"
                  ></p-button> -->
                  <!-- </div> -->
                </div>

                <!-- Add Nested comment input box area -->
                <div>
                  <div
                    *ngIf="answer.isAddReplyTextboxVisible"
                    class="d-flex align-items-center flex-wrap gap-3 pt-3 mb-3"
                  >
                    <div class="d-flex align-items-center gap-3 flex-grow-1">
                      <!-- avatar -->
                      <figure class="avatar m-0">
                        <span>K</span>
                      </figure>
                      <!-- End: avatar -->
                      <div class="flex-grow-1">
                        <input
                          type="text"
                          class="p-inputtext-sm"
                          pInputText
                          placeholder="Add Reply"
                        />
                      </div>
                    </div>
                    <div>
                      <p-button label="Reply" size="small"></p-button>
                    </div>
                  </div>
                  <ng-container>
                    <div
                      class="d-flex gap-3 mt-3"
                      *ngFor="let comment of answer.Comments; let l = index"
                    >
                      <figure class="avatar m-0">
                        <span>{{
                          comment.User?.firstName?.charAt(0).toUpperCase()
                        }}</span>
                      </figure>
                      <div>
                        <h6 class="m-0 fs-6 fw-medium">
                          <small
                            >{{ comment.User?.firstName }} ·
                            {{ comment.createdAt | date }}</small
                          >
                        </h6>
                        <p class="text-muted m-0">
                          <small
                            [innerHTML]="comment?.content | renderContent"
                          ></small>
                        </p>
                      </div>
                    </div>
                    <ng-container *ngIf="answer.Comments?.length === 0">
                      <div class="text-center">
                        <small class="text-center text-danger"
                          >No Replies</small
                        >
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- End: Add Nested comment input box area -->
              </div>
              <!-- End: Comment section -->
            </div>
            <ng-container *ngIf="!question?.Answers?.length">
              <p class="text-center text-danger pt-3">No Answers</p>
            </ng-container>
          </ng-container>
          <!-- End: Comments list -->
        </div>
      </div>
    </div>
  </div>
</div>
