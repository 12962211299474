import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testPartialSelect',
})
export class TestPartialSelectPipe implements PipeTransform {
  transform(value: any): any {
    const regex = /^Not_Qualified_Round_\d+$/;
    return regex.test(value);
  }
}
