import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'competitionStage',
})
export class CompetitionStagePipe implements PipeTransform {
  transform(value: string): string {
    let nearestDate: string = '';
    let smallestDiff: number = Number.MAX_VALUE;
    const now = new Date();

    Object.values(value).forEach((dateStr) => {
      const eventDate = new Date(dateStr);
      const diff = Math.abs(now.getTime() - eventDate.getTime());

      if (diff < smallestDiff) {
        smallestDiff = diff;
        nearestDate = dateStr;
      }
    });
    // console.log(nearestDate);
    const isoDate = new Date(nearestDate).toISOString();
    return formatTime(isoDate);
  }
}

export function formatTime(isoString) {
  const date = new Date(isoString);

  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let seconds = date.getUTCSeconds();

  hours = hours % 12 || 12;
  return `${hours} hr : ${minutes} min : ${seconds} s`;
}
