<div class="d-flex gap-3 flex-wrap">
  <div>
    <input
      #fileInput
      type="file"
      [id]="inputId"
      accept="image/*"
      (change)="onFileSelected($event)"
      hidden
    />
    <label
      [style]="{ width: width + 'px', height: height + 'px' }"
      class="file-img-wrapper"
      [for]="inputId"
    >
      <img
        [width]="width"
        [height]="height"
        class="cursor-pointer"
        [ngSrc]="imageUrl ?? defaultPlaceholder"
        alt="Upload Image"
      />
    </label>
  </div>
  <ng-container *ngIf="isButtonsShown">
    <div>
      <div class="d-flex align-items-center gap-3">
        <button class="btn btn-outline-primary btn-sm" (click)="openFileSelector()">
          Change
        </button>
        <button class="btn btn-outline-danger btn-sm" (click)="removeImage()">
          Remove
        </button>
      </div>
    </div>
  </ng-container>
</div>
