<header class="algo-header d-flex shadow-sm p-3">
  <figure class="logo-wrapper m-0">
    <img src="../../../../assets/icons/logo-2.svg" alt="Company Logo" />
  </figure>
  <div class="d-flex justify-content-between gap-3 flex-grow-1 px-4">
    <div class="d-flex align-items-center gap-3">
      <label class="menu-bar-label d-flex cursor-pointer" for="primarySidebar">
        <span class="pi pi-bars"></span>
      </label>
      <app-search></app-search>
    </div>
    <div class="d-flex align-items-center gap-3">
      <!-- <span class="icon icon-sms"></span>
      <span class="pi pi-bell"></span> -->
      <!-- avatar -->
      <!-- (click)="avatarImg.toggle($event)" -->
      <figure class="avatar cursor-pointer m-0" [routerLink]="['/organisation/app-shell/organisation-settings']">
        <span class="fw-normal white-space-nowrap">{{
          loggedInUserName?.fullName?.length &&
            (loggedInUserName?.fullName[0].toUpperCase() ?? "")
        }}</span>
      </figure>
      <p-overlayPanel #avatarImg>
        <div class="py-2 px-3">
          <span class="small fw-medium">Settings</span>
        </div>
      </p-overlayPanel>
      <!-- End: avatar -->
    </div>
  </div>
</header>
