<div class="d-flex flex-column h-100 overflow-hidden">
  <!-- student profile header  -->
  <div
    *ngIf="isStudentPortal"
    class="secondary-header d-flex justify-content-between bg-white border-top p-3 w-100"
  >
    <div class="d-flex align-items-center">
      <div class="cursor-pointer" [routerLink]="['/student']">
        <i class="pi pi-times"></i>
      </div>
      <h6 class="ps-3 ms-3 border-start m-0 fs-6">Student Profile</h6>
    </div>

    <!-- continue -->
    <div class="d-none d-lg-flex gap-2 align-items-center">
      <button
        pButton
        type="button"
        class="p-cancel-btn w-100 d-flex gap-2 align-items-center"
        *ngIf="router.url !== '/student/student-profile/personality-test'"
        (click)="goBackOrContinue('back')"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="text-nowrap">Go Back</span>
      </button>
      <button
        pButton
        class="p-primary full-width d-flex gap-2 align-items-center"
        (click)="goBackOrContinue('continue')"
      >
        <span>{{
          !router.url.includes("super-curricular-activities")
            ? "Continue"
            : "Finish"
        }}</span>
        <i
          *ngIf="!router.url.includes('super-curricular-activities')"
          class="pi pi-arrow-right"
        ></i>
        <i
          *ngIf="router.url.includes('super-curricular-activities')"
          class="pi pi-check"
        ></i>
      </button>
    </div>
    <!-- end: continue -->
  </div>
  <!-- End: student profile header  -->

  <!-- stepper section  -->
  <div
    class="d-flex flex-column flex-lg-row flex-grow-1 overflow-hidden px-0 px-lg-3 bg-white mt-0 mt-lg-2 pb-3"
  >
    <ul class="stepper my-0 pt-3 overflow-auto me-lg-3">
      <li
        class="stepper-item"
        *ngFor="let step of steps; let i = index"
        [routerLink]="step.path"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.ischild-menu]="step.isChildren?.length > 0"
        [class.active]="step.isActive"
        (click)="toggleOtherExpandedChild(step)"
      >
        <!-- (click)="step.isChildren?.length > 0 ? step.isExpanded = !step.isExpanded : null" -->
        <div class="d-flex align-items-center gap-2">
          <div class="stepper-count-wrapper">
            <span class="stepper-count">{{ step.id }}</span>
          </div>
          <span class="cursor-pointer">{{ step.title }}</span>
        </div>
        <ng-container *ngIf="step.isExpanded">
          <div
            class="sub-menu"
            *ngFor="let child of step.isChildren"
            (click)="$event.stopPropagation()"
            [routerLink]="child.path"
            [routerLinkActive]="'submenu-active'"
          >
            <ng-container *ngIf="step.id == child.parentID">
              <div class="dot-wrapper">
                <div class="dot"></div>
              </div>
              <span class="cursor-pointer sub-menu-text">{{
                child.title
              }}</span>
            </ng-container>
          </div>
        </ng-container>
        <!-- <ul class="nav nav-tabs d-md-none" *ngIf="step.isExpanded">
          <li class="nav-item" *ngFor="let child of step.isChildren">
            <a class="nav-link" [routerLink]="child.path">{{ child.title }}</a>
          </li>
        </ul> -->
      </li>
    </ul>

    <ul class="stepper mobile-view my-0 mb-3 overflow-auto">
      <ng-container *ngFor="let step of steps">
        <ng-container *ngIf="step.isExpanded">
          <li
            class="sub-menu"
            *ngFor="let child of step.isChildren"
            (click)="$event.stopPropagation()"
            [routerLink]="child.path"
            [routerLinkActive]="'submenu-active'"
            [class.ischild-menu]="true"
          >
            <div class="d-flex align-items-center gap-2">
              <div class="dot-wrapper">
                <div class="dot"></div>
              </div>
              <span class="cursor-pointer sub-menu-text">{{
                child.title
              }}</span>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>

    <div class="flex-grow-1 px-3 px-lg-0 overflow-auto">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>

    <!-- continue -->
    <div class="d-flex justify-content-center gap-2 d-lg-none px-3">
      <button
        pButton
        type="button"
        class="p-cancel-btn w-100 d-flex gap-2 align-items-center justify-content-center"
        *ngIf="router.url !== '/student/student-profile/personality-test'"
        (click)="goBackOrContinue('back')"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="text-nowrap">Go Back</span>
      </button>
      <button
        pButton
        class="p-primary w-100 d-flex justify-content-center gap-2 align-items-center"
        (click)="goBackOrContinue('continue')"
      >
        <span>{{
          !router.url.includes("super-curricular-activities")
            ? "Continue"
            : "Finish"
        }}</span>
        <i
          *ngIf="!router.url.includes('super-curricular-activities')"
          class="pi pi-arrow-right"
        ></i>
        <i
          *ngIf="router.url.includes('super-curricular-activities')"
          class="pi pi-check"
        ></i>
      </button>
    </div>
    <!-- end: continue -->
  </div>
  <!-- End: stepper section  -->
</div>
