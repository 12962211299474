import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from 'src/app/shared/users.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { MastersService } from 'src/app/shared/masters.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/admin/services/admin.service';
export class careerInterest {
  id;
  userId = null;
  careerId = null;
  others = '';
  why_interst_in_given_career = '';
  what_you_have_done_to_further_interest_in_the_given_career = '';
  Created_at;
  Updated_at;
  Created_by;
  Updated_by;
  careerName = '';
}
@Component({
  selector: 'app-career-interest',
  templateUrl: './career-interest.component.html',
  styleUrls: ['./career-interest.component.scss'],
})
export class CareerInterestComponent implements OnInit {
  fav: any[] = [
    { id: 1, name: 'Agriculture, Food, and Natural Resources' },
    { id: 2, name: 'Architecture and Construction' },
    { id: 3, name: 'Arts, Audio/Video Technology, and Communication' },
    { id: 4, name: 'Business and Finance' },
    { id: 5, name: 'Education and Training' },
    { id: 6, name: 'Government and Public Administration' },
    { id: 7, name: 'Health Science' },
    { id: 8, name: 'Information Technology' },
    { id: 9, name: 'Law, Public Safety, Corrections, and Security' },
    { id: 10, name: 'Marketing' },
    { id: 11, name: 'Science, Technology, Engineering, and Math' },
    { id: 12, name: 'Other' },
  ];
  selectedCareer: [] = [];
  studentData: any;
  studentProfileForm: FormGroup;
  profileCareerInterstData: any;
  subscription: Subscription;
  isStudent: boolean = true;

  constructor(
    private router: Router,
    private commonService: CommonServiceService,
    private fb: FormBuilder,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.studentProfileForm = this.buildForm();

    if (this.router.url.includes('admin/manage-students/student-details/')) {
      this.getAdminStudentData();
      this.isStudent = false;
    } else {
      this.getCareerInterestDetails();
      this.subscription = this.commonService
        .triggerChildMethod()
        .subscribe((res) => {
          this.saveData();
        });
    }
  }

  getAdminStudentData() {
    this.adminService.studentData.asObservable().subscribe((res) => {
      this.profileCareerInterstData = res.profile_data.career_interest;
      this.studentProfileForm.patchValue(this.profileCareerInterstData);
      this.selectedCareer = this.commonService.bindNgModelValues(
        res.profile_data.career_interest,
        'careerId'
      );
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSubjectChange(e, controlName: string) {
    this.studentProfileForm.controls[controlName].setValue(e.join(','));
  }

  getCareerInterestDetails() {
    this.commonService
      .getQuestionDetails('users/profile/careerinterest')
      .subscribe(
        (res) => {
          this.profileCareerInterstData = res.data;
          this.studentProfileForm.patchValue(this.profileCareerInterstData);
          this.selectedCareer = this.commonService.bindNgModelValues(
            res.data,
            'careerId'
          );
          console.log(this.selectedCareer);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  buildForm(): FormGroup {
    return this.fb.group({
      careerId: [null, Validators.required],
      others: [],
      why_interst_in_given_career: [null],
      what_you_have_done_to_further_interest_in_the_given_career: [null],
    });
  }

  saveData() {
    if (this.studentProfileForm.invalid) {
      this.commonService.showToast(
        'Please fill all the required fields',
        'error',
        1000
      );
      return;
    }
    console.log(this.studentProfileForm.value);
    const data = {
      isCategorList: true,
      name: this.studentProfileForm.value,
    };
    this.commonService
      .saveRecord('users/profile/careerinterest', data)
      .subscribe(
        (res) => {
          this.commonService.showToast(
            'Data saved successfully',
            'success',
            1000
          );
          // this.router.navigate([
          //   'student/student-profile/university-subject-interest',
          // ]);
        },
        (error) => {
          this.commonService.showToast('Something went wrong', 'error', 1000);
        }
      );
  }
}
