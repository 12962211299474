import { Pipe, PipeTransform } from '@angular/core';
import {
  localFormatDateStringtoDDMMYYYY,
  SharedMethodsService,
} from '../services/shared-methods.service';

@Pipe({
  name: 'timeZoneDate',
})
export class TimeZoneDatePipe implements PipeTransform {
  constructor(private sharedService: SharedMethodsService) {}
  transform(value: any, timeZone: string) {
    if (timeZone && value) {
      console.log(localFormatDateStringtoDDMMYYYY(
        this.sharedService.convertToTimeZone(value, timeZone)
      ),'Time Zone conversions')
      return localFormatDateStringtoDDMMYYYY(
        this.sharedService.convertToTimeZone(value, timeZone)
      );
    } else {
      return value;
    }
  }
}
