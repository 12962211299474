import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { SignUpComponent } from 'src/app/students/sign-up/sign-up.component';
import { CommonServiceService } from '../../shared/common-service.service';
import { UsersService } from '../../shared/users.service';
import { GoogleService } from 'src/app/shared/google.service';
import { environment } from 'src/environments/environment';
declare const google: any;

@Component({
  selector: 'app-student-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @HostListener('window:resize', ['$event']) onResize(event: Event): void {
    this.checkDeviceType();
  }

  loginPassword: string;
  loginUsername: string;

  submitted: boolean = false;
  showPass: boolean = false;
  isForgotPassword: boolean = false;
  loginErr: string;
  userEmailForVerification: string;
  $curAccessToken;
  sub: Subscription;
  window = window;
  isResetPassPage: boolean = false;
  resetPasswordObj: any;
  confirmloginPassword: string;
  deviceType: string;
  showPassConfirm: boolean = false;

  constructor(
    private commonService: CommonServiceService,
    private usersService: UsersService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private activatedRouter: ActivatedRoute,
    private googleService: GoogleService
  ) {
    this.checkDeviceType();
  }

  private checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
  }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    let competitionIdForStudent =
      localStorage.getItem('competitionIdForStudent') || null;
    const currentUser =
      (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user') || '')) ||
      null;

    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['payload']) {
        this.isResetPassPage = true;
        this.resetPasswordObj = this.commonService.base64ToBytes(
          params['payload']
        );
        this.loginUsername = this.resetPasswordObj.email;
      }
      if (params['invitecode']) {
        localStorage.setItem(
          'invitecode',
          JSON.stringify(params['invitecode'])
        );
        const invitecode = {
          invitecode: params['invitecode'],
        };
        this.checkUserExist(invitecode);
      }
      if (params['team_id'] && params['competition_id']) {
        const comeptitionObj = {
          team_id: params['team_id'],
          competition_id: params['competition_id'],
        };
        localStorage.setItem('comeptitionObj', JSON.stringify(comeptitionObj));
      }
      if (params['competitionId']) {
        localStorage.setItem(
          'competitionIdForStudent',
          params['competitionId']
        );
        const id = +params['competitionId'];
        if (currentUser) {
          this.router.navigate([
            `/student/competitions/join-competition/${id}`,
          ]);
        } else {
          this.router.navigate(['competition'], {
            queryParams: {
              competitionId: id,
            },
          });
        }
      }
      competitionIdForStudent =
        localStorage.getItem('competitionIdForStudent') || null;
      let parsedId = competitionIdForStudent && +competitionIdForStudent;
      if ((!token || !currentUser) && parsedId) {
        this.router.navigate(['competition'], {
          queryParams: {
            competitionId: parsedId,
          },
        });
      }

      if (currentUser) {
        this.router.navigate(['/student']);
      }
    });
    //   this.commonService.logout()
    this.sub = this.commonService.$curAccessToken.subscribe((item) => {
      if (item) {
        this.$curAccessToken = item;
      }
    });
    this.loadGoogleScript();
  }

  ngAfterViewInit(): void {
    this.loadGoogle();
  }

  loadGoogleScript() {
    const script2 = this.googleService.createScriptElement();
    script2.onload = this.loadGoogle;
    document.head.appendChild(script2);
  }

  toggleForgotPasswordScreen() {
    this.isForgotPassword = !this.isForgotPassword;
    this.loadGoogleScript();
  }

  checkUserExist(inviteCode) {
    this.commonService
      .saveRecord(`users/checkUserExistsWithInviteCode`, inviteCode)
      .subscribe(
        (res) => {
          if (!res.exists) {
            localStorage.setItem('invitedEmail', JSON.stringify(res['email']));
            this.router.navigate(['/signup']);
          }
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error,
            'error',
            3000,
            'Error'
          );
        }
      );
  }

  resetPassword() {
    if (!this.loginPassword) {
      this.commonService.showToast(
        'Please enter password',
        'warn',
        3000,
        'Info'
      );
      return;
    }
    if (this.loginPassword.length < 8) {
      this.commonService.showToast(
        'Password should be atleast 8 characters',
        'warn',
        3000
      );
      return;
    }
    if (this.confirmloginPassword != this.loginPassword) {
      this.commonService.showToast(
        'Password and Confirm password should be same',
        'warn',
        3000
      );
      return;
    }
    const data = {
      ...this.resetPasswordObj,
      password: this.loginPassword,
    };
    this.commonService.getResetLink('users/reset', { data: data }).subscribe(
      (res) => {
        this.commonService.showToast(
          'Password Changed successfully.',
          'info',
          3000,
          'Info'
        );
        this.isResetPassPage = false;
        this.router.navigate(['/login']);
      },
      (error) => {
        this.commonService.showToast(error.error.error, 'error', 3000, 'Error');
      }
    );
  }

  studentLogin() {
    if (this.isResetPassPage) {
      this.resetPassword();
      return;
    } else {
      if (!this.loginPassword || !this.loginUsername) {
        return;
      }
      if (!this.$curAccessToken) {
        this.commonService.showToast('Please wait...', 'info', 3000, 'Info');
        return;
      }
      this.submitted = true;
      this.usersService
        .login({
          data: { email: this.loginUsername, password: this.loginPassword },
        })
        .subscribe(
          (item) => {
            localStorage.setItem('token', item.token);
            localStorage.setItem('user', JSON.stringify(item.data));
            this.commonService.$curUser.next(item.data);
            this.commonService.$curUserToken.next(item.token);
            if (!item.data.isVerifiedEmail) {
              localStorage.setItem('unverfiedUser', JSON.stringify(item.data));
              this.router.navigate([`/signup/${item.data.id}`], {
                queryParams: { emailid: item.data.email, from: 'login' },
              });
            } else {
              this.navigateToDashboard(item);
            }
          },
          (err) => {
            this.commonService.showToast('Login failed!', 'warn', 3000, 'Info');

            // this.loginErr = err.error.message
            this.submitted = false;
            this.loginPassword = '';
            this.loginUsername = '';

            console.log(err);
          }
        );
    }
  }

  loadGoogle() {
    console.log('Google Loaded');
    window['handleCredentialResponse'] = (response) =>
      this.handleCredentialResponse(response);
    google &&
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
         prompt: 'select_account'
      });

    google &&
      google.accounts.id.renderButton(
        document.getElementsByClassName('g_id_signin')[0],
        { theme: 'outline', size: 'large' }
      );
    google && google.accounts.id.prompt();
  }

  handleCredentialResponse(response: any): void {
    const idToken = response.credential;
    this.googleService.sendToken(idToken).subscribe(
      (data) => {
        this.commonService.$curUser.next(data.data);
        this.commonService.$curUserToken.next(data.token);
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.data));
        setTimeout(() => {
          this.router.navigate(['/student/admission/US/dashboard']);
        }, 700);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  navigateToDashboard(item: any) {
    this.commonService.showToast('Login successful.', 'success', 3000, 'Info');

    if (this.config.data) {
      this.ref.close({ close: true });
    } else {
      this.router.navigate(['/student/admission/US/dashboard']);
    }
  }

  showPassFn() {
    this.showPass = !this.showPass;
  }

  showPassFnConfirm() {
    this.showPassConfirm = !this.showPassConfirm;
  }
  openSignup() {
    if (this.config.data) {
      this.commonService.show(SignUpComponent, {
        width: window.innerWidth > 900 ? '40vw' : '95vw',
        data: { isPopup: true },
      });
      this.ref.close();
    } else {
      this.router.navigate(['/signup']);
    }
  }

  showForgotPassword() {
    this.isForgotPassword = !this.isForgotPassword;
  }

  forgotPassword() {
    if (!this.loginUsername) {
      this.commonService.showToast('Please enter email', 'warn', 3000, 'Info');
      return;
    }
    const data = {
      email: this.loginUsername,
    };
    this.commonService.getResetLink('users/forgot', { data: data }).subscribe(
      (res) => {
        this.commonService.showToast(
          'Reset link sent to your email',
          'success',
          3000,
          'Info'
        );
        this.isForgotPassword = false;
        this.loginUsername = '';
        this.loginPassword = '';
      },
      (err) => {
        this.commonService.showToast(
          err.error.error ?? 'Please try again later',
          'error',
          3000,
          'Info'
        );
      }
    );
  }
}
