import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AwardSpecial } from '../judges.interface';

@Pipe({
  name: 'getImageForAwards',
  standalone: true,
})
export class GetImageForAwardsPipe implements PipeTransform {
  imageBaseURL: string = environment.apiUrl;
  transform(value: AwardSpecial | any, type: string): string {
    if (value.images && value.images.length > 0) {
      const getCertificateImage = value.images.find((image) => {
        if (image.type == type) {
          return image;
        }
      });
      if (getCertificateImage) {
        console.log(`${this.imageBaseURL}/${getCertificateImage.image}`);
        return `${this.imageBaseURL}common/getfile/${getCertificateImage.image}`;
      } else {
        return '../../../../assets/images/competition/winner.png';
      }
    } else {
      return '../../../../assets/images/competition/winner.png';
    }
  }
}
