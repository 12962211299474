import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';

@Component({
  selector: 'app-or-invite-signup',
  templateUrl: './or-invite-signup.component.html',
  styleUrls: ['./or-invite-signup.component.scss'],
})
export class OrInviteSignupComponent {
  password: string;
  confirmPassword: string;
  email: string;
  showPass: boolean = false;
  cnfPassFn: boolean = false;
  invitedCode: string;

  constructor(
    private activatedRouter: ActivatedRoute,
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.email = this.activatedRouter.snapshot.queryParamMap.get('email');
    this.invitedCode =
      this.activatedRouter.snapshot.queryParamMap.get('orginvitecode');
  }

  showPassFn() {
    this.showPass = !this.showPass;
  }
  showConfirmPassFn() {
    this.cnfPassFn = !this.cnfPassFn;
  }

  orgAdminLogin() {
    if (this.password !== this.confirmPassword) {
      this.commonService.showToast('Password should be same', 'error', 3000);
      return;
    }
    if (this.password.length < 8) {
      this.commonService.showToast(
        'Password should be atleast 8 characters',
        'error',
        3000
      );
      return;
    }
    if (!this.password) {
      this.commonService.showToast('Please enter password', 'error', 3000);
      return;
    }
    const data = {
      isCategorList: true,
      name: {
        email: this.email,
        password: this.password,
        invitecode: this.invitedCode,
      },
    };
    this.commonService.saveRecord(`org/users/accept`, data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Login Successful',
          'success',
          2000,
          'Info'
        );
        this.router.navigate(['organisation-admin-login']);
      },
      (error) => {
        this.commonService.showToast('Please try again', 'error', 3000);
      }
    );
  }
}
