import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-algo-header',
  templateUrl: './algo-header.component.html',
  styleUrls: ['./algo-header.component.scss'],
})
export class AlgoHeaderComponent {
  isCheckboxChecked = window.innerWidth < 768;
  loggedInUserName: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isCheckboxChecked = window.innerWidth < 768;
  }
  ngOnInit(): void {
    this.isCheckboxChecked = window.innerWidth < 769;
    this.loggedInUserName =
      localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user'));
  }
}
