import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getdate',
})
export class GetdatePipe implements PipeTransform {
  transform(value: string): number {
    return  + new Date(value).getDate();
  }
}
