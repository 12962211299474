import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';
import { Answer, ForumQuestion } from '../../models/shared.interface';
import { SharedMethodsService } from '../../services/shared-methods.service';
import { SharedApiService } from '../../services/shared-api.service';

@Component({
  selector: 'app-public-forum',
  templateUrl: './public-forum.component.html',
  styleUrls: ['./public-forum.component.scss'],
})
export class PublicForumComponent {
  link: string;
  selectedCountry: string;
  question: ForumQuestion;
  totalAnswers: number;
  totalComments: number;
  isBackButtonShow: boolean;
  loggedInUserDetail: any;
  answer: string;
  userCategory: number;
  isAnswerReplyOpen: any[] = [];
  replies: any[] = [];

  constructor(
    private activatedRouter: ActivatedRoute,
    private commonService: CommonServiceService,
    private sharedMethods: SharedMethodsService,
    private router: Router,
    private sharedApiService: SharedApiService
  ) {}

  ngOnInit(): void {
    this.userCategory =
      JSON.parse(localStorage.getItem('userCategory')) ?? null;
    this.loggedInUserDetail =
      JSON.parse(localStorage.getItem('user')) ?? null;
    this.commonService.getSelectedCountry().subscribe((res) => {
      this.selectedCountry = res;
    });
    this.activatedRouter.queryParams.subscribe((res) => {
      if (res['sharableLink']) {
        this.link = res['sharableLink'];
        this.getQuestionDetail();
      }
    });
    this.activatedRouter.params.subscribe((res) => {
      if (res['id']) {
        this.isBackButtonShow = true;
        this.link = res['id'];
        this.getQuestionSingleDetail();
      }
    });
  }

  navigateToBack() {
    const backRoute =
      this.userCategory == 1
        ? `admin/manage-forum`
        : `student/admission/${this.selectedCountry}/manage-forum`;
    this.router.navigate([backRoute]);
  }

  getQuestionSingleDetail() {
    this.commonService
      .getQuestionDetails(`questions/id/${this.link}`)
      .subscribe(
        (res) => {
          console.log(res.data);
          this.question = res.data;
          this.totalAnswers = res.data.Answers.length;
          this.question.Answers.forEach((res) => {
            res['isAddAnswerVisible'] = false;
            res['commentCount'] = res.Comments.length;
          });
        },
        (err) => {
          console.log(err);
          this.navigateToBack();
        }
      );
  }

  getQuestionDetail() {
    this.commonService.getQuestionDetails(`questions/${this.link}`).subscribe(
      (res) => {
        console.log(res.data);
        this.question = res.data;
        this.totalAnswers = res.data.Answers.length;
        this.question.Answers.forEach((res) => {
          res['commentCount'] = res.Comments.length;
          res['isRepliesOpen'] = false;
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  showReplyTextBox(answer: Answer) {
    if (!this.loggedInUserDetail) {
      this.router.navigate(['/login']);
    } else {
      answer.isRepliesOpen = !answer.isRepliesOpen;
    }
  }

  showNestedComments() {
    if (!this.loggedInUserDetail) {
      this.router.navigate(['/login']);
    }
  }

  showAddAnswerTextBox(question: ForumQuestion) {
    if (!this.loggedInUserDetail) {
      this.router.navigate(['/login']);
    } else {
      question.isAddAnswerVisible = !question.isAddAnswerVisible;
    }
  }

  saveAnswer(question: ForumQuestion) {
    if (this.answer == null) {
      this.commonService.showToast('Please enter an answer', 'warn', 3000);
      return;
    }
    const data = {
      name: {
        content: this.answer,
        questionid: question.id,
      },
      isCategorList: true,
    };

    this.sharedApiService.saveRecord('questions/answer', data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Answer saved successfully',
          'success',
          1000
        );
        this.answer = null;
        question.answerCount = question.answerCount + 1;
        if (!question.isCommentsOpened) {
          const savedAnswer = {
            ...res.data,
            Comments: [],
            isRepliesOpen: false,
            isEditMode: false,
            replyCount: res.replyCount,
            User: {
              firstName: this.loggedInUserDetail.firstName,
              lastName: this.loggedInUserDetail.lastName,
              email: this.loggedInUserDetail.email,
              country: this.loggedInUserDetail.country,
            },
          };
          if (res.data.isAdmin) {
            this.question.Answers.unshift(savedAnswer);
          } else {
            this.question['Answers'].push(savedAnswer);
          }
        }
        //
      },
      (error) => {
        this.commonService.showToast(
          error.error.error ?? 'Error while saving an answer',
          'success',
          1000
        );
      }
    );
  }

  addReply(answer: Answer, index: number, question: ForumQuestion) {
    if (this.replies[index] == null) {
      this.commonService.showToast('Please enter reply', 'warn', 3000);
      return;
    }
    answer.isRepliesOpen = true;
    const record = {
      isCategorList: true,
      name: {
        answerid: answer.id,
        content: this.replies[index],
        questionid: question.id,
      },
    };
    this.sharedMethods.saveReply(
      answer,
      record,
      this.replies,
      index,
      this.loggedInUserDetail
    );
  }
}
