<div class="myChartWrapper">
  <div
    class="myChart"
    [style.width]="100 + '%'"
    height="100%"
    [style.myChartWrapper]="chartWidth"
  >
    <canvas #myChart></canvas>
  </div>
</div>
