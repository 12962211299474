<!-- competition cards -->
<div class="competition-cards">
  <div
    class="card shadow-none"
    *ngFor="let competition of updatedCompetitions | sortCompetitions"
  >
    <div class="card-body d-flex flex-column">
      <div class="d-flex flex-column gap-3 pb-0">
        <figure class="card-figure m-0">
          <img
            class="img-fluid"
            [ngSrc]="
              competition.thumbnail
                ? imageBaseURL + 'common/getfile/' + competition.thumbnail
                : '../../../../assets/images/student-portal/competition-img.png'
            "
            [width]="320"
            [height]="180"
            [alt]="competition?.name ?? 'Competition Image'"
          />

          <button
            class="btn btn-white btn-share p-2"
            (click)="share(competition.details)"
          >
            <img
              src="../../../../assets/competitions/card-share.svg"
              class="img-fluid"
              alt="Share"
            />
          </button>
        </figure>
        <div class="flex-grow-1 overflow-hidden mb-2">
          <h6 class="card-title mt-2 text-break" [title]="competition?.name">
            {{ competition?.name }}
          </h6>

          <!-- Priority Registration -->
          <div
            class="text-priority d-flex align-items-center gap-2"
            *ngIf="isRegisterationOpen"
          >
            <span class="d-flex" *ngIf="!competition?.isUpcoming">
              <!-- [ngClass]="{
                  'in-progress': 
                }" -->

              <!-- In Progress -->
              <img
                *ngIf="competition?.status === 'In Progress'"
                src="../../../../assets/competitions/in-progress-icon.svg"
                class="img-fluid"
                alt="In Progress"
              />

              <!-- Late Registration -->
              <img
                *ngIf="competition?.status !== 'In Progress'"
                src="../../../../assets/competitions/double-arrow-up.svg"
                class="img-fluid"
                alt="Double Arrow Up"
              />
            </span>

            <!-- Coming Soon -->
            <span class="d-flex" *ngIf="competition?.isUpcoming">
              <img
                src="../../../../assets/svgs/competition-status.svg"
                alt=""
                srcset=""
              />
            </span>

            <span
              class="text-uppercase fw-bolder"
              [ngClass]="{
                'text-primary': competition?.isUpcoming,
                'in-progress': competition?.status === 'In Progress'
              }"
            >
              {{
                competition?.isUpcoming
                  ? "Coming Soon"
                  : competition?.status != "Registration"
                  ? competition?.status
                  : competition?.registration_stage + " " + "Registration"
              }}
            </span>
          </div>
          <!-- End: Priority Registration -->

          <!-- Registration Closed -->
          <div
            class="text-reg-close d-flex align-items-center gap-2"
            *ngIf="!isRegisterationOpen"
          >
            <span class="d-flex align-items-center">
              <img
                src="../../../../assets/competitions/reg-closed.svg"
                class="img-fluid"
                alt="Registration Closed"
              />
            </span>
            <span class="text-uppercase fw-bolder"> Registration Closed </span>
          </div>
          <!-- End: Registration Closed -->

          <!-- timer section -->
          <div class="mt-2">
            <!-- ENDS IN -->
            <div
              class="d-flex align-items-center gap-2"
              *ngIf="isRegisterationOpen"
            >
              <span>
                <img
                  src="../../../../assets/competitions/ends-in-clock.svg"
                  class="img-fluid"
                  alt="Ends In"
                />
              </span>
              <span class="text-endsin"> ENDS IN </span>
            </div>
            <div class="text-endsin" *ngIf="!isRegisterationOpen">
              Registration Deadline
            </div>
            <!-- ENDS IN -->

            <!-- timer -->
            <div class="d-flex flex-wrap timer-joiner-section">
              <app-countdown-timer
                *ngIf="isRegisterationOpen"
                [endTime]="competition?.status_time_update"
                [isUpcomingCompetition]="
                  competition?.status == 'Registration' ? false : true
                "
              ></app-countdown-timer>

              <div
                *ngIf="!isRegisterationOpen"
                class="timer-section flex-grow-1 px-2 ps-0"
              >
                <span class="text-time">{{
                  competition?.regular_registration_close
                    | date : "dd MMM yy, hh:mm a"
                }}</span>
              </div>
              <!-- End: timer section -->
            </div>
          </div>
        </div>
      </div>
      <!-- Join Now Buttons -->
      <div
        class="d-flex gap-2 pt-3 competition-card-footer mt-auto"
        *ngIf="
          isRegisterationOpen && !competition?.isUpcoming;
          else notUpcoming
        "
      >
        <button
          class="btn btn-join-now d-flex justify-content-center gap-2 w-100"
          (click)="storeCompetition(competition)"
          [disabled]="
            (!isUserLoggedIn && competition?.registration_stage == 'Closed') ||
            competition?.team_disqualified ||
            (!competition?.isJoined && competition?.status !== 'Registration')
          "
          *ngIf="
            !competition?.team_disqualified &&
            ((competition?.status == 'In Progress' && competition?.isJoined) ||
              competition?.status == 'Registration')
          "
        >
          <span
            class="text-decoration-underline link-underline-light link-offset-1"
          >
            {{ competition?.isJoined ? "My Progress" : "Join now" }}</span
          >
          <span>
            <img
              src="../../../../assets/competitions/tilt-arrow.svg"
              class="img-fluid"
              alt="Join now"
            />
          </span>
        </button>
        <button
          type="button"
          (click)="navigatetoDetails(competition?.details)"
          class="btn btn-view-details link-dark link-underline-opacity-100-hover w-100 d-flex justify-content-center align-items-center"
        >
          View Details
        </button>
      </div>

      <ng-template #notUpcoming>
        <div class="d-flex flex-column pt-3 mt-auto">
          <button
            class="btn btn-outline-primary w-100 rounded opacity-100 fw-medium"
            disabled
          >
            Coming Soon...
          </button>
        </div>
      </ng-template>
      <!-- End: Join Now Buttons -->
    </div>
    <!-- End: competition cards -->
  </div>
</div>
