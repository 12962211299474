import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { SharedMethodsService } from 'src/app/shared/services/shared-methods.service';
import {
  ConvertedDataStructure,
  TimeSpentData,
} from '../../or-shared/or.interface';
import { OrSharedService } from '../../services/or-shared.service';

@Component({
  selector: 'app-or-student-details',
  templateUrl: './or-student-details.component.html',
  styleUrls: ['./or-student-details.component.scss'],
})
export class OrStudentDetailsComponent implements OnInit {
  loggedInUserName: any;
  globalData: any;

  timeSpentFilter: string = 'week';
  lessonCompletedFilter: string = 'week';
  questionsFilter: string = 'week';

  selectedData: any[] = [];

  timeSpentData: TimeSpentData;
  lessonCompletedData: TimeSpentData;
  questionsData: TimeSpentData;
  user: number;
  endpoint: string;
  isSuperAdmin: boolean;
  yearOption: any[] = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
  ];

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private commonService: CommonServiceService,
    private helperOrService: OrSharedService,
    private sharedService: SharedMethodsService
  ) {}

  ngOnInit(): void {
    this.loggedInUserName =
      localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user'));
    this.router.params.subscribe((params) => {
      this.user = +this.route.url.split('/')[3];
      const route = this.route.url;
      switch (route) {
        case `/organisation/app-shell/student-details/${params['id']}`:
          this.endpoint = 'org/students/';
          this.isSuperAdmin = false;
          break;
        case `/admin/organisation-details/${this.user}/student-details/${params['id']}`:
          this.endpoint = 'admin/students/';
          this.isSuperAdmin = true;
          break;
      }
      this.getStudentDetails(params['id']);
    });
  }

  getStudentDetails(id: number) {
    this.commonService.getByIdDetail(this.endpoint, id).subscribe(
      (res) => {
        let data: ConvertedDataStructure;
        if (this.isSuperAdmin) {
          const adminObj = {
            lesson_completed: res.dashboard_data.lesson_completed,
            question_asked: res.dashboard_data.question_asked,
            time_spent: res.dashboard_data.time_spent,
            user_data: res.user_data,
          };
          data = this.helperOrService.convertKeysToStructuredFormat(adminObj);
        } else {
          data = this.helperOrService.convertKeysToStructuredFormat(res.data);
        }
        this.globalData = data;

        this.onTimeSpentChange(
          'timeSpentData',
          this.timeSpentFilter,
          'time_spent'
        );
        this.onTimeSpentChange(
          'lessonCompletedData',
          this.lessonCompletedFilter,
          'lesson_completed'
        );
        this.onTimeSpentChange(
          'questionsData',
          this.questionsFilter,
          'question_asked'
        );
        console.log(this.timeSpentData);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * onTimeSpentChange function to handle the time spent filter change and update the time spent data accordingly.
   */
  onTimeSpentChange(graphData, selectedFilter, keyName) {
    switch (selectedFilter) {
      case 'week':
        this[graphData] = {
          labels: this.globalData.time_spent.week.labels.map((x) =>
            this.sharedService.getDayNameByDate(x)
          ),
          data: this.globalData[keyName].week.data,
        };
        break;
      case 'month':
        const getCurrentYear = new Date().getFullYear();
        const getCurrentMonth = new Date().getMonth();
        const dates = this.sharedService
          .getDatesForMonth(getCurrentYear, getCurrentMonth)
          .map((x) => new Date(x).getDate());
        this[graphData] = {
          labels: dates,
          data: this.globalData[keyName].month.data,
        };
        console.log(this[graphData]);
        break;
      case 'year':
        this[graphData] = {
          labels: this.sharedService.months,
          data: this.globalData[keyName].year.data,
        };
        break;
    }
  }
}
