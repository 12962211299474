import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ConvertedData,
  ConvertedDataStructure,
  TimeSpentData,
  UserActivity,
} from '../or-shared/or.interface';

@Injectable({
  providedIn: 'root',
})
export class OrSharedService {
  isNameChanged: BehaviorSubject<any> = new BehaviorSubject(
    (localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user'))) ||
      true
  );
  constructor() {}

  convertKeysToStructuredFormat(
    userActivity: UserActivity
  ): ConvertedDataStructure {
    const convertArrayToStructuredFormat = (
      arr: any[],
      labelKey: string,
      dataKey: string
    ): TimeSpentData => {
      const labels: string[] = arr.map(
        (item) => item[labelKey]?.toString() ?? ''
      );
      const data: number[] = arr.map((item) => item[dataKey] ?? 0);
      return { labels, data };
    };

    return {
      time_spent: {
        week: convertArrayToStructuredFormat(
          userActivity.time_spent.week,
          'date',
          'active_minutes'
        ),
        month: convertArrayToStructuredFormat(
          userActivity.time_spent.month,
          'date',
          'active_minutes'
        ),
        year: convertArrayToStructuredFormat(
          userActivity.time_spent.year,
          'month',
          'active_minutes'
        ),
      },
      lesson_completed: {
        week: convertArrayToStructuredFormat(
          userActivity.lesson_completed.week,
          'date',
          'lesson_completed'
        ),
        month: convertArrayToStructuredFormat(
          userActivity.lesson_completed.month,
          'date',
          'lesson_completed'
        ),
        year: convertArrayToStructuredFormat(
          userActivity.lesson_completed.year,
          'month',
          'lesson_completed'
        ),
      },
      question_asked: {
        week: convertArrayToStructuredFormat(
          userActivity.question_asked.week,
          'date',
          'question_asked'
        ),
        month: convertArrayToStructuredFormat(
          userActivity.question_asked.month,
          'date',
          'question_asked'
        ),
        year: convertArrayToStructuredFormat(
          userActivity.question_asked.year,
          'month',
          'question_asked'
        ),
      },
      user_data: userActivity.user_data,
    };
  }
}
