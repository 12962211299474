<p-toast></p-toast>
<div *ngIf="loader.isLoading$ | async">
    <div class="spinner-wrapper">
        <div class="spinner-border" role="status">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<!-- <app-loader></app-loader> -->
<!--<p-sidebar [(visible)]="visibleSidebar" position="bottom" [baseZIndex]="10000">-->
<!--    <app-background-upload-handler></app-background-upload-handler>-->
<!--</p-sidebar>-->
<!--<p-dialog [(visible)]="signInPopup" [modal]="true" [style]="{width: '30vw'}" [draggable]="false" [resizable]="false">-->
<!--    <div class="modal-content">-->
<!--        <div class="model-container">-->
<!--            <app-sign-in></app-sign-in>-->
<!--        </div>-->
<!--    </div>-->
<!--</p-dialog>-->


<!--<p-dialog [(visible)]="signUpPopup" [modal]="true" [style]="{width: '70vw'}" [draggable]="false" [resizable]="false">-->
<!--    <div class="modal-content">-->
<!--        <div class="model-container">-->
<!--            <app-sign-up></app-sign-up>-->
<!--        </div>-->
<!--    </div>-->
<!--</p-dialog>-->