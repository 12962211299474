import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Competitions } from '../../models/competions.models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharableLinkComponent } from 'src/app/shared/components/sharable-link/sharable-link.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-co-detail-card',
  templateUrl: './co-detail-card.component.html',
  styleUrls: ['./co-detail-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoDetailCardComponent {
  @HostListener('window:resize', ['$event']) onResize(event: Event): void {
    this.checkDeviceType();
  }

  @Input() isRegisterationOpen: boolean;
  @Input() competitions: Competitions[];
  @Input() myCompetition: Competitions[];

  updatedCompetitions: Competitions[] = [];

  imageBaseURL: string = environment.apiUrl;
  shareVideoLink: string;
  ref: DynamicDialogRef;
  sharePopupPosition: string;
  deviceType: string;
  isUserLoggedIn: boolean;

  constructor(
    public dialogService: DialogService,
    private commonService: CommonServiceService,
    private router: Router
  ) {
    this.checkDeviceType();
  }

  ngOnChanges(changes: any): void {
    this.updatedCompetitions = [...this.competitions];
    this.updatedCompetitions.forEach((x) => {
      const isJoined =
        this.myCompetition &&
        this.myCompetition?.length > 0 &&
        this.myCompetition.find((y) => x.id == y.id);
      if (isJoined) {
        x.isJoined = true;
        x.team_id = isJoined.team_id;
      } else {
        x.isJoined = false;
      }
    });
  }

  ngOnInit(): void {
    localStorage.setItem('comeptitionDetails', null);
    this.isUserLoggedIn =
      localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))
        ? true
        : false;
  }

  storeCompetition(competition: Competitions) {
    if (competition?.team_disqualified) {
      return;
    }
    localStorage.setItem('comeptitionDetails', JSON.stringify(competition));
    if (!this.isUserLoggedIn) {
      if (competition.registration_stage === 'Closed') {
        return;
      }
      this.router.navigate(['/competition'], {
        queryParams: { competitionId: competition.id },
      });
    } else {
      if (competition.individual == 1) {
        if (competition.registration_stage == 'Closed') {
          this.router.navigate([
            `/student/competitions/${competition.id}/team/${competition.team_id}/submissions`,
          ]);
        } else {
          this.router.navigate([
            `student/competitions/join-competition/${competition.id}`,
          ]);
        }
      } else {
        if (competition.isJoined) {
          if (competition.registration_stage == 'Closed') {
            this.router.navigate([
              `/student/competitions/${competition.id}/team/${competition.team_id}/submissions`,
            ]);
          } else {
            this.router.navigate([
              'student/competitions',
              competition.id,
              'team',
              competition.team_id,
              'team-details',
            ]);
          }
        } else {
          this.router.navigate([
            'student/competitions/join-competition',
            competition.id,
          ]);
        }
      }
    }
  }

  navigatetoDetails(link: string) {
    window.open(link, '_blank');
  }

  share(link: any) {
    this.shareVideoLink = link;
    if (navigator.share && this.deviceType === 'Mobile') {
      navigator
        .share({
          title: 'Algoed | Competitions',
          url: `${link}`,
        })
        .catch((err) => {
          console.error('Error sharing:', err);
        });
    } else {
      if (link) {
        this.ref = this.dialogService.open(SharableLinkComponent, {
          header: 'Share',
          height: '320px',
          width: '100%',
          style: { maxWidth: '600px' },
          position: this.sharePopupPosition,
          styleClass: 'leader-modal',
          contentStyle: {
            overflow: 'auto',
          },
          baseZIndex: 10000,
          data: {
            isDirectURL: true,
            video: `${link}`,
          },
        });
      } else {
        this.commonService.showToast('No Sharable Link Found', 'error', 3000);
      }
    }
  }

  private checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.sharePopupPosition = 'bottom';
    } else {
      this.sharePopupPosition = 'center';
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
