<!-- <div class="d-flex flex-coumn flex-lg-row gap-3"> -->
<div
  infiniteScroll
  [infiniteScrollDistance]="3"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
  class="d-flex flex-column flex-grow-1 gap-3 overflow-vertical"
>
  <div *ngIf="!isAdmin">
    <!-- Search & Filter -->
    <div
      class="p-1"
      [ngClass]="{
        'border-bottom pb-3': !isYourQuestionVisible,
        'mb-3': isYourQuestionVisible
      }"
    >
      <div class="row g-3">
        <div class="col-12" [ngClass]="{ 'col-lg-5': isYourQuestionVisible }">
          <app-search
            class="w-100"
            [searchMaxWidth]="'initial'"
            [placeholder]="'Search Questions'"
            [searchFunction]="onSearch.bind(this)"
            (searchResults)="handleSearchResults($event)"
          ></app-search>
        </div>
        <div class="col-12" [ngClass]="{ 'col-lg-3': isYourQuestionVisible }">
          <p-multiSelect
            [options]="tagList"
            [(ngModel)]="selectedTagsForFilter"
            placeholder="Filter by Tags"
            [appendTo]="'body'"
            optionValue="id"
            optionLabel="tag_name"
            (ngModelChange)="onTagSelected()"
          ></p-multiSelect>
        </div>
        <div class="col-12 col-lg-4 pe-4" *ngIf="isYourQuestionVisible">
          <p-dropdown
            [options]="countries"
            [(ngModel)]="selectedCountryForFilter"
            placeholder="Filter by Country"
            [appendTo]="'body'"
            [showClear]="true"
            optionValue="name"
            optionLabel="name"
            (ngModelChange)="onCountrySelected()"
            class="d-block w-100"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <!-- End: Search & Filter -->

    <!-- Editor Section -->
    <p-card *ngIf="isYourQuestionVisible">
      <div class="d-flex flex-column flex-sm-row gap-3">
        <!-- avatar -->
        <figure class="avatar m-0">
          <span>{{ currentItems?.firstName[0].toUpperCase() ?? "" }}</span>
        </figure>
        <!-- End: avatar -->

        <!-- ask question here -->
        <div class="flex-grow-1">
          <div class="mb-3">
            <input
              pInputText
              placeholder="Question Title"
              [(ngModel)]="title"
            />
          </div>
          <div>
            <p-editor
              placeholder="Question Description"
              [(ngModel)]="content"
              [appendTo]="'body'"
              [style]="{ width: '100%', height: '150px' }"
            >
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-bold"
                    aria-label="Bold"
                  ></button>
                  <button
                    type="button"
                    class="ql-italic"
                    aria-label="Italic"
                  ></button>
                  <button
                    type="button"
                    class="ql-underline"
                    aria-label="Underline"
                  ></button>
                  <button
                    type="button"
                    class="ql-image"
                    aria-label="image"
                  ></button>
                  <button
                    type="button"
                    class="ql-code-block"
                    aria-label="code-block"
                  ></button>
                </span>
              </ng-template>
            </p-editor>
          </div>
          <!-- <div class="mt-3">
          <p-multiSelect
            [options]="tagList"
            [(ngModel)]="selectedTags"
            placeholder="Select Tags"
            [appendTo]="'body'"
            optionValue="id"
            optionLabel="tag_name"
          ></p-multiSelect>
        </div> -->

          <!-- Ask/Post -->
          <div
            class="d-flex align-items-center justify-content-between mt-3 gap-3 flex-wrap"
          >
            <div class="d-flex align-items-center gap-2 flex-wrap">
              <p-button
                *ngFor="let tag of tagList"
                [rounded]="true"
                size="small"
                [outlined]="true"
                [severity]="tag.isAdded ? 'primary' : 'secondary'"
                (click)="addTag(tag)"
              >
                <span class="pi pi-plus"></span>
                <span class="ms-2">{{ tag.tag_name }}</span>
              </p-button>
            </div>
            <button
              pRipple
              pButton
              label="Ask"
              icon="pi pi-question-circle"
              class="p-primary"
              (click)="addQuestion()"
            ></button>
          </div>
        </div>
        <!-- End: ask question here -->
      </div>
    </p-card>
    <!-- End: Editor Section -->
  </div>

  <p-card *ngIf="isAdmin">
    <div class="d-flex flex-column flex-sm-row gap-3">
      <!-- avatar -->
      <figure class="avatar m-0">
        <span>{{ currentItems?.firstName[0].toUpperCase() ?? "" }}</span>
      </figure>
      <!-- End: avatar -->

      <!-- ask question here -->
      <div class="flex-grow-1">
        <div class="mb-3">
          <input pInputText placeholder="Question Title" [(ngModel)]="title" />
        </div>
        <div class="mb-3">
          <input pInputText placeholder="First Name" [(ngModel)]="first_name" />
        </div>
        <div class="mb-3">
          <input pInputText placeholder="Last Name" [(ngModel)]="last_name" />
        </div>
        <div class="mb-3">
          <!-- <input pInputText placeholder="Country" [(ngModel)]="country" /> -->
          <p-dropdown
            [options]="countries"
            class="w-100"
            [(ngModel)]="country"
            placeholder="Country"
            [appendTo]="'body'"
            optionValue="name"
            optionLabel="name"
          ></p-dropdown>
        </div>
        <div class="mb-3">
          <p-dropdown
            [options]="['US', 'UK']"
            [(ngModel)]="superAdminUSUK"
            placeholder="US or UK"
            [appendTo]="'body'"
            (ngModelChange)="getTagsByCountry()"
            class="d-block w-100"
          ></p-dropdown>
        </div>
        <div>
          <p-editor
            placeholder="Question Description"
            [(ngModel)]="content"
            [appendTo]="'body'"
            [style]="{ width: '100%', height: '150px' }"
          >
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button
                  type="button"
                  class="ql-bold"
                  aria-label="Bold"
                ></button>
                <button
                  type="button"
                  class="ql-italic"
                  aria-label="Italic"
                ></button>
                <button
                  type="button"
                  class="ql-underline"
                  aria-label="Underline"
                ></button>
                <button
                  type="button"
                  class="ql-image"
                  aria-label="image"
                ></button>
                <button
                  type="button"
                  class="ql-code-block"
                  aria-label="code-block"
                ></button>
              </span>
            </ng-template>
          </p-editor>
        </div>
        <!-- Ask/Post -->
        <div
          class="d-flex align-items-center justify-content-between mt-3 gap-3 flex-wrap"
        >
          <div class="d-flex align-items-center gap-2 flex-wrap">
            <p-button
              *ngFor="let tag of tagList"
              [rounded]="true"
              size="small"
              [outlined]="true"
              [severity]="tag.isAdded ? 'primary' : 'secondary'"
              (click)="addTag(tag)"
            >
              <span class="pi pi-plus"></span>
              <span class="ms-2">{{ tag.tag_name }}</span>
            </p-button>
          </div>
          <button
            pRipple
            pButton
            label="Ask"
            icon="pi pi-question-circle"
            class="p-primary"
            (click)="addQuestionSuperAdmin()"
          ></button>
        </div>
      </div>
      <!-- End: ask question here -->
    </div>
  </p-card>
  <p-card *ngIf="isAdmin">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-2">
        <label> Filter By Country </label>
        <p-dropdown
          [options]="['US', 'UK']"
          [(ngModel)]="selectedCountryFilterForAdmin"
          placeholder="Filter by Country"
          [appendTo]="'body'"
          (ngModelChange)="adminCountryFilter()"
        ></p-dropdown>
      </div>
      <p-button
        label="Manage Tags"
        icon="pi pi-tag"
        [outlined]="true"
        [size]="'small'"
        (click)="navigateToTags()"
      ></p-button>
    </div>
  </p-card>

  <p-card
    class="forum-question-content"
    *ngFor="let question of questionsList; let i = index"
  >
    <div
      class="forum-question-card d-flex flex-column flex-sm-row justify-content-between align-items-sm-center"
    >
      <div class="forum-question-card-content d-flex gap-3 p-3 flex-grow-1">
        <div class="user-details">
          <!-- avatar -->
          <figure class="avatar m-0">
            <span>{{ question.User?.firstName[0].toUpperCase() ?? "" }}</span>
          </figure>
          <!-- End: avatar · -->
          <!-- User name Details -->
          <h6 class="forum-username m-0 fw-medium">
            {{ question.User?.firstName }}
          </h6>
          <span class="forum-user-country">
            {{ question.User?.country ?? "N/A" }}
          </span>
          <div class="share-btn-wrapper">
            <span class="cursor-pointer">
              <img
                src="../../../../assets/icons/share-icon.svg"
                (click)="shareQuestion(question)"
                alt="Share"
              />
            </span>
          </div>
          <!-- End: User name Details -->
        </div>
        <div class="flex-grow-1">
          <h6
            (click)="loadQuestionDetails(question)"
            class="m-0 fs-5 fw-medium cursor-pointer link"
            [innerHTML]="question.title | renderContent"
            appDynamicStyle
          ></h6>
          <p
            class="text-desc mt-2"
            [innerHTML]="question.content | renderContent"
            appDynamicStyle
          ></p>
          <div
            class="d-flex justify-content-end flex-wrap mt-3 gap-2"
            *ngIf="question?.Tags?.length > 0"
          >
            <p-badge
              *ngFor="let tag of question.Tags; let j = index"
              class="cursor-pointer"
              [value]="tag.tag_name"
              [severity]="'warning'"
            ></p-badge>
          </div>
        </div>
      </div>
      <div class="px-3 px-sm-0 pe-sm-3" *ngIf="isAdmin">
        <p-badge
          class="cursor-pointer"
          [value]="question.statusLabel"
          (click)="op.toggle($event)"
          [severity]="question.status == 'REJECT_MANUAL' ? 'danger' : 'info'"
        ></p-badge>
        <p-overlayPanel #op>
          <ng-template pTemplate="content">
            <p-listbox
              [options]="listItems"
              (onClick)="changeStatus($event, op, question)"
              optionLabel="name"
              optionValue="status"
              [style]="{ width: '8rem' }"
              [listStyle]="{ 'max-height': '220px' }"
            ></p-listbox>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>
    <!-- question body  -->
    <!-- Action Btns -->
    <div
      class="forum-action-footer-btns d-flex flex-wrap align-items-center p-3"
    >
      <div
        class="d-flex flex-wrap align-items-center gap-3"
        [ngClass]="{ 'ms-lg-5 ps-2': isYourQuestionVisible }"
      >
        <button
          pButton
          pRipple
          icon="pi pi-user-edit"
          label="Add your answer"
          size="small"
          class="p-primary p-button-sm"
          (click)="showAddAnswerTextBox(question)"
        ></button>
      </div>
      <div class="d-flex flex-wrap align-items-center flex-grow-1 gap-3">
        <div
          class="d-flex gap-2 comments-btn cursor-pointer"
          (click)="showAnswers(question)"
        >
          <i class="pi pi-comments"></i>
          <span>{{
            question.answerCount == 1
              ? question.answerCount + " Answer"
              : question.answerCount + " Answers"
          }}</span>
        </div>
        <div class="d-flex flex-wrap align-items-center ms-auto gap-3">
          <div class="avatar-group d-flex align-items-center">
            <span class="avatar-images" *ngFor="let avatar of question?.fillTheArrayForRepiles">
              <img
                style="height: 24px"
                src="../../../../assets/user-128.png"
                alt="Comment Avatar"
              />
            </span>
          </div>
          <div class="share-btn-wrapper">
            <span class="cursor-pointer">
              <img
                src="../../../../assets/icons/share-icon.svg"
                (click)="shareQuestion(question)"
                alt="Share"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Action Btns -->
    <!-- Add Answer input box area -->
    <div *ngIf="!isAdmin">
      <div
        class="d-flex align-items-center gap-3 p-3 flex-grow-1"
        *ngIf="question.isAddAnswerVisible"
      >
        <!-- avatar -->
        <figure class="avatar m-0">
          <span>{{ currentItems?.firstName[0].toUpperCase() ?? "" }}</span>
        </figure>
        <!-- End: avatar -->
        <div class="add-answer-box flex-grow-1">
          <p-editor
            placeholder="Add you answer"
            [(ngModel)]="addAnswer[i]"
            [appendTo]="'body'"
            [style]="{ width: '100%', height: '100px' }"
          >
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button
                  type="button"
                  class="ql-bold"
                  aria-label="Bold"
                ></button>
                <button
                  type="button"
                  class="ql-italic"
                  aria-label="Italic"
                ></button>
                <button
                  type="button"
                  class="ql-underline"
                  aria-label="Underline"
                ></button>
                <button
                  type="button"
                  class="ql-image"
                  aria-label="image"
                ></button>
                <button
                  type="button"
                  class="ql-code-block"
                  aria-label="code-block"
                ></button>
              </span>
            </ng-template>
          </p-editor>
          <span
            class="send-img-wrapper cursor-pointer"
            (click)="saveAnswer(question, i)"
          >
            <img src="../../../../assets/icons/send.svg" alt="Send" />
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin">
      <div
        class="d-flex align-items-center gap-3 p-3 flex-grow-1"
        *ngIf="question.isAddAnswerVisible"
      >
        <!-- avatar -->
        <figure class="avatar m-0">
          <span>{{ currentItems?.firstName[0].toUpperCase() ?? "" }}</span>
          <!-- <span>{{ currentItems?.firstName[0].toUpperCase() ?? "" }}</span> -->
        </figure>
        <!-- End: avatar -->
        <div class="add-answer-box flex-grow-1">
          <div class="mb-3">
            <input
              pInputText
              placeholder="First Name"
              [(ngModel)]="first_name"
            />
          </div>
          <div class="mb-3">
            <input pInputText placeholder="Last Name" [(ngModel)]="last_name" />
          </div>
          <div class="mb-3">
            <p-dropdown
              [options]="countries"
              class="w-100"
              [(ngModel)]="country"
              placeholder="Country"
              [appendTo]="'body'"
              optionValue="name"
              optionLabel="name"
            ></p-dropdown>
          </div>
          <p-editor
            placeholder="Add Your Answer (For Admin)"
            [(ngModel)]="addAnswer[i]"
            [appendTo]="'body'"
            [style]="{ width: '100%', height: '100px' }"
          >
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button
                  type="button"
                  class="ql-bold"
                  aria-label="Bold"
                ></button>
                <button
                  type="button"
                  class="ql-italic"
                  aria-label="Italic"
                ></button>
                <button
                  type="button"
                  class="ql-underline"
                  aria-label="Underline"
                ></button>
                <button
                  type="button"
                  class="ql-image"
                  aria-label="image"
                ></button>
                <button
                  type="button"
                  class="ql-code-block"
                  aria-label="code-block"
                ></button>
              </span>
            </ng-template>
          </p-editor>
          <span
            class="send-img-wrapper cursor-pointer"
            (click)="saveAnswerSuperAdmin(question, i)"
          >
            <img src="../../../../assets/icons/send.svg" alt="Send" />
          </span>
        </div>
      </div>
    </div>

    <!-- Add Answer input box area -->
    <!-- question body -->

    <ng-container *ngIf="question.isCommentsOpened">
      <!-- Comments list -->
      <div
        class="d-flex gap-3 px-3 pt-3 forum-add-answer-area"
        *ngFor="let answer of question.Answers; let k = index"
      >
        <!-- avatar -->
        <figure class="avatar m-0">
          <span>{{ answer.User?.firstName[0].toUpperCase() ?? "" }}</span>
        </figure>
        <!-- End: avatar -->

        <!-- Comment section -->
        <div class="flex-grow-1 border-bottom pb-3">
          <div>
            <h6 class="m-0 fs-6 fw-medium">
              <small>{{ answer.User?.firstName }} </small>
              <span class="mx-2 forum-user-country">
                {{ answer.User?.country ?? "N/A" }}
              </span>
            </h6>
            <div class="text-muted m-0 d-flex justify-content-between">
              <small
                class="py-2"
                *ngIf="!answer.isEditMode"
                [innerHTML]="answer?.content | renderContent"
              ></small>
              <ng-container *ngIf="currentItems.email === answer.User?.email">
                <div class="mt-3" *ngIf="answer.isEditMode">
                  <p-editor
                    [(ngModel)]="editedAnswer[k]"
                    placeholder="Edit Answer"
                  >
                    <ng-template pTemplate="header">
                      <span class="ql-formats">
                        <button
                          type="button"
                          class="ql-bold"
                          aria-label="Bold"
                        ></button>
                        <button
                          type="button"
                          class="ql-italic"
                          aria-label="Italic"
                        ></button>
                        <button
                          type="button"
                          class="ql-underline"
                          aria-label="Underline"
                        ></button>
                        <button
                          type="button"
                          class="ql-image"
                          aria-label="image"
                        ></button>
                        <button
                          type="button"
                          class="ql-code-block"
                          aria-label="code-block"
                        ></button>
                      </span> </ng-template
                  ></p-editor>
                </div>
                <div class="d-flex">
                  <span
                    class="cursor-pointer text-info ms-3"
                    (click)="toggleToEditAnswer(question, answer, i, k)"
                  >
                    <i class="pi pi-pencil"></i>
                  </span>
                  <span
                    class="cursor-pointer text-danger ms-3"
                    (click)="deleteAnswer(question, answer, k)"
                  >
                    <i class="pi pi-trash"></i>
                  </span>
                  <span
                    *ngIf="answer.isEditMode"
                    class="cursor-pointer ms-3 text-danger"
                    (click)="cancelEditAnswerMode(answer, k)"
                  >
                    <i class="pi pi-times"></i>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="d-eflex gap-2 mt-2">
            <p-button
              icon="pi pi-user-edit"
              label="Add your reply"
              [rounded]="true"
              severity="info"
              [text]="true"
              size="small"
              (click)="showReplyTextBox(answer)"
              class="reply-btn"
            ></p-button>
            <p-button
              icon="pi pi-reply"
              [label]="answer.replyCount + ' Show Reply'"
              [rounded]="true"
              [text]="true"
              severity="success"
              size="small"
              (click)="showNestedComments(answer)"
            ></p-button>
          </div>

          <!-- Add Nested comment input box area -->
          <div>
            <div
              *ngIf="answer.isAddReplyTextboxVisible"
              class="d-flex align-items-center flex-wrap gap-3 pt-3 mb-3"
            >
              <div class="d-flex align-items-center gap-3 flex-grow-1">
                <!-- avatar -->
                <figure *ngIf="currentItems?.firstName" class="avatar m-0">
                  <span>{{
                    currentItems.firstName[0].toUpperCase() ?? ""
                  }}</span>
                </figure>
                <!-- End: avatar -->
                <div class="flex-grow-1 add-answer-box">
                  <p-editor
                    placeholder="Reply"
                    [(ngModel)]="replies[k]"
                    [appendTo]="'body'"
                    [style]="{ width: '100%', height: '100px' }"
                  >
                    <ng-template pTemplate="header">
                      <span class="ql-formats">
                        <button
                          type="button"
                          class="ql-bold"
                          aria-label="Bold"
                        ></button>
                        <button
                          type="button"
                          class="ql-italic"
                          aria-label="Italic"
                        ></button>
                        <button
                          type="button"
                          class="ql-underline"
                          aria-label="Underline"
                        ></button>
                        <button
                          type="button"
                          class="ql-image"
                          aria-label="image"
                        ></button>
                        <button
                          type="button"
                          class="ql-code-block"
                          aria-label="code-block"
                        ></button>
                      </span>
                    </ng-template>
                  </p-editor>
                  <span
                    class="send-img-wrapper cursor-pointer"
                    (click)="addReply(answer, k, question)"
                  >
                    <img src="../../../../assets/icons/send.svg" alt="Send" />
                  </span>
                </div>
              </div>
              <!-- <div>
                <p-button
                  label="Reply"
                  size="small"
                  (click)="addReply(answer, k, question)"
                ></p-button>
              </div> -->
            </div>
            <ng-container *ngIf="answer.isRepliesOpen">
              <div
                class="d-flex gap-3 mt-3"
                *ngFor="let comment of answer.Comments; let l = index"
              >
                <figure class="avatar m-0">
                  <span>{{
                    comment.User?.firstName?.charAt(0).toUpperCase()
                  }}</span>
                </figure>
                <div>
                  <h6 class="m-0 fs-6 fw-medium">
                    <small
                      >{{ comment.User?.firstName }} ·
                      {{ comment.createdAt | date }}</small
                    >
                  </h6>
                  <p class="text-muted m-0">
                    <small
                      [innerHTML]="comment?.content | renderContent"
                    ></small>
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!answer.Comments.length">
                <p class="text-center text-danger">No Replies</p>
              </ng-container>
            </ng-container>
          </div>
          <!-- End: Add Nested comment input box area -->
        </div>
        <!-- End: Comment section -->
      </div>
      <div
        *ngIf="question.AnswerCount > 3"
        class="forum-add-answer-area text-center my-1"
      >
        <p-button
          (click)="loadQuestionDetails(question)"
          size="small"
          label="Load More Answers"
          [outlined]="true"
        ></p-button>
      </div>
      <ng-container *ngIf="!question.Answers?.length">
        <p class="text-center text-danger">No Answers</p>
      </ng-container>
    </ng-container>
    <!-- End: Comments list -->
  </p-card>

  <ng-container *ngIf="!questionsList.length">
    <p-card class="text-danger"> No Questions Found </p-card>
  </ng-container>
</div>

<!-- left list section  -->
<!-- <p-card *ngIf="!isAdmin">
    <div class="d-flex flex-column gap-3">
      <h6 class="m-0 fs-6 fw-medium">Your Questions</h6>
    </div>
  </p-card> -->
<!-- End: left list section  -->
<!-- </div> -->
<p-dialog
  header="Share"
  [(visible)]="isSharableDialogOpened"
  [style]="{ width: '50vw', padding: '0' }"
>
  <!-- <p> -->
  <div class="p-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-share-alt"></i>
      </span>
      <input pInputText [(ngModel)]="sharableLink.link" [disabled]="true" />
    </div>
    <div class="py-3 d-flex justify-content-end">
      <div>
        <button
          pButton
          pRipple
          label="Copy"
          icon="pi pi-copy"
          (click)="copyToClipboard()"
        ></button>
      </div>
      <div class="ps-2">
        <button
          class="p-button-success"
          pButton
          pRipple
          label="Open"
          icon="pi pi-external-link"
          (click)="openInNewTab()"
        ></button>
      </div>
    </div>
  </div>
  <!-- </p> -->
</p-dialog>
