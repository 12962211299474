<div>
  <ng-container *ngIf="!isDirectUpload">
    <button
      class="btn btn-sm btn-primary"
      *ngIf="!isOutlined; else outlinedButton"
      (click)="fileInput.click()"
    >
      <div class="d-flex align-items-center gap-2">
        <img src="../../../assets/svgs/upload.svg" [alt]="buttonName" />
        {{ buttonName }}
      </div>
    </button>

    <ng-template #outlinedButton>
      <a
        (click)="fileInput.click()"
        class="d-flex align-items-center gap-2 text-decoration-underline text-info link-underline-info cursor-pointer"
      >
        <img src="../../../assets/svgs/upload-info.svg" [alt]="buttonName" />
        <span>{{ buttonName }}</span></a
      >
    </ng-template>
  </ng-container>
  <input
    #fileInput
    type="file"
    [attr.multiple]="isMultiple ? 'multiple' : null"
    [attr.accept]="acceptedTypes"
    (change)="onFilesSelected($event)"
    hidden
  />
</div>
<div class="d-flex flex-wrap gap-3 mt-3" *ngIf="files?.length > 0">
  <div class="doc-card" *ngFor="let file of files; let i = index">
    <div class="d-flex align-items-center flex-grow-1 gap-2 overflow-hidden">
      <ng-container
        *ngIf="!previewImage"
        [ngSwitch]="file?.fileType || file?.type"
      >
        <!-- <i
           
            class="pi pi-file-pdf file-icon"
          ></i> -->
        <span *ngSwitchCase="'application/pdf'">
          <img src="../../../assets/svgs/pdf.svg" alt="" srcset="" />
        </span>

        <i *ngSwitchCase="'image/jpeg'" class="pi pi-image file-icon"></i>
        <i *ngSwitchCase="'image/png'" class="pi pi-image file-icon"></i>
      </ng-container>
      <ng-container *ngIf="previewImage">
        <img
          *ngIf="
            file?.fileType === 'image/jpeg' || file?.fileType === 'image/png';
            else offlineImage
          "
          width="100"
          height="100"
          [ngSrc]="imageBaseURL + file?.slug ?? file"
          loading="lazy"
          class="file-image"
        />
      </ng-container>
      <ng-template #offlineImage>
        <img width="100" height="100" class="file-image" [src]="file" />
      </ng-template>
      <span class="file-name-text text-truncate">
        {{ file?.name ?? file?.slug ?? file.url }}
      </span>
    </div>
    <i class="pi pi-times cursor-pointer" (click)="removeFile(i)"></i>
  </div>
</div>
