<div class="py-0 py-lg-3">
  <div>
    <h6 class="student-profile-title">
      Let’s start with your personality type
    </h6>
    <p class="student-profile-subtext">
      Take a quick personality test to uncover strengths and weaknesses. We'll
      guide you in aligning interests with goals and skills, offering advice on
      courses, activities, and more.
    </p>
    <p class="student-profile-subtext">
      Complete the free
      <a href="https://www.16personalities.com/" target="_blank"
        >16 Personality Types</a
      >
      quiz and select your personality type code below.
    </p>
  </div>
  <div class="card shadow-none">
    <div class="card-body">
      <!-- personality type cards -->
      <div *ngFor="let test of testTypes">
        <h6 class="card-title my-0 fs-6 mb-3">{{ test.testTitle }}</h6>
        <div class="personality-cards">
          <div *ngFor="let item of test.subTests">
            <input
              [disabled]="!isStudentPortal"
              type="radio"
              class="personality_type_input"
              [id]="item.id"
              name="personality_type"
              [value]="item.subTestCode"
              [(ngModel)]="selectedGrade"
              (ngModelChange)="saveTest()"
            />
            <label
              [for]="item.id"
              class="personality-card"
              [class]="test.color"
            >
              <img class="personality-icon" [src]="item.imagePath" alt="icon" />
              <div class="tick-icon">
                <img
                  src="../../../../assets/images/student-personality/tick-icon.svg"
                  alt="tick icon"
                />
              </div>
              <div
                [ngClass]="{
                  'personality-card-text': selectedGrade == item.subTestCode
                }"
              >
                <h6>{{ item.subTestTitle }}</h6>
                <p class="my-0">{{ item.subTestCode }}</p>
              </div>
            </label>
          </div>
        </div>
      </div>
      <!-- End: personality type cards -->
    </div>
  </div>
</div>
