import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService {
  baseUrl: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getQuestionListInForum(path: string, queryParams: any): Observable<any> {
    let params = new HttpParams();
    if (queryParams?.page) {
      params = params.set('page', queryParams.page);
    }

    if (queryParams?.pageSize) {
      params = params.set('pageSize', queryParams.pageSize);
    }

    if (queryParams?.q) {
      params = params.set('q', queryParams.q);
    }

    if (queryParams?.tags) {
      params = params.set('tags', queryParams.tags);
    }

    if(queryParams?.country) {
      params = params.set('country', queryParams.country);
    }

    if(queryParams?.countryShortCode) {
      params = params.set('countryShortCode', queryParams.countryShortCode);
    }

    return this.httpClient.get<any>(`${this.baseUrl}${path}`, { params });
  }

  getListInForum(path: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}${path}`);
  }

  updateRecord(path: string, data: any): Observable<any> {
    return this.httpClient.put<any>(`${this.baseUrl}${path}`, data);
  }

  saveRecord(path: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}${path}`, data);
  }

  deleteRecord(path: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.baseUrl}${path}`);
  }

  saveLogs(path: string, data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}${path}`, data);
  }

  updateLogs(path: string, data: any): Observable<any> {
    return this.httpClient.put<any>(`${this.baseUrl}${path}`, data);
  }
}
