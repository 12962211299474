<div class="d-flex flex-column h-100 overflow-hidden">
  <header class="d-flex align-items-center justify-content-between gap-5 p-3">
    <div
      class="d-flex align-items-center gap-5"
      [ngClass]="{ 'justify-content-between w-100': isMobileView }"
    >
      <figure class="d-flex m-0 cursor-pointer" (click)="navigateToHome()">
        <img
          src="../../assets/icons/logo-2.svg"
          class="img-fluid brand-logo"
          alt="Logo"
        />
      </figure>
      <ul *ngIf="isLoggedInUser">
        <li class="explore-page-item">
          <a [routerLink]="['/explore-competitions']" target="_blank">
            Explore Competitons
          </a>
        </li>
      </ul>
      <!-- <label
        class="menu-bar-label d-flex cursor-pointer"
        for="certificateMainMenu"
        *ngIf="isMobileView! && !isLoggedInUser"
        >
        <span class="pi pi-bars"></span>
      </label> -->
    </div>

    <div
      class="d-none d-lg-flex gap-3 align-items-center justify-content-between flex-grow-1"
      *ngIf="!isMobileView && !isLoggedInUser"
    >
      <ul class="d-flex gap-5 p-0 align-items-center">
        <li class="explore-page-item">
          <a [routerLink]="['/explore-competitions']" target="_blank">
            Explore Competitons
          </a>
        </li>
      </ul>
      <div class="d-flex gap-4 align-items-center">
        <p-button
          class="custom-ternary"
          [rounded]="true"
          [routerLink]="['/signup']"
          >Sign up for free</p-button
        >
      </div>
    </div>
  </header>

  <div class="flex-grow-1 overflow-auto">
    <div class="certificate-head-content">
      <div
        class="container d-flex flex-column flex-md-row justify-content-md-between align-items-md-center gap-3"
      >
        <!-- Title -->
        <div>
          <h5 class="m-0 mb-1 fs-5">
            AlgoEd Competitions officially certifies that
          </h5>
          <p class="m-0">
            This award was issued to
            <span class="text-highlight text-capitalize">{{
              competitionAwardData?.team_member?.name ?? ""
            }}</span>
            on
            <span class="text-highlight">{{
              competitionAwardData?.award?.createdAt | date
            }}</span>
          </p>
        </div>
        <!-- End: Title -->

        <!-- Verified Button -->
        <div>
          <button
            class="btn btn-verifed rounded-pill d-flex gap-2 align-items-center"
            (click)="openVerifiedModal()"
          >
            <span>Verified</span>
            <img
              class="img-fluid"
              src="../../assets/images/certifications/verified-tick.png"
              alt="Verified"
            />
          </button>
        </div>
        <!-- End: Verified Button -->
      </div>
    </div>
    <div class="container py-4">
      <div
        class="d-flex flex-column flex-md-row align-items-md-center gap-4 mb-4"
      >
        <figure class="champion-figure m-0">
          <img
            class="img-fluid"
            [src]="competitionAwardIconUrl ?? ''"
            [alt]="competitionAwardData?.competition_award?.award_title"
          />
        </figure>

        <!-- Title & figure -->
        <div>
          <h5 class="m-0 mb-1 fs-5">
            {{
              competitionAwardData?.award_details?.award_category == "global"
                ? "Global"
                : ""
            }}
            {{ competitionAwardData?.competition_award?.award_title }}
            {{
              competitionAwardData?.country?.country ||
              competitionAwardData?.region?.name
                ? "-"
                : ""
            }}
            {{
              competitionAwardData?.award_details?.award_category == "country"
                ? competitionAwardData?.country?.country
                : competitionAwardData?.award_details?.award_category ==
                  "regional"
                ? competitionAwardData?.region?.name
                : ""
            }}
          </h5>
          <p class="m-0">
            Issued by
            <span class="text-primary"
              >{{ competitionAwardData?.competition?.name }}
            </span>
          </p>
        </div>
        <!-- End: Title & figure -->
      </div>
      <div class="d-flex flex-column flex-lg-row gap-4">
        <div class="flex-grow-1 d-flex flex-column gap-4">
          <!-- canvas -->
          <div class="canvas-wrapper">
            <app-certificate-canvas
              *ngIf="competitionAwardData"
              [competitionAwardData]="competitionAwardData"
            ></app-certificate-canvas>
          </div>
          <!-- End: canvas -->

          <!-- Canvas footer for Desktop -->
          <app-certificate-footer
            class="d-none d-lg-block"
          ></app-certificate-footer>
          <!-- End: Canvas footer Desktop-->
        </div>
        <!-- share section  -->
        <div class="newest-updates-card-wrapper">
          <app-certificate-share-card
            [isLoggedInUser]="isValidToken"
            (linkedProfile)="addToLinkedInProfile()"
          ></app-certificate-share-card>

          <!-- Download Award Certificate -->
          <app-download-award-card
            *ngIf="isValidToken"
            (downloadAward)="downloadImage()"
            (downloadToPdfAward)="downloadToPdfAward()"
          ></app-download-award-card>
          <!-- End: Download Award Certificate -->
        </div>
        <!-- share section  -->

        <!-- Canvas footer for Mobile -->
        <app-certificate-footer class="d-lg-none"></app-certificate-footer>
        <!-- End: Canvas footer Mobile-->
      </div>
    </div>
  </div>
</div>

<!-- Fonts For Loading into the Canvas -->
<div>
  <p style="font-family: 'GoodVibrations'; font-size: 1px">
    GoodVibrations Font
  </p>
  <p style="font-family: 'Playfair Display'; font-size: 1px">
    Playfair Display Font
  </p>
  <p style="font-family: 'Allrounder Monument Test'; font-size: 1px">
    Allrounder Monument Test Font
  </p>
  <p style="font-family: 'Helvetica'; font-size: 1px">Helvetica Font</p>
</div>

<!-- Verified Modal -->
<p-dialog
  [(visible)]="isVerifiedModalVisible"
  [modal]="true"
  styleClass="verified-modal"
  [style]="{ width: window.innerWidth > 576 ? '600px' : '90vw' }"
  header="Verification data"
  [closable]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
>
  <div class="verified-content">
    <figure>
      <img
        src="../../assets/images/certifications/verified-badge-img.png"
        alt="Verified Badge"
        class="img-fluid"
      />
    </figure>
    <ul class="verified-info">
      <li *ngFor="let info of verificationInfo">
        <img
          src="../../assets/images/certifications/verified-check.png"
          alt="Verified Check"
          class="verified-check"
        />
        <span [innerHTML]="info"></span>
      </li>
    </ul>
  </div>
</p-dialog>
<!-- End of Verified Modal -->
