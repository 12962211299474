import {
  Component,
  ElementRef,
  HostBinding,
  ViewChild
} from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-submission',
  templateUrl: './manage-submission.component.html',
  styleUrls: ['./manage-submission.component.scss'],
})
export class ManageSubmissionComponent {
  @ViewChild('paperformContainer', { static: true })
  paperformContainer: ElementRef;
  isLoggedInUser: boolean;
  @HostBinding('class')
  get cssClass(): Record<string, boolean> {
    return {
      ['h-100']: !this.isLoggedInUser,
      ['overflow-auto']: !this.isLoggedInUser,
      ['d-block']: !this.isLoggedInUser,
    };
  }

  constructor(private metaService: Meta) {}

  ngOnInit(): void {
    this.metaService.updateTag({
      property: 'og:title',
      content: 'AlgoEd | Essay Review',
    });
    this.isLoggedInUser =
      (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user'))) ||
      false;
  }
  ngAfterViewInit() {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }
}
