<div class="card share-card certificate-card">
  <div class="card-body">
    <div
      class="card-title d-flex align-items-center justify-content-between mb-3"
    >
      <h6 class="m-0">Share This Page</h6>
      <figure class="certification-img m-0">
        <img
          class="img-fluid"
          src="../../../../assets/competitions/certification-trophy.png"
          alt="Certification Trophy Image"
        />
      </figure>
    </div>

    <div
      class="d-grid share-items gap-3"
      [ngClass]="{ 'mb-4': isLoggedInUser }"
      *ngIf="!isMobileView"
    >
      <span
        *ngFor="let icon of shareIcons"
        (click)="handleButtonClick(icon.id)"
        class="share-icon sm text-center cursor-pointer"
      >
        <img [src]="icon.src" [alt]="icon.alt" class="img-fluid" />
      </span>
    </div>

    <!-- Add to My Linkedin Profile -->
    <div>
      <button
        class="btn btn-white-download justify-content-center shadow-none w-100 mb-3" 
        [ngClass]="{ 'mt-3': !isLoggedInUser }"
        (click)="copyToClipboard()"
      >
        <i class="pi pi-copy cursor-pointer" style="font-size: 1rem"></i>
        <span class="cursor-pointer"> Copy to Clipboard </span>
      </button>
    </div>
    <div
      class="d-flex flex-column flex-sm-row justify-content-between align-items-center gap-3"
    >
      <button
        *ngIf="isLoggedInUser"
        (click)="addToLinkedProfile()"
        class="btn btn-white-download justify-content-center shadow-none w-100"
      >
        <img
          src="../../../../assets/share-icons/linkedin.png"
          class="img-fluid"
          width="24px"
          alt="Linkedin"
        />
        Add to My Linkedin Profile
      </button>

      <button
        class="btn btn-outline-white d-flex align-items-center gap-2 justify-content-center rounded-pill"
        type="button"
        (click)="openSharePage($event)"
        *ngIf="isMobileView"
      >
        <span>Share</span>
        <span
          ><svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.08333 4.12565V0.958984L14.625 6.50065L9.08333 12.0423V8.79648C5.125 8.79648 2.35417 10.0632 0.375 12.834C1.16667 8.87565 3.54167 4.91732 9.08333 4.12565Z"
              fill="#fff"
            />
          </svg>
        </span>
      </button>
    </div>
    <!-- End: Add to My Linkedin Profile -->
  </div>
</div>

<div class="h-100 w-100" *ngIf="isMobileView && isSharePageOpen">
  <div class="share-overlay" (click)="closeSharePage(false)"></div>
  <div class="card share-card">
    <div class="card-body overflow-hidden d-flex flex-column">
      <div class="card-title fw-bold p-3 text-center position-relative">
        <span>Share This Page</span>

        <span class="close-btn" (click)="closeSharePage(false)">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00078 8.40078L2.10078 13.3008C1.91745 13.4841 1.68411 13.5758 1.40078 13.5758C1.11745 13.5758 0.884115 13.4841 0.700781 13.3008C0.517448 13.1174 0.425781 12.8841 0.425781 12.6008C0.425781 12.3174 0.517448 12.0841 0.700781 11.9008L5.60078 7.00078L0.700781 2.10078C0.517448 1.91745 0.425781 1.68411 0.425781 1.40078C0.425781 1.11745 0.517448 0.884115 0.700781 0.700781C0.884115 0.517448 1.11745 0.425781 1.40078 0.425781C1.68411 0.425781 1.91745 0.517448 2.10078 0.700781L7.00078 5.60078L11.9008 0.700781C12.0841 0.517448 12.3174 0.425781 12.6008 0.425781C12.8841 0.425781 13.1174 0.517448 13.3008 0.700781C13.4841 0.884115 13.5758 1.11745 13.5758 1.40078C13.5758 1.68411 13.4841 1.91745 13.3008 2.10078L8.40078 7.00078L13.3008 11.9008C13.4841 12.0841 13.5758 12.3174 13.5758 12.6008C13.5758 12.8841 13.4841 13.1174 13.3008 13.3008C13.1174 13.4841 12.8841 13.5758 12.6008 13.5758C12.3174 13.5758 12.0841 13.4841 11.9008 13.3008L7.00078 8.40078Z"
              fill="#595959"
            />
          </svg>
        </span>
      </div>

      <!-- Items -->
      <div class="overflow-auto flex-grow-1">
        <div
          class="share-items d-flex align-items-center gap-3 justify-content-between pt-3 cursor-pointer"
          *ngFor="let icon of shareIcons"
          (click)="handleButtonClick(icon.id)"
        >
          <div class="d-flex align-items-center gap-3">
            <span class="share-icon sm text-center cursor-pointer">
              <img [src]="icon.src" [alt]="icon.alt" class="img-fluid" />
            </span>
            <span>
              {{ icon.content }}
            </span>
          </div>
          <div>
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.06023 8.00065L0.935232 1.87565C0.726899 1.66732 0.626204 1.42079 0.633149 1.13607C0.640093 0.851345 0.747732 0.604818 0.956065 0.396484C1.1644 0.188151 1.41093 0.0839844 1.69565 0.0839844C1.98037 0.0839844 2.2269 0.188151 2.43523 0.396484L8.83107 6.81315C8.99773 6.97982 9.12273 7.16732 9.20607 7.37565C9.2894 7.58398 9.33107 7.79232 9.33107 8.00065C9.33107 8.20898 9.2894 8.41732 9.20607 8.62565C9.12273 8.83398 8.99773 9.02148 8.83107 9.18815L2.4144 15.6048C2.20607 15.8132 1.96301 15.9138 1.68523 15.9069C1.40745 15.9 1.1644 15.7923 0.956065 15.584C0.747732 15.3757 0.643565 15.1291 0.643565 14.8444C0.643565 14.5597 0.747732 14.3132 0.956065 14.1048L7.06023 8.00065Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- End: Items -->
    </div>
  </div>
</div>
