import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent {
  isLoggedIN: boolean;
  roleId: number;

  constructor(private router: Router) {}

  ngOnInit() {
    const local = localStorage.getItem('user') || '';
    // this.roleId = local && JSON.parse(local).roleId;
    try {
      this.roleId = local && JSON.parse(local).roleId;
    } catch (e) {
      console.error('Error parsing JSON from local:', e);
      this.roleId = undefined;
    }
    if (local && JSON.parse(local)) {
      this.isLoggedIN = true;
    } else {
      this.isLoggedIN = false;
    }
  }

  navigate() {
    const routes = {
      1: {
        loggedIn: '/admin/dashboard',
        loggedOut: '/',
      },
      2: {
        loggedIn: '/student/admission/US/dashboard',
        loggedOut: '/admin-login',
      },
      10: {
        loggedIn: '/organisation/app-shell/dashboard',
        loggedOut: '/organisation-admin-login',
      },
    };

    const defaultRoute = '';
    const roleRoutes = routes[this.roleId] || {};
    const navigatePath = this.isLoggedIN
      ? roleRoutes.loggedIn
      : roleRoutes.loggedOut;

    this.router.navigate([navigatePath || defaultRoute]);
  }
}
