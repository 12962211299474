import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TeamDetail } from '../../models/competions.models';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-bulk-invite-for-competition',
  templateUrl: './bulk-invite-for-competition.component.html',
  styleUrls: ['./bulk-invite-for-competition.component.scss'],
})
export class BulkInviteForCompetitionComponent {
  memberList: TeamDetail[] = [];
  tempMemberList: TeamDetail[] = [];
  isAllPaid: boolean;
  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private commonService: CommonServiceService
  ) {}

  ngOnInit() {
    this.memberList = this.config.data?.memberList;
    this.memberList =
      this.memberList.length &&
      this.memberList.map((x) => {
        x.isChecked = false;
        return x;
      });
    this.tempMemberList = [...this.memberList];
    this.isAllPaid = this.memberList.every((x) => x.payment_status === 'Paid');
  }

  payForTeam() {
    const member_count = this.memberList.filter((x) => x.isChecked);
    if (member_count.length === 0) {
      this.commonService.showToast('Please select team members', 'error', 1000);
      return;
    }
    this.ref.close(member_count);
  }

  onSearch(searchText: string) {
    if (!searchText) {
      this.memberList = [...this.tempMemberList];
      return of(this.memberList);
    } else {
      const genericString = searchText.toLowerCase();
      return of(
        this.tempMemberList.filter((x) => {
          return (
            x?.name.toLowerCase().includes(genericString) ||
            x?.email.toLowerCase().includes(genericString)
          );
        })
      );
    }
  }

  handleSearchResults(searchResults: any) {
    if (searchResults && searchResults.length) {
      this.memberList = searchResults;
    } else {
      this.memberList = [];
    }
    console.log(this.memberList);
    // this.tempMemberList = searchResults;
  }
}
