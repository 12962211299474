import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { QueryParamsFields } from '../../or-shared/or.interface';
import { OrAPIService } from '../../services/or-api.service';

@Component({
  selector: 'app-or-create-and-group-list',
  templateUrl: './or-create-and-group-list.component.html',
  styleUrls: ['./or-create-and-group-list.component.scss'],
})
export class OrCreateAndGroupListComponent {
  @ViewChild('searchComponent', { static: false }) searchComponent: any;

  @Input() getAllList: string;
  @Input() addGroup: string;

  // Output decorator
  @Output() selectedGroup: EventEmitter<any> = new EventEmitter<any>();

  // Array types
  groupsList: any[] = [];
  tempList: any[] = [];
  // Object type
  queryParams: QueryParamsFields = {
    page: 1,
    pageSize: 15,
    countryShortCode: 'US',
  };

  // Number type
  totalRecords: number;
  searchValue: string;

  // FormGroup type
  groupForm: FormGroup;

  // Boolean type
  isInfiniteScroll: boolean = true;
  groupDialogVisible: boolean = false;

  highlightedGroup: any;

  // Add a blank line to separate different types

  constructor(
    private apiService: OrAPIService,
    private commonService: CommonServiceService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.groupForm = this.fb.group({
      name: [null, Validators.required],
      countryShortCode: ['US', Validators.required],
    });
    this.getGroupList();
    this.groupForm.controls['countryShortCode'].valueChanges.subscribe(
      (res) => {
        this.filterGroupList(res);
      },
      (error) => {}
    );
  }

  openDialogForGroup() {
    if (window.innerWidth <= 984) {
      this.groupDialogVisible = true;
    }
  }

  filterGroupList(countryShortCode: string) {
    this.searchComponent.searchText = '';
    this.groupsList = this.tempList.filter(
      (x) => x.countryShortCode == countryShortCode
    );
    this.highlightedGroup =
      this.groupsList.length > 0 && this.groupsList[0] && this.groupsList[0].id;
    this.groupsList.length > 0 &&
      this.groupsList[0] &&
      this.commonService.$selectedGroup.next(this.groupsList[0]);
  }

  getGroupListBySearch(searchText: string) {
    if (searchText) {
      return of(
        this.tempList.filter((x) =>
          x.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      return of(this.tempList);
    }
  }

  handleSearchResults(searchResults: any) {
    this.groupsList = [...searchResults];
  }

  getGroupList() {
    this.apiService.getAllList(this.getAllList).subscribe(
      (res: any) => {
        if (res.data.groups.length > 0) {
          this.groupsList = [...res.data.groups];
          this.tempList = [...res.data.groups];
        }
        this.filterGroupList(this.groupForm.controls['countryShortCode'].value);
        if (this.groupsList.length > 0 && this.queryParams.page == 1) {
          this.commonService.$selectedGroup.next(this.groupsList[0]);
          this.highlightedGroup = this.groupsList[0].id;
        }
      },
      (error: any) => {
        this.commonService.showToast(error.error.error, 'error', 1000);
      }
    );
  }

  createGroup() {
    if (this.groupForm.controls['name'].invalid) {
      this.commonService.showToast('Please enter group name', 'error', 1000);
    }
    if (this.groupForm.controls['countryShortCode'].invalid) {
      this.commonService.showToast('Please select country', 'error', 1000);
    }
    if (this.groupForm.valid) {
      const finalData = {
        isCategorList: true,
        name: this.groupForm.value,
      };
      this.apiService.addGroup(this.addGroup, finalData).subscribe(
        (res) => {
          this.commonService.showToast(
            'Group created successfully',
            'success',
            1000
          );
          this.queryParams = {
            page: 1,
            pageSize: 15,
          };
          this.groupForm.controls['name'].reset();
          this.groupsList = [];
          this.getGroupList();
        },
        (error) => {
          this.commonService.showToast(error.error.error, 'error', 3000);
        }
      );
    }
  }

  viewGroupDetails(group: any) {
    this.highlightedGroup = group.id;
    this.commonService.$selectedGroup.next(group);
  }
}
