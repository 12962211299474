import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTaskForCalender',
})
export class FilterTaskForCalenderPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) return null;

    const filter = args[0].find((x) => x.id === value);
    return filter[args[1]];
  }
}
