import { Component, HostListener } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { mainConstants } from 'src/app/shared/constants/main.constants';

@Component({
  selector: 'app-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss'],
})
export class PaymentTermsComponent {
  isMobile: any;
  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  window: Window;
  redirectLinks = mainConstants.redirectLinks;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.onResize();
  }

  ngOnInit() {}

  acceptAndContinue() {
    this.ref.close(true);
  }
}
