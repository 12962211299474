<div class="d-flex h-100 overflow-hidden flex-column">
  <div class="py-3 position-relative z-1">
    <div class="container d-flex flex-wrap align-items-center gap-2 gap-md-4">
      <!-- Dropdown Business Case Competition -->
      <div class="flex-grow-1">
        <div class="p-inputgroup search-inputgroup">
          <input
            type="text"
            [(ngModel)]="searchText"
            (ngModelChange)="filteredCompetitions()"
            pInputText
            placeholder="Search competitions"
          />
          <span class="search-icon-with-dropdown">
            <i class="pi pi-search"></i>
          </span>
        </div>
      </div>
      <div class="flex-shrink-0" *ngIf="isLoggedInUser">
        <button
          type="button"
          class="btn btn-gray-secondary d-flex gap-2 align-items-center gap-2 h-100 w-100"
          (click)="openJoinCompetition(true)"
        >
          <i style="color: #0cbd80" class="icon-trophy"></i>
          <span>My Competitions</span>
        </button>
      </div>
    </div>
  </div>

  <div class="flex-grow-1 overflow-auto" [ngClass]="{ 'pb-4': isLoggedInUser }">
    <div class="container">
      <div
        class="flex-grow-1 d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center gap-3 flex-wrap mt-2 mb-4"
      >
        <h6 class="m-0 competition-heading">Competitions</h6>

        <!-- share page button for mobile view  -->
        <div class="card get-updates-card">
          <div
            class="card-body d-flex align-items-lg-center gap-3"
            [ngClass]="{ 'flex-column': !isLoggedInUser && isMobileView }"
          >
            <form [formGroup]="subscriptionForm" *ngIf="!isLoggedInUser">
              <input
                formControlName="email"
                type="email"
                class="p-inputtext-md"
                placeholder="Enter your email"
                pInputText
              />
            </form>
            <div class="flex-grow-1 flex-shrink-0">
              <button
                class="btn btn-primary btn-subscribe rounded fw-bold"
                [disabled]="isSubscription && isLoggedInUser"
                (click)="subscribeToCompetition()"
              >
                <small
                  class="icon icon-check me-2"
                  *ngIf="isSubscription && isLoggedInUser"
                ></small>
                {{
                  isSubscription && isLoggedInUser
                    ? "Subscribed"
                    : !isSubscription && isLoggedInUser
                    ? "Subscribe"
                    : "Get Updates"
                }}
              </button>
            </div>
            <div class="flex-grow-1 flex-shrink-0">
              <button
                class="btn btn-page-share"
                type="button"
                (click)="openSharePage($event)"
              >
                <span>Share This Page</span>
                <span class="icon icon-share-icon"> </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End: share page button for mobile view  -->

      <!-- My competitions card list & non logged in subscribe card  -->
      <div
        class="d-flex flex-column-reverse flex-lg-row co-detail-card-wrapper"
      >
        <app-co-detail-card
          *ngIf="clubbedCompetitionsList?.length > 0"
          class="flex-grow-1"
          [isRegisterationOpen]="isRegisterationOpen"
          [competitions]="clubbedCompetitionsList"
          [myCompetition]="myCompetition"
          [ngClass]="{ 'public-competition-card-wrapper': !isLoggedInUser }"
        ></app-co-detail-card>
        <ng-container *ngIf="!clubbedCompetitionsList?.length">
          <!-- No Competition  -->
          <div
            class="dashboard-competitions-card card d-flex flex-column justify-content-center align-items-center flex-grow-1 gap-3 p-4"
          >
            <figure class="m-0">
              <img
                src="../../assets/competitions/trophy2x.png"
                alt="Competition Trophy Image"
              />
            </figure>
            <h5
              class="join-competition-text text-center text-decoration-none mb-0"
            >
              No competition found!
            </h5>
          </div>
          <!-- End: No Competition  -->
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-my-competitions
  [myCompetition]="myCompetition"
  (isCLosedClicked)="closeJoinCompetition($event)"
  *ngIf="isJoinCompetitionCanvasOpen"
></app-my-competitions>

<!-- Share Page Items for Mobile View -->
<app-share-page-card
  *ngIf="isSharePageOpen"
  [isSharePageOpen]="isSharePageOpen"
  (closeShareModal)="closeSharePage($event)"
></app-share-page-card>
<!-- End: Share Page Items for Mobile View -->
