import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() isMultiple: boolean = false;
  @Input() fileTypes: string = 'image/*,application/pdf';
  @Input() file;
  @Input() disabled: boolean = false;
  fileArr;

  @Output() handleImg: EventEmitter<any> = new EventEmitter<any>();

  uploadInputModel;
  constructor() {}

  ngOnInit(): void {}

  upload(event) {
    if (this.isMultiple) {
      this.fileArr.push(...event.target.files);
      this.handleImg.emit({ action: 'upload', file: this.fileArr });
    } else {
      this.file = event.target.files[0];
      this.handleImg.emit({ action: 'upload', file: this.file });
    }

    this.uploadInputModel = null;
  }

  clearFileArr(i) {
    this.fileArr.splice(i, 1);
    this.handleImg.emit({ action: 'delete', file: this.fileArr });
  }

  clearFile() {
    this.file = null;
    this.handleImg.emit({ action: 'delete', file: null });
  }

  getImgPreview(fileObj) {
    let reader = new FileReader();

    reader.readAsDataURL(fileObj); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      return event.target.result; // current blog img Preview
    };
  }
}
