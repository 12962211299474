import { Component } from '@angular/core';
import { mainConstants } from '../../constants/main.constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonServiceService } from '../../common-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sharable-link',
  templateUrl: './sharable-link.component.html',
  styleUrls: ['./sharable-link.component.scss'],
})
export class SharableLinkComponent {
  sharableButtons: any[] = mainConstants.sharableButtons;
  shareVideoLink: string;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public commonService: CommonServiceService
  ) {}

  ngOnInit(): void {
    if (this.config.data['video']) {
      
      this.shareVideoLink = `${environment.openShareLink}${this.config.data.video.slug}`;
    }

    if(this.config.data['isDirectURL']){
      this.shareVideoLink = this.config.data.video;
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.shareVideoLink);
    this.commonService.showToast('Link Copied', 'success', 1000);
  }

  handleButtonClick(buttonId: number): void {
    switch (buttonId) {
      case 1:
        // Copy Link
        this.copyToClipboard();
        break;
      case 2:
        // Whatsapp
        const whatsappLink =
          'https://api.whatsapp.com/send?text=' +
          encodeURIComponent(this.shareVideoLink);
        window.open(whatsappLink, '_blank');
        break;
      case 3:
        // Facebook
        const facebookLink =
          'https://www.facebook.com/sharer/sharer.php?u=' +
          encodeURIComponent(this.shareVideoLink);
        window.open(facebookLink, '_blank');
        break;
      case 4:
        // Twitter
        const twitterLink =
          'https://twitter.com/intent/tweet?url=' +
          encodeURIComponent(this.shareVideoLink);
        window.open(twitterLink, '_blank');
        break;
      case 5:
        // Email
        window.location.href =
          'mailto:?body=' + encodeURIComponent(this.shareVideoLink);
        break;
      case 6:
        // LinkedIn
        const linkedinLink =
          'https://www.linkedin.com/sharing/share-offsite/?url=' +
          encodeURIComponent(this.shareVideoLink);
        window.open(linkedinLink, '_blank');
        break;
      // Add other cases for additional buttons
    }
  }
}
