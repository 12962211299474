import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/shared/users.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Router, ActivatedRoute  } from '@angular/router';
import {Subscription } from 'rxjs'

@Component({
  selector: 'app-standardized-test',
  templateUrl: './standardized-test.component.html',
  styleUrls: ['./standardized-test.component.scss']
})
export class StandardizedTestComponent implements OnInit, OnDestroy {
  
  satScores = []
  actScores = [];
  oxbridgeScores = [{
      oxbridge_standardized_test:'',
      oxbridge_standardized_test_scores:'',
      isEditEntry:false
  }];
  nonOxbridgeScores = [];
  subs: Subscription;
  studentData:any;
  profileStandardTestData:any;
  
  newActEntry:boolean = false
  newSatEntry:boolean = false
  newOxbEntry:boolean = false
  newNonOxbEntry:boolean = false

    actObj:any = {}
    satObj:any  = {}
    nonOxbridgeObj:any = {}
    
    nonOxbOptions=[{value:"BioMedical Admissions Test (BMAT)"},
{value:"National Admissions Test for Law (LNAT)"},
{value:"Sixth Term Examination Paper (STEP)"}]
  
  isMobile:boolean = false  
  constructor(private usersService: UsersService,private commonService:CommonServiceService) { }

  ngOnInit(): void {
      if(window.innerWidth < 600){
          this.isMobile = true
      }
    this.subs = this.commonService.$curUser.subscribe(item=>{
        if(item){
            this.studentData = item
            this.getProfileActScore()
            this.getProfileSatScore()
            this.getProfileOxbridgeScores()
            this.getProfileNonOxbridgeScores()

        }
      })
  }

  ngOnDestroy(){
      this.subs.unsubscribe()
  }


  private getProfileActScore(){
    this.actScores = []
      this.usersService.getProfileActScore({where:{userId:this.studentData.userId || this.studentData.id}}).subscribe(item=>{
          
          this.actScores = item.data
          this.actScores =this.actScores.map(x=>{
              x.isEditEntry = false
           return x   
              
          })
          

      },err=>{
          console.log(err)
      })
  }
  
  private getProfileSatScore(){
      this.satScores = []
      this.usersService.getProfileSatScore({where:{userId:this.studentData.userId || this.studentData.id}}).subscribe(item=>{
          
          this.satScores = item.data
          this.satScores = this.satScores.map(x=>{
              x.isEditEntry = false
           return x   
              
          })
          

      },err=>{
          console.log(err)
      })
  }

  private getProfileOxbridgeScores(){
      this.usersService.getProfileOxbridgeScores({where:{testType:1,userId:this.studentData.userId || this.studentData.id}}).subscribe(item=>{
          
          this.oxbridgeScores = item.data
          this.oxbridgeScores = this.oxbridgeScores.map(x=>{
              x.isEditEntry = false
           return x   
              
          })
          
      },err=>{
          console.log(err)
      })
      
  }
    private getProfileNonOxbridgeScores(){
      this.nonOxbridgeScores = []
      this.usersService.getProfileOxbridgeScores({where:{testType:0,userId:this.studentData.userId || this.studentData.id}}).subscribe(item=>{
          
          this.nonOxbridgeScores = item.data
            this.nonOxbridgeScores = this.nonOxbridgeScores.map(x=>{
          x.isEditEntry = false
           return x   
          })

      },err=>{
          console.log(err)
      })
      
  }
  
  showAddEntry(scoreType){
      if(scoreType == 'act'){
        this.newActEntry = !this.newActEntry
      }
      if(scoreType == 'sat'){
        this.newSatEntry = !this.newSatEntry

      }
      if(scoreType == 'nonOxb'){
        this.newNonOxbEntry = !this.newNonOxbEntry

      }
      
  }
  
  editEntry(scoreType,i,action){
      if(scoreType == 'act'){
          if(action == 'edit'){
              this.actScores[i].isEditEntry = true
          }else{
              this.actScores[i].isEditEntry = false
              
          }

      }
      if(scoreType == 'sat'){
          if(action == 'edit'){
              this.satScores[i].isEditEntry = true
          }else{
              this.satScores[i].isEditEntry = false
              
          }

      }
      if(scoreType == 'oxb'){
          if(action == 'edit'){
              this.oxbridgeScores[0].isEditEntry = true
          }else{
              this.oxbridgeScores[0].isEditEntry = false
              
          }
      }
      if(scoreType == 'nonOxb'){
          if(action == 'edit'){
              this.nonOxbridgeScores[i].isEditEntry = true
          }else{
              this.nonOxbridgeScores[i].isEditEntry = false
              
          }

      }
  }
  
  saveActScore(i?){
      if(i >= 0){
      let dataObj = {
          ...this.actScores[i]
        }
        delete dataObj['Created_at']
        delete dataObj['Created_by']
        delete dataObj['Updated_at']
        delete dataObj['Updated_by']
        delete dataObj['userId']
        delete dataObj['isEditEntry']

      this.usersService.updateProfileActScore({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Act score updated','success',3000,'Info')
         this.getProfileActScore()
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }else{
         let dataObj = {
          ...this.actObj
        } 
        dataObj.userId = this.studentData.id || this.studentData.userId
        

      this.usersService.addProfileActScore({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Act score added','success',3000,'Info')
         this.actObj= {}
         this.getProfileActScore()
         this.showAddEntry('act')
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }
      

  }
  
  saveSatScore(i?){
      if(i >= 0){
      let dataObj = {
          ...this.satScores[i]
        }
        delete dataObj['Created_at']
        delete dataObj['Created_by']
        delete dataObj['Updated_at']
        delete dataObj['Updated_by']
        delete dataObj['userId']
        delete dataObj['isEditEntry']

    
      this.usersService.updateProfileSatScore({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Sat score updated','success',3000,'Info')
         this.getProfileSatScore()
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }else{
         let dataObj = {
          ...this.satObj
        } 
        dataObj.userId = this.studentData.id || this.studentData.userId
        

      this.usersService.addProfileSatScore({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Sat score added','success',3000,'Info')
            this.satObj = {}

         this.getProfileSatScore()
               this.showAddEntry('sat')

          
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }
  }
  
  saveOxbridgeScore(){
      if(this.oxbridgeScores[0]['id']){
      let dataObj = {
          ...this.oxbridgeScores[0]
        }
        delete dataObj['Created_at']
        delete dataObj['Created_by']
        delete dataObj['Updated_at']
        delete dataObj['Updated_by']
        delete dataObj['userId']
        delete dataObj['isEditEntry']

    
          this.usersService.updateProfileOxbridgeScores({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Oxbridge score updated','success',3000,'Info')
         this.getProfileOxbridgeScores()
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }else{
         let dataObj = {
          ...this.oxbridgeScores[0]
        } 
        delete dataObj['Created_at']
        delete dataObj['Created_by']
        delete dataObj['Updated_at']
        delete dataObj['Updated_by']
        delete dataObj['isEditEntry']
        delete dataObj['id']

        dataObj['userId'] = this.studentData.id || this.studentData.userId
        dataObj['testType'] = 1 

    
 
      this.usersService.addProfileOxbridgeScores({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Oxbridge score added','success',3000,'Info')
         this.getProfileOxbridgeScores()
          
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }
      
  }
  
  saveNonOxbridgeScore(i?){
      if(i >= 0){
      let dataObj = {
          ...this.nonOxbridgeScores[i]
        }
        delete dataObj['Created_at']
        delete dataObj['Created_by']
        delete dataObj['Updated_at']
        delete dataObj['Updated_by']
        delete dataObj['userId']
        delete dataObj['isEditEntry']

    
      this.usersService.updateProfileOxbridgeScores({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Non Oxbridge score updated','success',3000,'Info')
         this.getProfileNonOxbridgeScores()

      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }else{
         let dataObj = {
          ...this.nonOxbridgeObj
        } 
        dataObj.userId = this.studentData.id || this.studentData.userId
        dataObj.testType =0
    

      this.usersService.addProfileOxbridgeScores({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Non Oxbridge score added','success',3000,'Info')
         this.nonOxbridgeObj = {}
         this.getProfileNonOxbridgeScores()
         this.showAddEntry('nonOxb')
          
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })

      }
      
      
  }

    deleteActScore(i){
        let dataObj = {
            id:this.actScores[i].id
        }

      this.usersService.deleteProfileActScore({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Act score deleted','success',3000,'Info')
         this.getProfileActScore()
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })
        
    }
    deleteSatScore(i){
        let dataObj = {
            id:this.satScores[i].id
        }
      this.usersService.deleteProfileSatScore({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Sat score deleted','success',3000,'Info')
         this.getProfileSatScore()
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })
        
    }
    deleteProfileOxbridgeScores(i){
        let dataObj = {
            id:this.nonOxbridgeScores[i].id
        }
      this.usersService.deleteProfileOxbridgeScores({data:dataObj}).subscribe(item=>{
         this.commonService.showToast('Non Oxbridge score deleted','success',3000,'Info')
         this.getProfileNonOxbridgeScores()
      },err=>{
         this.commonService.showToast('Something went wrong','warn',3000,'Info')
          
      })
        
    }

}
