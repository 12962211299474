import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortCompetitions',
})
export class SortCompetitionsPipe implements PipeTransform {
  transform(competitions: any[]): any[] {
    return competitions.sort((a: any, b: any) => {
      const now = new Date();

      // 1. Current competitions in registration state with closest competition start first
      const isRegistrationA =
        a.registration_stage !== 'Closed' &&
        new Date(a.competition_start) >= now;
      const isRegistrationB =
        b.registration_stage !== 'Closed' &&
        new Date(b.competition_start) >= now;

      if (isRegistrationA && !isRegistrationB) return -1;
      if (!isRegistrationA && isRegistrationB) return 1;

      if (isRegistrationA && isRegistrationB) {
        return (
          new Date(a.competition_start).getTime() -
          new Date(b.competition_start).getTime()
        );
      }

      // 2. Upcoming competitions
      if (a.isUpcoming && !b.isUpcoming) return -1;
      if (!a.isUpcoming && b.isUpcoming) return 1;

      // 3. In-progress competitions
      if (a.status === 'In Progress' && b.status !== 'In Progress') return -1;
      if (a.status !== 'In Progress' && b.status === 'In Progress') return 1;

      // 4. Competitions where isJoined = true should be last
      if (a.isJoined && !b.isJoined) return 1;
      if (!a.isJoined && b.isJoined) return -1;

      return 0;
    });
  }
}
