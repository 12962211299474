<div class="h-100 w-100" *ngIf="isSharePageOpen">
  <div class="share-overlay" (click)="closeSharePage(false)"></div>
  <div class="card share-card">
    <div class="card-body overflow-hidden d-flex flex-column">
      <div class="card-title fw-bold p-3 text-center position-relative">
        <span>Share This Page</span>

        <span class="close-btn" (click)="closeSharePage(false)">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00078 8.40078L2.10078 13.3008C1.91745 13.4841 1.68411 13.5758 1.40078 13.5758C1.11745 13.5758 0.884115 13.4841 0.700781 13.3008C0.517448 13.1174 0.425781 12.8841 0.425781 12.6008C0.425781 12.3174 0.517448 12.0841 0.700781 11.9008L5.60078 7.00078L0.700781 2.10078C0.517448 1.91745 0.425781 1.68411 0.425781 1.40078C0.425781 1.11745 0.517448 0.884115 0.700781 0.700781C0.884115 0.517448 1.11745 0.425781 1.40078 0.425781C1.68411 0.425781 1.91745 0.517448 2.10078 0.700781L7.00078 5.60078L11.9008 0.700781C12.0841 0.517448 12.3174 0.425781 12.6008 0.425781C12.8841 0.425781 13.1174 0.517448 13.3008 0.700781C13.4841 0.884115 13.5758 1.11745 13.5758 1.40078C13.5758 1.68411 13.4841 1.91745 13.3008 2.10078L8.40078 7.00078L13.3008 11.9008C13.4841 12.0841 13.5758 12.3174 13.5758 12.6008C13.5758 12.8841 13.4841 13.1174 13.3008 13.3008C13.1174 13.4841 12.8841 13.5758 12.6008 13.5758C12.3174 13.5758 12.0841 13.4841 11.9008 13.3008L7.00078 8.40078Z"
              fill="#595959"
            />
          </svg>
        </span>
      </div>
      <!-- copy link -->
      <div class="copy-component" (click)="copyToClipboard()">
        <div class="clipboard-text">{{ shareLink }}</div>
        <span>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_462_391)">
              <path
                d="M14 0.875H3.5C2.5375 0.875 1.75 1.6625 1.75 2.625V14.875H3.5V2.625H14V0.875ZM16.625 4.375H7C6.0375 4.375 5.25 5.1625 5.25 6.125V18.375C5.25 19.3375 6.0375 20.125 7 20.125H16.625C17.5875 20.125 18.375 19.3375 18.375 18.375V6.125C18.375 5.1625 17.5875 4.375 16.625 4.375ZM16.625 18.375H7V6.125H16.625V18.375Z"
                fill="#0F6FFF"
              />
            </g>
            <defs>
              <clipPath id="clip0_462_391">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
      <!-- End: copy link -->

      <!-- Items -->
      <div class="overflow-auto flex-grow-1">
        <div
          class="share-items d-flex align-items-center gap-3 justify-content-between pt-3 cursor-pointer"
          *ngFor="let icon of shareIcons"
          (click)="handleButtonClick(icon.id)"
        >
          <div class="d-flex align-items-center gap-3">
            <span class="share-icon sm text-center cursor-pointer">
              <img [src]="icon.src" [alt]="icon.alt" class="img-fluid" />
            </span>
            <span>
              {{ icon.content }}
            </span>
          </div>
          <div>
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.06023 8.00065L0.935232 1.87565C0.726899 1.66732 0.626204 1.42079 0.633149 1.13607C0.640093 0.851345 0.747732 0.604818 0.956065 0.396484C1.1644 0.188151 1.41093 0.0839844 1.69565 0.0839844C1.98037 0.0839844 2.2269 0.188151 2.43523 0.396484L8.83107 6.81315C8.99773 6.97982 9.12273 7.16732 9.20607 7.37565C9.2894 7.58398 9.33107 7.79232 9.33107 8.00065C9.33107 8.20898 9.2894 8.41732 9.20607 8.62565C9.12273 8.83398 8.99773 9.02148 8.83107 9.18815L2.4144 15.6048C2.20607 15.8132 1.96301 15.9138 1.68523 15.9069C1.40745 15.9 1.1644 15.7923 0.956065 15.584C0.747732 15.3757 0.643565 15.1291 0.643565 14.8444C0.643565 14.5597 0.747732 14.3132 0.956065 14.1048L7.06023 8.00065Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- End: Items -->
    </div>
  </div>
</div>
