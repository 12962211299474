import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  OrganisationDashboard,
  QueryParamsFields,
} from '../or-shared/or.interface';

@Injectable({
  providedIn: 'root',
})
export class OrAPIService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCurrentUserOrganisation() {
    return this.http.get(`${this.baseURL}org/profile`);
  }

  addGroup(path: string, data: any) {
    return this.http.post(`${this.baseURL}${path}`, data);
  }

  getById(path: string, id: number) {
    return this.http.get(`${this.baseURL}${path}${id}`);
  }

  getList(queryParamsFields: QueryParamsFields, path: string) {
    let params = new HttpParams();
    if (queryParamsFields?.page) {
      params = params.set('page', queryParamsFields?.page);
    }
    if (queryParamsFields?.pageSize) {
      params = params.set('pageSize', queryParamsFields?.pageSize);
    }
    if (queryParamsFields?.isAccepted) {
      params = params.set('isAccepted', queryParamsFields?.isAccepted);
    }
    if (queryParamsFields?.searchText) {
      params = params.set('searchText', queryParamsFields?.searchText);
    }
    if (queryParamsFields?.q) {
      params = params.set('q', queryParamsFields?.q);
    }
    if (queryParamsFields?.email) {
      params = params.set('email', queryParamsFields?.email);
    }
    if (queryParamsFields?.gradYear) {
      params = params.set('gradYear', queryParamsFields?.gradYear);
    }
    if (queryParamsFields?.name) {
      params = params.set('name', queryParamsFields?.name);
    }
    if (queryParamsFields?.month) {
      params = params.set('month', queryParamsFields?.month);
    }
    if (queryParamsFields?.year) {
      params = params.set('year', queryParamsFields?.year);
    }
    if (queryParamsFields?.countryShortCode) {
      params = params.set(
        'countryShortCode',
        queryParamsFields?.countryShortCode
      );
    }
    return this.http.get(`${this.baseURL}${path}`, { params });
  }

  getAllList(path: string) {
    return this.http.get(`${this.baseURL}${path}`);
  }

  inviteStudent(endpoints: string, email: any) {
    return this.http.post(`${this.baseURL}${endpoints}`, email);
  }
  inviteBulkStudents(endpoints: string,email: any) {
    return this.http.post(`${this.baseURL}${endpoints}`, email);
  }

  assignStudentToGroup(path: string, id: number, data: any) {
    return this.http.post(`${this.baseURL}${path}${id}`, data);
  }

  saveData(path: string, data: any) {
    return this.http.post(`${this.baseURL}${path}`, data);
  }

  delete(path: string, id: number) {
    return this.http.delete(`${this.baseURL}${path}${id}`);
  }
  updateData(path: string, data: any) {
    return this.http.put(`${this.baseURL}${path}`, data);
  }

  getOrDashboardData(): Observable<OrganisationDashboard> {
    return this.http.get<OrganisationDashboard>(`${this.baseURL}org/dashboard`);
  }

  deleteEvent(path: string): Observable<any> {
    return this.http.delete(`${this.baseURL}${path}`);
  }
}
