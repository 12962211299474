import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StudentDetails } from '../manage-students/models/student-interface';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  studentData: BehaviorSubject<StudentDetails> =
    new BehaviorSubject<StudentDetails>(null);

  constructor() {}
}
