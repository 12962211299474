<div class="upload_container">
  <div class="buttons mt-0">
    <button
      pButton
      pRipple
      class="p-primary p-button-outlined upload_btn"
      label="Upload"
      [disabled]="disabled"
      [ngClass]="{ disabled: disabled }"
      (click)="uploadInput.click()"
    ></button>
    <button
      pButton
      pRipple
      class="p-danger p-button-outlined trash"
      (click)="clearFile()"
      [disabled]="!file || disabled"
      [ngClass]="{ disabled: disabled }"
    >
      <img src="assets/icons/trash.svg " alt="" />
    </button>
  </div>
  <input
    type="file"
    [multiple]="isMultiple"
    style="display: none"
    #uploadInput
    [(ngModel)]="uploadInputModel"
    (change)="upload($event)"
    [disabled]="disabled"
    [accept]="fileTypes"
  />

  <!--    <div class="uploaded_imgs" *ngIf="isMultiple && fileArr.length > 0" >-->
  <!--        <ng-container *ngFor="let file of fileArr;let i = index">-->
  <!--<span class="img_preview">-->
  <!--{{getImgPreview(file)}}-->
  <!--</span>-->
  <!--        <span class="img_name">{{file.name}}         <span class="img_delete" (click)="clearFileArr(i)"><i class="pi pi-trash"></i></span>-->
  <!--</span>-->
  <!--        </ng-container>-->
  <!--    </div>-->

  <!--   <div class="uploaded_imgs" *ngIf="file" >-->
  <!--<span class="img_preview">-->
  <!--{{getImgPreview(file)}}-->

  <!--</span>-->
  <!--       <span class="img_name">-->
  <!--{{file.name}} </span>-->

  <!--   </div>-->
</div>
