<p-menubar
  [model]="items"
  [autoDisplay]="false"
  [ngClass]="{ 'judges-portal-header': isJudgesPortal }"
>
  <ng-template pTemplate="start">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <img
          src="../../../assets/icons/logo-2.svg"
          class="pointer-cursor"
          alt=""
          srcset=""
          (click)="handleLogoNav()"
        />
        <label
          *ngIf="!isMobileView"
          class="menu-bar-label d-flex cursor-pointer"
          for="primarySidebar"
        >
          <span class="pi pi-bars" *ngIf="isStudentProfile"></span>
        </label>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="end">
    <div
      class="d-flex justify-content-end gap-3 align-items-center judge-competition-dropdown"
    >
      <p-dropdown
        *ngIf="isJudgesPortal"
        optionLabel="name"
        placeholder="Select a Competition"
        [options]="judgesCompetitionsList"
        [(ngModel)]="selectedCompetition"
        (onChange)="onJudgesCompetitionChange(selectedCompetition)"
      ></p-dropdown>
      <label
        class="menu-bar-label d-flex cursor-pointer"
        for="primarySidebar"
        *ngIf="isMobileView"
      >
        <span class="pi pi-bars" *ngIf="isStudentProfile"></span>
      </label>
    </div>
  </ng-template>
</p-menubar>
