<section class="login-container">
  <div class="card-container">
    <div class="card-padding">
      <img src="../../../assets/icons/main-logo.svg" alt="" />
      <h3>Request Verification code</h3>
      <p *ngIf="!isRequest">
        Hello, Please click on the button below to receive verification code in
        your email.
        <!--, {{studentData.firstName}} {{studentData.lastName}}-->
      </p>
      <br *ngIf="!isRequest" />
      <button
        pButton
        pRipple
        type="submit"
        label="Request OTP on email"
        class="p-button-raised p-button-lg"
        *ngIf="!isRequest"
        (click)="requestOtp()"
      ></button>

      <p *ngIf="isRequest">
        A unique 6 digit code has been sent on your email {{ requestEmail }}, It
        will be valid for the next 30 mins.
      </p>
      <br />

      <form action="POST" *ngIf="isRequest">
        <span class="p-input-icon-left">
          <input
            pInputText
            class="p-inputtext-lg"
            placeholder="otp"
            [(ngModel)]="otpCode"
            type="number"
            [ngModelOptions]="{ standalone: true }"
          />
        </span>
        <br />
        <br />
        <span *ngIf="otpTimer" class="otpTimer">{{ otpTimer }}</span>

        <div class="flex otp_submit_btns">
          <button
            pButton
            pRipple
            type="submit"
            label="Resend OTP"
            class="p-button-raised p-button-lg"
            [disabled]="!isResend || submitted1"
            (click)="resendOtp()"
          ></button>

          <button
            pButton
            pRipple
            type="submit"
            label="Submit"
            class="p-button-raised p-button-lg"
            [disabled]="
              !otpCode || otpCode?.toString().length > 6 || submitted2
            "
            (click)="verifyOtp()"
          ></button>
        </div>
      </form>
    </div>
  </div>
</section>
