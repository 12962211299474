import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { SHARE_ICONS } from '../../constants/share-icons.constants';
import { ShareButtonClickService } from '../../services/share-button-click.service';

@Component({
  selector: 'app-share-page-card',
  templateUrl: './share-page-card.component.html',
  styleUrls: ['./share-page-card.component.scss'],
})
export class SharePageCardComponent {
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  @Input() isSharePageOpen: boolean = false;
  @Output() closeShareModal = new EventEmitter<boolean>();
  shareIcons = SHARE_ICONS;
  deviceType: string;
  isMobileView: boolean;
  shareLink: string;

  constructor(
    private shareButtonClickService: ShareButtonClickService
  ) {}

  ngOnInit(): void {
    this.checkDeviceType();
    this.shareLink = `${environment.openShareLink}explore-competitions`;
  }

  closeSharePage(event): void {
    this.closeShareModal.emit(event);
  }

  handleButtonClick(buttonId: number): void {
    this.shareButtonClickService.handleButtonClick(buttonId, this.shareLink);
  }

  copyToClipboard() {
   this.shareButtonClickService.copyToClipboard(this.shareLink);
  }
}
