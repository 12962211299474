<!-- footer.component.html -->
<div class="footer-container">
  <div class="header-footer">
    <div class="circle-icon">
      <span class="circle-icon-alpha">
        <svg
          width="32"
          height="25"
          viewBox="0 0 32 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5427 24.5331C-1.20849 21.901 -2.42948 6.59612 6.73417 1.86275C11.7034 -0.70457 17.4519 0.464339 20.8714 4.73655L22.7492 7.08221L23.6648 4.09366C24.3281 1.92761 25.0578 0.988209 26.3167 0.679588C27.2709 0.445094 28.2433 0.439391 28.4766 0.667471C28.7091 0.89555 27.8147 3.7159 26.4873 6.93467L24.0745 12.7863L25.5149 16.7621C26.6404 19.8718 27.4604 20.9708 29.2763 21.8069C32.4764 23.2808 32.2059 24.3942 28.5313 24.8767C24.5242 25.4027 22.7638 23.866 21.0807 18.375C16.9883 5.02664 15.9802 3.27542 12.3879 3.27542C6.30409 3.27542 3.06244 13.9039 7.40918 19.5967C9.33798 22.1233 10.7368 22.7926 12.9521 22.2488C14.8386 21.7855 15.8154 20.9537 18.0708 17.8875C19.0862 16.5069 19.1344 16.5268 19.6526 18.5446C20.0958 20.2702 19.8567 20.9031 18.2531 22.2524C15.5275 24.5446 11.8142 25.4162 8.5427 24.5331Z"
            fill="#3247C6"
          />
        </svg>
      </span>
    </div>
  </div>
  <div class="footer-content">
    <h3 class="text-center text-md-start">Unlock your personal AI university counselor</h3>
    <div class="footer-buttons">
      <button class="btn-login" [routerLink]="['/login']">Log in</button>
      <span>or</span>
      <button class="btn-access" [routerLink]="['/signup']">
        Free lifetime access
      </button>
    </div>
  </div>
</div>
