import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { CommonServiceService } from '../shared/common-service.service';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  isOrganisationInvite: boolean;
  competitionImages: {
    login_image_desktop: string;
    login_image_mobile: string;
  };
  imageBaseURL: string = environment.apiUrl;
  isLoading$ = new BehaviorSubject<boolean>(true);
  deviceType: string;
  isMobileView: boolean;
  isImageAvailableAfterLoad: boolean = false;
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonServiceService,
    private titleService: Title,
    private metaService: Meta,
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('org/acceptinvite')) {
      this.isOrganisationInvite = true;
    } else {
      this.isOrganisationInvite = false;
    }
    this.checkDeviceType();
    this.getLoginImages();

    if (this.router.url.includes('competition?competitionId')) {
      this.titleService.setTitle('AlgoEd Student | Login');
      this.metaService.updateTag({
        property: 'og:title',
        content: 'AlgoEd Student | Login'
      });
    }
  }

  getLoginImages() {
    this.activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          if (params['competitionId'] || params['competition_id']) {
            const id = params['competitionId'] || params['competition_id'];
            return this.commonService.getByIdDetail(
              'users/competitions/public/',
              id
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.isImageAvailableAfterLoad = res.data.login_image_desktop
            ? true
            : false;
          this.competitionImages = {
            login_image_desktop: res.data.login_image_desktop,
            login_image_mobile: res.data.login_image_mobile,
          };
        }
        this.isLoading$.next(false);
      });
  }
}
