import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../../shared/admin.service';
import { CommonServiceService } from '../../../shared/common-service.service';
import { MastersService } from '../../../shared/masters.service';
import { UsersService } from '../../../shared/users.service';
import { VimeoService } from '../../../shared/vimeo.service';

@Component({
  selector: 'app-quiz-popup',
  templateUrl: './quiz-popup.component.html',
  styleUrls: ['./quiz-popup.component.scss'],
})
export class QuizPopupComponent implements OnInit {
  setAnswer: any = [];
  questions: any;
  questionsCount: number = 0;
  questionSelected: number = 0;
  submissionStatus: any;
  showResults: boolean = false;

  studentData: any;
  subs: Subscription;
  videoId: any;
  qIdsObj: any;
  value: any;
  isActive: boolean;
  alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
  totalQuestions: number;
  totalCorrect: number;
  isLessonType: boolean;
  videoNotes: string;
  questionsAndAswers: any;
  baseURL: string;
  isImage: boolean;
  constructor(
    private commonService: CommonServiceService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public vimeoService: VimeoService,
    public adminService: AdminService,
    public mastersService: MastersService,
    public usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.videoId = this.config.data.videoId;
    this.isLessonType = this.config.data.isDocument;
    this.videoNotes = this.config.data.videoNotes;
    this.baseURL = this.config.data.baseURL;
    this.isImage = this.config.data.isImage;
    console.log(this.config.data.videoDetail);
    if (!this.isLessonType) {
      // this.getVideoQuestion();
      this.questionsCount = this.config.data.videoDetail?.questionData?.length;
      this.questions = this.config.data.videoDetail?.questionData;
    }
  }

  public getVideoQuestion() {
    this.adminService
      .getVideoQuestion({ data: { videoId: this.videoId } })
      .subscribe(
        (item) => {
          var qIds = item.data.map((d) => d.questionId);
          this.qIdsObj = qIds.join(',');
          this.getActiveQuestionsAnswersForVideo({
            data: { questionIds: this.qIdsObj },
          });
        },
        (err) => {
          this.commonService.showToast(err.error.message, 'warn', 3000, 'Info');
          console.log(err);
        }
      );
  }

  public getActiveQuestionsAnswersForVideo(data) {
    this.adminService.getActiveQuestionsAnswersForVideo(data).subscribe(
      (item) => {
        this.questions = item.data;
        this.questionsCount = this.questions.length;
      },
      (err) => {
        this.commonService.showToast(
          'Something Went wrong.!',
          'warn',
          3000,
          'Info'
        );
        console.log(err);
      }
    );
  }

  prevQuestion() {
    if (this.questionSelected > 0) {
      this.questionSelected = this.questionSelected - +1;
      this.showResults = false;
    }
  }

  nextQuestion() {
    if (this.questionSelected < this.questionsCount) {
      this.answerChange(
        this.questions[this.questionSelected],
        this.setAnswer[this.questionSelected]
      );
      this.questionSelected = this.questionSelected + 1;

      this.value = (this.questionSelected / this.questionsCount) * 100;
    }
  }

  activeAnswer(question, answer) {
    this.isActive = answer;
    const result = this.setAnswer.findIndex((e) => {
      return e && e.question == question;
    });

    if (result == -1) {
      this.setAnswer[question] = { question: question, answer: answer };
    } else {
      this.setAnswer[question].answer = answer;
    }
  }

  showResultsFun() {
    this.showResults = true;
    this.totalQuestions = this.questions?.length;
    this.totalCorrect = 0;
    this.questions.forEach((x, idx1) => {
      x.answers.forEach((y, idx2) => {
        if (
          this.setAnswer[idx1]?.question == idx1 &&
          this.setAnswer[idx1]?.answer === idx2 &&
          y.isCorrect == 1
        ) {
          this.totalCorrect += 1;
        }
      });
    });
    this.markVideoAsComplete();
  }

  markVideoAsComplete() {
    const data = {
      isCategorList: true,
      name: { videoid: this.videoId },
    };
    this.commonService.saveRecord('users/videos/complete', data).subscribe(
      (res) => {
        console.log('res', res);
      },
      (error) => {
        this.ref.close();
        this.commonService.showToast('Something went wrong', 'warn', 3000);
      }
    );
  }

  answerChange(question: any, selectedAnswer: any) {
    if (!selectedAnswer) {
      return;
    }
    const data = {
      isCategorList: true,
      name: {
        isCorrect: question.answers[selectedAnswer.answer].isCorrect,
        answerid: question.answers[selectedAnswer.answer].id,
      },
    };
    console.log(data);
    this.commonService.saveRecord('users/videos/quiz', data).subscribe(
      (res) => {
        console.log('res', res);
      },
      (error) => {
        this.commonService.showToast('Something went wrong', 'warn', 3000);
      }
    );
  }
}
