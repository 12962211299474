import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFullysubmitted',
})
export class GetFullysubmittedPipe implements PipeTransform {
  transform(value: any) {
    const roundDetails = value.rounds?.find((round) => {
      return round.round_number == value.current_round;
    });
    if (roundDetails) {
      return roundDetails.fully_submitted;
    } else {
      return false;
    }
  }
}
