<div class="team-selection-panel">
  <div class="search-bar mt-1">
    <app-search
      [searchMaxWidth]="'100%'"
      [searchFunction]="onSearch.bind(this)"
      (searchResults)="handleSearchResults($event)"
    ></app-search>
  </div>
  <div class="team-members-list">
    <h3>Team members</h3>
    <ul class="list-group">
      <!-- Repeat this list item for each team member -->
      <span *ngIf="isAllPaid" class="text-success"
        >Payment complete for all members.</span
      >
      <ng-container *ngIf="memberList?.length">
        <li
          class="list-group-item d-flex align-items-center mt-1"
          *ngFor="let item of memberList"
          [ngClass]="{ disabled: item?.payment_status === 'Paid' }"
        >
          <div class="profile-picture">
            <p-checkbox
              [binary]="true"
              [(ngModel)]="item.isChecked"
            ></p-checkbox>
            <figure
              class="avatar md cursor-pointer m-auto mb-4"
              style="margin-top: -2.25rem"
            >
              <span class="fw-normal white-space-nowrap">{{
                item?.name && item?.name.split(" ")[0].charAt(0)
              }}</span>
            </figure>
          </div>
          <div class="member-info">
            <strong>{{ item.name }}</strong>
            <span>{{ item.email }}</span>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="!memberList?.length">
        <p class="text-center text-danger">No team members found.</p>
      </ng-container>
      <!-- ... other team members ... -->
    </ul>
  </div>
  <div class="proceed-button">
    <button
      type="button"
      class="btn btn-success"
      [disabled]="isAllPaid"
      (click)="payForTeam()"
    >
      Proceed to pay
    </button>
  </div>
</div>
