<div class="shadow-none mt-3">
  <div [formGroup]="subjectForm">
    <div class="student-header-part">
      <h4>Tell us your university subject interest (for UK)</h4>
      <p class="sub-text">
        Please tell us your career interests so that we can give you
        personalized advice on choosing school courses, super-curricular
        activities, university subject, and application destinations.
      </p>
    </div>
    <div class="position-relative">
      <div class="grade-button-container">
        <button
          *ngFor="let grad of gradeButtons"
          class="grade-button"
          [class.selected]="grad.isSelected"
          (click)="toggleGradeButtons(grad)"
        >
          {{ grad.label }}
        </button>
      </div>
      <button class="grade-navigation-button right">
        <span class="pi pi-angle-right"></span>
      </button>
    </div>
    <div class="card shadow-none mt-3">
      <div class="card-body" *ngIf="selectedGrade.id == 1">
        <h2>{{ selectedGrade?.label }}</h2>
        <div class="input-group">
          <p class="sub-text my-2">
            Which subject are you interested in pursuing in university?
          </p>
          <textarea
            placeholder="Type here..."
            [rows]="10"
            [cols]="30"
            pInputTextarea
            formControlName="which_subject_interested_1"
          ></textarea>
        </div>
        <div class="input-group">
          <p class="sub-text my-2">
            What have you done to further your interest in this subject?
          </p>
          <textarea
            placeholder="Type here..."
            [rows]="10"
            [cols]="30"
            pInputTextarea
            formControlName="what_done_to_further_interest_in_subject_1"
          ></textarea>
        </div>
      </div>
      <div class="card-body" *ngIf="selectedGrade.id == 2">
        <h2>{{ selectedGrade?.label }}</h2>
        <div class="input-group">
          <p class="sub-text my-2">
            Which subject are you interested in pursuing in university?
          </p>
          <textarea
            placeholder="Type here..."
            [rows]="10"
            [cols]="30"
            pInputTextarea
            formControlName="which_subject_interested_2"
          ></textarea>
        </div>
        <div class="input-group">
          <p class="sub-text my-2">
            What have you done to further your interest in this subject?
          </p>
          <textarea
            placeholder="Type here..."
            [rows]="10"
            [cols]="30"
            pInputTextarea
            formControlName="what_done_to_further_interest_in_subject_2"
          ></textarea>
        </div>
      </div>
      <div class="card-body" *ngIf="selectedGrade.id == 3">
        <h2>{{ selectedGrade?.label }}</h2>
        <div class="input-group">
          <p class="sub-text my-2">
            Which subject are you interested in pursuing in university?
          </p>
          <textarea
            placeholder="Type here..."
            [rows]="10"
            [cols]="30"
            pInputTextarea
            formControlName="which_subject_interested_3"
          ></textarea>
        </div>
        <div class="input-group">
          <p class="sub-text my-2">
            What have you done to further your interest in this subject?
          </p>
          <textarea
            placeholder="Type here..."
            [rows]="10"
            [cols]="30"
            pInputTextarea
            formControlName="what_done_to_further_interest_in_subject_3"
          ></textarea>
        </div>
      </div>
      <footer
        *ngIf="isStudent"
        class="d-flex justify-content-center gap-3 border-top p-3 mt-3 pb-0"
      >
        <button pButton type="button" class="p-cancel-btn">
          <span class="flex-nowrap">Cancel</span>
        </button>
        <button pButton type="button" (click)="saveData()" class="p-primary">
          <span class="flex-nowrap">Save</span>
        </button>
      </footer>
    </div>
  </div>
</div>
