import {
  Component,
  Output,
  forwardRef,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doc-file-upload',
  templateUrl: './doc-file-upload.component.html',
  styleUrls: ['./doc-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocFileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocFileUploadComponent),
      multi: true,
    },
  ],
})
export class DocFileUploadComponent {
  @ViewChild('outlinedButton') outlinedButton: ElementRef;

  @Input() files: File[] | any = [];
  @Input() previewImage: boolean = false;
  @Input() isMultiple: boolean = true;
  @Input() buttonName: string = 'Attach';
  @Input() isDirectUpload: boolean = false;
  @Input() acceptedTypes: string = 'application/pdf, image/jpeg, image/png';
  @Input() isOutlined: boolean = false;

  @Output() fileAdded: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileRemoved: EventEmitter<number> = new EventEmitter<number>();

  onChange: any = () => {};
  onTouched: any = () => {};
  imageBaseURL: string = environment.apiUrl;

  onFilesSelected(event: Event): void {
    const filesAdded = (event.target as HTMLInputElement).files;
    if (filesAdded) {
      for (let i = 0; i < filesAdded.length; i++) {
        if (!this.files || !this.files.length) {
          this.files = [];
        }
        this.files.push(filesAdded[i]);
        this.fileAdded.emit(filesAdded[i]);
        this.onChange(this.files);
      }
    }
    console.log(this.files);
  }

  removeFile(index: number): void {
    if (this.isMultiple) {
      this.files.splice(index, 1);
    }
    this.fileRemoved.emit(index);
    this.onChange(this.files);
  }

  writeValue(files: File[]): void {
    console.log(files);
    if (files) {
      this.files = files;
    } else {
      this.files = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(ctrl: FormControl) {
    return this.files.length > 0 ? null : { required: true };
  }
}
