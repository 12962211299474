import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordCount',
})
export class WordCountPipe implements PipeTransform {
  transform(value: string): number {
    // Trim whitespace from start and end of string
    let trimmedValue = value?.trim();

    // Split by one or more whitespace characters (including newlines)
    let words = trimmedValue?.split(/\s+/);

    // Filter out empty strings (e.g., from multiple spaces or newlines)
    words = words && words?.filter((word) => word.length > 0);

    // Return the number of words
    return words?.length > 0 ? words.length : 0;
  }
}
