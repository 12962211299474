<div
  class="certificate-footer d-flex flex-column-reverse flex-lg-row align-items-lg-center justify-content-between gap-2"
>
  <div>
    <h5 class="m-0 mb-1 fs-5">Explore Competitions</h5>
    <p class="m-0 mb-3 fw-medium font-mc">
      For ambitious middle and high school students
    </p>
    <a
      class="btn btn-ternary text-white"
      routerLink="/explore-competitions"
      target="_blank"
    >
      Explore Competitions
    </a>
  </div>
  <figure class="m-0">
    <img
      class="img-fluid"
      src="../../../assets/images/certifications/certificate-group.png"
      alt="Ceritficate Group"
    />
  </figure>
</div>
