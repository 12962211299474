import { Component, HostListener, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../shared/common-service.service';
import { MastersService } from '../shared/masters.service';
import { UsersService } from '../shared/users.service';
import Validation from '../shared/validation';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  signupForm: UntypedFormGroup;
  submitted = false;
  countries: any;
  genderCategory: any;
  years: any = [];
  terms: any;
  isDialog: boolean = false;

  competitionImages: {
    login_image_desktop: string;
    login_image_mobile: string;
  };
  imageBaseURL: string = environment.apiUrl;
  isLoading$ = new BehaviorSubject<boolean>(true);
  deviceType: string;
  isMobileView: boolean;
  isImageAvailableAfterLoad: boolean = false;
  competitionIdForStudent: any;

  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  constructor(
    private commonService: CommonServiceService,
    private usersService: UsersService,
    private router: Router,
    private mastersService: MastersService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.competitionIdForStudent =
      localStorage.getItem('competitionIdForStudent') || null;

    this.getLoginImages();

    this.checkDeviceType();
    this.getCountries();
    this.getYears();
    this.genderCategory = [
      { key: 'Male', value: 'Male' },
      { key: 'Female', value: 'Female' },
      { key: 'Others', value: 'Others' },
    ];
    this.signupForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        parentEmailId: ['', [Validators.email]],
        gradYear: ['', Validators.required],
        phone: [null],
        country: ['', Validators.required],
        gender: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.required],
        isEighteenPlus: [false, Validators.required],
        sendMarketingEmails: [false, Validators.required],
        termsId: ['1'],
      },
      {
        validators: [Validation.match('password', 'confirmPassword')],
      }
    );
  }

  get f() {
    return this.signupForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    let data1 = this.signupForm.value;

    //
    //
    if (this.signupForm.invalid) {
      return;
    }

    let data = this.signupForm.value;

    if (data.acceptTerms === false) {
      this.commonService.showToast('Please accept Terms', 'warn', 3000, 'Info');
      return;
    }
    delete data.confirmPassword;
    delete data.acceptTerms;
    if (data.sendMarketingEmails == true) {
      data.sendMarketingEmails = 1;
    } else {
      data.sendMarketingEmails = 0;
    }
    if (data.isEighteenPlus == true) {
      data.isEighteenPlus = 1;
    } else {
      data.isEighteenPlus = 0;
    }
    // data.sendMarketingEmails = data.sendMarketingEmails ? true : 1 ||  data.sendMarketingEmails ? false : 0;
    // data.isEighteenPlus = data.isEighteenPlus ? true : 1 ||  data.isEighteenPlus ? false : 0;
    // data.termsId = this.termId;

    this.usersService.signup({ data }).subscribe(
      (item) => {
        this.commonService.showToast(
          'Signup successful.',
          'success',
          3000,
          'Info'
        );
        localStorage.setItem('token', item.token);
        localStorage.setItem('user', JSON.stringify(item.data[0]));
        this.commonService.$curUser.next(item.data[0]);
        this.commonService.$curUserToken.next(item.token);

        this.router.navigate(['/student/admission/US/dashboard']);
      },
      (err) => {
        this.commonService.showToast(err.error.message, 'warn', 3000, 'Info');
        // this.loginErr = err.error.message
        this.submitted = false;
        console.log(err);
      }
    );
  }

  onReset(): void {
    this.submitted = false;
    this.signupForm.reset();
  }

  public getCountries() {
    this.mastersService.getCountries({}).subscribe(
      (item) => {
        this.countries = item;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private getYears() {
    var max = new Date().getFullYear();
    var min = max + 9;
    for (var i = max; i <= min; i++) {
      this.years.push({ year: i });
    }
  }

  getLoginImages() {
    this.activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          if (this.competitionIdForStudent) {
            return this.commonService.getByIdDetail(
              'users/competitions/public/',
              this.competitionIdForStudent
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.isImageAvailableAfterLoad = res.data.login_image_desktop
            ? true
            : false;
          this.competitionImages = {
            login_image_desktop: res.data.login_image_desktop,
            login_image_mobile: res.data.login_image_mobile,
          };
        }
        this.isLoading$.next(false);
      });
  }
}
