import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateHighlight',
  pure: true,
})
export class DateHighlightPipe implements PipeTransform {
  transform(date: any, events: any[]): boolean {
    const dateString = `${date.year}-${
      date.month + 1 > 10 ? date.month + 1 : `0${date.month + 1}`
    }-${date.day > 10 ? date.day : `0${date.day}`}`;
    
    if (!events || !events.length) {
      return false;
    }
    return events.some((event) => {
      const calenderDate = event.date.split('T')[0];
      return calenderDate === dateString;
    });
  }
}
