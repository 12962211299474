import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/services/admin.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
  selector: 'app-university-subject-interest',
  templateUrl: './university-subject-interest.component.html',
  styleUrls: ['./university-subject-interest.component.scss'],
})
export class UniversitySubjectInterestComponent implements OnInit {
  gradeButtons: any[] = [
    {
      id: 1,
      label: 'Subject 1',
      isSelected: false,
      activeInterest: 'which_subject_interested_1',
      activeSubject: 'what_done_to_further_interest_in_subject_1',
    },
    {
      id: 2,
      label: 'Subject 2',
      isSelected: false,
      activeInterest: 'which_subject_interested_2',
      activeSubject: 'what_done_to_further_interest_in_subject_2',
    },
    {
      id: 3,
      label: 'Subject 3',
      isSelected: false,
      activeInterest: 'which_subject_interested_3',
      activeSubject: 'what_done_to_further_interest_in_subject_3',
    },
  ];
  selectedGrade: any;
  subjectForm: FormGroup;

  activeInterest: string;
  activeSubject: string;
  subscription: Subscription;
  isStudent: boolean = true;
  constructor(
    private fb: FormBuilder,
    private commonService: CommonServiceService,
    private router: Router,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.toggleGradeButtons(this.gradeButtons[0]);
    this.subjectForm = this.build();
    if (this.router.url.includes('admin/manage-students/student-details/')) {
      this.getAdminStudentData();
      this.isStudent = false;
    } else {
      this.getSubjectData();
    }
  }

  getAdminStudentData() {
    this.adminService.studentData.asObservable().subscribe((item) => {
      if (item) {
        this.subjectForm.patchValue(
          item?.profile_data?.university_subject_interest
        );
      }
    });
  }

  getSubjectData() {
    this.commonService
      .getQuestionDetails('users/profile/subinterest')
      .subscribe((res) => {
        this.subjectForm.patchValue(res?.data);
      });
    this.subscription = this.commonService
      .triggerChildMethod()
      .subscribe((res) => {
        this.saveData();
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  build(): any {
    return this.fb.group({
      id: [],
      which_subject_interested_1: [''],
      what_done_to_further_interest_in_subject_1: [''],
      which_subject_interested_2: [''],
      what_done_to_further_interest_in_subject_2: [''],
      which_subject_interested_3: [''],
      what_done_to_further_interest_in_subject_3: [''],
    });
  }

  toggleGradeButtons(grad) {
    this.selectedGrade = grad;
    this.gradeButtons.forEach((element) => {
      if (element.id == grad.id) {
        element.isSelected = true;
        this.activeInterest = element.activeInterest;
        this.activeSubject = element.activeSubject;
      } else {
        element.isSelected = false;
      }
    });
  }

  saveData() {
    const data = {
      isCategorList: true,
      name: this.subjectForm.value,
    };
    this.commonService.saveRecord('users/profile/subinterest', data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Data saved successfully',
          'success',
          1000
        );
      },
      (error) => {
        this.commonService.showToast('Something went wrong', 'error', 1000);
      }
    );
  }
}
