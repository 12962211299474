import { Component } from '@angular/core';

@Component({
  selector: 'app-certificate-footer',
  templateUrl: './certificate-footer.component.html',
  styleUrls: ['./certificate-footer.component.scss']
})
export class CertificateFooterComponent {

}
