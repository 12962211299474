import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, ObservableInput } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  baseURL: string = environment.apiUrl + 'users';
  basicURL: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  listNotification(): Observable<any> {
    return this.http.get(`${this.baseURL}/notifications`);
  }

  changeNotificationStatus(id: any): Observable<any> {
    return this.http.put(`${this.baseURL}/notifications/${id}`, {});
  }

  acceptOrRejectInvite(path: any, inviteCode: any): Observable<any> {
    return this.http.post(`${this.baseURL}/${path}`, inviteCode);
  }

  //login
  login(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/login`, object);
  }

  //changePassword
  changePassword(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/updateAccountInfo`, object);
  }

  getAllOrganisationListGroup(): Observable<any> {
    return this.http.get(`${this.baseURL}/organizations`);
  }

  leaveOrg(id: number): Observable<any> {
    return this.http.delete(`${this.baseURL}/organizations/${id}`);
  }

  getCalenderTasks(dateFilter: any): Observable<any> {
    let params = new HttpParams();
    if (dateFilter?.month) {
      params = params.set('month', dateFilter.month);
    }
    if (dateFilter?.year) {
      params = params.set('year', dateFilter.year);
    }
    return this.http.get(`${this.baseURL}/calender`, { params });
  }

  //get single student details
  getStudentData(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getStudentData`, object);
  }

  editStudentDataFromStudentProfile(object: any): Observable<any> {
    return this.http.put(`${this.baseURL}/profile`, object);
  }

  //get Profile University plan of student
  getProfileUniversityPlans(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileUniversityPlans`, obj);
  }

  //get Profile Carrer Interst of student
  getProfileCareerInterest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileCareerInterest`, obj);
  }

  //get Profile Academic Interst of student
  getProfileAcademicInterest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileAcademicInterest`, obj);
  }

  //get profile standardized tests of student
  getProfileStandardizedTests(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileStandardizedTests`, obj);
  }

  //get Profile academic record of student
  getProfileAcademicRecord(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileAcademicRecord`, obj);
  }

  //get Profile Personality Test  of student
  getProfilePersonalityTest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfilePersonalityTest`, obj);
  }

  //Student Request in Manage Student For Calendar activity
  //get Request Extra curricular of student
  getRequestExtraCurricularInterst(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getRequestExtraCurricularInterst`,
      obj
    );
  }

  //get Request University plan of student
  getRequestUniversityPlans(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestUniversityPlans`, obj);
  }

  //get Request Carrer Interst of student
  getRequestCareerInterest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestCareerInterest`, obj);
  }

  //get Request Academic Interst of student
  getRequestAcademicInterest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestAcademicInterest`, obj);
  }

  //get Request standardized tests of student
  getRequestStandardizedTests(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestStandardizedTests`, obj);
  }

  //get Request academic record of student
  getRequestAcademicRecord(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestAcademicRecord`, obj);
  }

  //get Request Personality Test  of student
  getRequestPersonalityTest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestPersonalityTest`, obj);
  }

  //get reuqest of calendar activity
  getRequestCalendarActivity(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestCalendarActivity`, obj);
  }
  //Student Request in Manage Student For Calendar activity

  // get User watched video module history
  getUserWatchedVideoModuleHistory(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getUserWatchedVideoModuleHistory`,
      obj
    );
  }

  // get User watched video module history for task
  getUserWatchedVideoModuleHistoryForTask(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getUserWatchedVideoModuleHistoryForTask`,
      obj
    );
  }

  // get video module task details
  getVideoModuleTaskDetails(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getVideoModuleTaskDetails`, obj);
  }

  // active inactive user
  activeInactiveUser(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/activeInactiveUser`, obj);
  }

  addProfileAcademicRecord(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addProfileAcademicRecord`, obj);
  }
  changeProfileAcademicRecord(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/changeProfileAcademicRecord`, obj);
  }

  removeProfileAcademicRecord(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/removeProfileAcademicRecord`, obj);
  }

  addUpdateProfileAcademicInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addUpdateProfileAcademicInterest`,
      obj
    );
  }

  addUpdateProfileCareerInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addUpdateProfileCareerInterest`,
      obj
    );
  }
  addUpdateProfileUniversityPlans(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addUpdateProfileUniversityPlans`,
      obj
    );
  }

  addProfileActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addProfileActScore`, obj);
  }

  updateProfileActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateProfileActScore`, obj);
  }

  addProfileSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addProfileSatScore`, obj);
  }

  updateProfileSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateProfileSatScore`, obj);
  }

  addProfileOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addProfileOxbridgeScores`, obj);
  }

  updateProfileOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateProfileOxbridgeScores`, obj);
  }

  getProfileActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileActScore`, obj);
  }

  getProfileSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileSatScore`, obj);
  }

  getProfileOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getProfileOxbridgeScores`, obj);
  }

  deleteProfileOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteProfileOxbridgeScores`, obj);
  }

  deleteProfileSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteProfileSatScore`, obj);
  }

  deleteProfileActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteProfileActScore`, obj);
  }

  getActivityTypes(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getActivityTypes`, obj);
  }

  addProfileExtraCurricularInterst(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addProfileExtraCurricularInterst`,
      obj
    );
  }

  addProfileExtraCurricularInterstFuture(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addProfileExtraCurricularInterstFuture`,
      obj
    );
  }

  changeProfileExtraCurricularInterst(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/changeProfileExtraCurricularInterst`,
      obj
    );
  }

  changeProfileExtraCurricularInterstFuture(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/changeProfileExtraCurricularInterstFuture`,
      obj
    );
  }

  removeProfileExtraCurricularInterst(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/removeProfileExtraCurricularInterst`,
      obj
    );
  }
  addUpdateProfilePersonalityTest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addUpdateProfilePersonalityTest`,
      obj
    );
  }

  userWatchedCalendarVideo(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/userWatchedCalendarVideo`, obj);
  }

  updateForUser_userWatchedVideosTaskNote(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/updateForUser_userWatchedVideosTaskNote`,
      obj
    );
  }

  addDiaryEntry(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addDiaryEntry`, obj);
  }

  editDiaryEntry(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/editDiaryEntry`, obj);
  }

  deleteDiaryEntry(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteDiaryEntry`, obj);
  }

  addDiaryObjective(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addDiaryObjective`, obj);
  }

  editDiaryObjective(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/editDiaryObjective`, obj);
  }

  deleteDiaryObjective(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteDiaryObjective`, obj);
  }

  getStudentDiaryObjective(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getStudentDiaryObjective`, obj);
  }

  addDebitPoints(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addDebitPoints`, obj);
  }

  submitActivity(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/submitActivity`, obj);
  }
  updateActivityTypeIds(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateActivityTypeIds`, obj);
  }
  addRequestAcademicRecord(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestAcademicRecord`, obj);
  }
  addRequestAcademicInterest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestAcademicInterest`, obj);
  }

  addRequestCareerInterest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestCareerInterest`, obj);
  }

  addRequestUniversityPlans(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestUniversityPlans`, obj);
  }
  addRequestExtraCurricularInterst(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addRequestExtraCurricularInterst`,
      obj
    );
  }
  addRequestActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestActScore`, obj);
  }

  updateRequestActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateRequestActScore`, obj);
  }

  addRequestSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestSatScore`, obj);
  }

  updateRequestSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateRequestSatScore`, obj);
  }

  addRequestOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/addRequestOxbridgeScores`, obj);
  }

  updateRequestOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateRequestOxbridgeScores`, obj);
  }

  getRequestActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestActScore`, obj);
  }

  getRequestSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestSatScore`, obj);
  }

  getRequestOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getRequestOxbridgeScores`, obj);
  }

  deleteRequestOxbridgeScores(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteRequestOxbridgeScores`, obj);
  }

  deleteRequestSatScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteRequestSatScore`, obj);
  }

  deleteRequestActScore(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteRequestActScore`, obj);
  }
  getAllEssayTypes_onlyActive(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getAllEssayTypes_onlyActive`, obj);
  }

  submitEssay(formdata): Observable<any> {
    return this.http.post(`${this.baseURL}/submitEssay`, formdata);
  }

  getStudentCalendarCreditsBasedOnActivityType(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getStudentCalendarCreditsBasedOnActivityType`,
      obj
    );
  }

  signup(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/signup`, obj);
  }

  submitOtherSubmission(formdata): Observable<any> {
    return this.http.post(`${this.baseURL}/submitOtherSubmission`, formdata);
  }

  getStudentsOtherSubmissionRequests(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/submitOtherSubmission`, obj);
  }

  getOtherSubmissionRequest(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getOtherSubmissionRequest`, obj);
  }

  forgotPassword(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/forgotPassword`, obj);
  }

  changePasswordFromToken(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/changePasswordFromToken`, obj);
  }

  addCredit_purchased(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addCredit_purchased`, object);
  }

  addUniversitiesToFav(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addUniversitiesToFav`, object);
  }

  removeUniversityFromFav(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeUniversityFromFav`, object);
  }

  createCustomerPortalSession(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/create-customer-portal-session`,
      object
    );
  }

  sendVerificationCode(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/sendVerificationCode`, object);
  }

  checkVerificationCode(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/checkVerificationCode`, object);
  }

  hideEvalTab(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/hideEvalTab`, object);
  }

  //get Profile Academic Interst of student
  getProfileUniversitySubjectInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getProfileUniversitySubjectInterest`,
      obj
    );
  }

  addUpdateProfileUniversitySubjectInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addUpdateProfileUniversitySubjectInterest`,
      obj
    );
  }

  getProfileSuperCurricularInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getProfileSuperCurricularInterest`,
      obj
    );
  }

  //get Profile Extra curricular of student For future activity
  getProfileSuperCurricularInterestFuture(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getProfileSuperCurricularInterestFuture`,
      obj
    );
  }
  addProfileSuperCurricularInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addProfileSuperCurricularInterest`,
      obj
    );
  }

  addProfileSuperCurricularInterestFuture(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addProfileSuperCurricularInterestFuture`,
      obj
    );
  }

  changeProfileSuperCurricularInterest(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/changeProfileSuperCurricularInterest`,
      obj
    );
  }

  changeProfileSuperCurricularInterestFuture(obj): Observable<any> {
    return this.http.post(
      `${this.baseURL}/changeProfileSuperCurricularInterestFuture`,
      obj
    );
  }

  handleUserByAdmin(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/handleUserByAdmin`, obj);
  }

  sendChatMessage(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/sendChatMessage`, obj);
  }

  allowUnallowResponse(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/allowUnallowResponse`, obj);
  }

  seenChatMessage(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/seenChatMessage`, obj);
  }

  getChatMessages(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getChatMessages`, obj);
  }

  replyChatMessage(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/replyChatMessage`, obj);
  }

  getUnseenCount(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getUnseenCount`, obj);
  }

  // confirmWelcomeUser(obj): Observable<any> {
  //   return this.http.post(`${this.baseURL}/confirmWelcomeUser`, obj);
  // }

  getAllNewSubscribedUsers(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getAllNewSubscribedUsers`, obj);
  }
  getCalendarEvent(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getCalendarEvent`, obj);
  }

  getTotalSpentByUser(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/getTotalSpentByUser`, obj);
  }

  updateAccountInfo(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/updateAccountInfo`, obj);
  }

  newSignup(obj): Observable<any> {
    return this.http.post(`${this.baseURL}/newSignup`, obj);
  }

}
