import { Component, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent {
  @Input() endTime: string;
  @Input() isUpcomingCompetition: boolean = false;
  @Input() isLiveCompetition: boolean = false;

  public timeLeft: {
    days: number | string;
    hours: number | string;
    minutes: number | string;
  };
  private timerSubscription: Subscription;

  ngOnInit(): void {
    if (!this.isUpcomingCompetition) {
      this.calculateTimeLeft();
      this.timerSubscription = interval(1000).subscribe(() =>
        this.calculateTimeLeft()
      );
    }
  }

  private calculateTimeLeft(): void {
    const endDate = new Date(this.endTime);
    const now = new Date();
    const difference = endDate.getTime() - now.getTime();

    if (difference > 0) {
      this.timeLeft = {
        days:
          Math.floor(difference / (1000 * 60 * 60 * 24)) >= 10
            ? Math.floor(difference / (1000 * 60 * 60 * 24))
            : '0' + Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours:
          Math.floor((difference / (1000 * 60 * 60)) % 24) >= 10
            ? Math.floor((difference / (1000 * 60 * 60)) % 24)
            : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes:
          Math.floor((difference / 1000 / 60) % 60) >= 10
            ? Math.floor((difference / 1000 / 60) % 60)
            : '0' + Math.floor((difference / 1000 / 60) % 60),
      };
    } else {
      this.timeLeft = { days: 0o0, hours: 0o0, minutes: 0o0 };
      this.timerSubscription && this.timerSubscription.unsubscribe(); // Stop the timer if it's past the end time
    }
  }
}
