<div class="p-3 overflow-auto">
  <div class="mt-2" *ngFor="let round of previousRounds">
    <h3
      class="mt-0"
      *ngIf="
        awardList[round.round_number] && awardList[round.round_number]?.length
      "
    >
      {{ round?.round_name }}
    </h3>
    <div
      class="awards-cards view-awards-modal"
      *ngIf="
        awardList[round.round_number] && awardList[round.round_number]?.length
      "
    >
      <div
        class="awards-card"
        *ngFor="let award of awardList[round.round_number]"
      >
        <figure class="awards-figure w-100">
          <img
            class="img-fluid w-100"
            [src]="award | getImageForAwards : 'competition'"
            [alt]="award.award_title"
          />
        </figure>
        <h6 class="text-black m-0">
          <div>{{ award?.award_title }}</div>
          <div class="text-muted fs-6">
            {{
              award?.award_category == "country"
                ? award?.country_name
                : award?.award_category == "regional"
                ? award?.region_name
                : ""
            }}
          </div>
        </h6>
        <p
          class="d-flex align-items-center justify-content-center gap-2 m-0 mt-auto"
        >
          <span class="cursor-pointer" (click)="viewAward(award)"
            >View Award</span
          >
          <img
            (click)="viewAward(award)"
            class="cursor-pointer"
            src="../../../../assets/competitions/arrow-right.svg"
            alt="View Award"
          />
        </p>
      </div>
    </div>
    <!-- <ng-template #noAwards>
      <h3 class="text-center">No Awards</h3>
    </ng-template> -->
  </div>
</div>
