<canvas
  *ngIf="!isMobileView"
  #certificateCanvas
  style="width: 100%; letter-spacing: 2px"
  width="1125"
  height="720"
></canvas>
<canvas
  *ngIf="isMobileView"
  #certificateCanvas
  style="width: 100%; letter-spacing: 2px"
  width="1250"
  height="740"
></canvas>
