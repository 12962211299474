import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { CommonServiceService } from '../../common-service.service';
import {
  Answer,
  ForumQuestion,
  Tag,
  Tags,
} from '../../models/shared.interface';
import { SharedApiService } from '../../services/shared-api.service';
import { SharedMethodsService } from '../../services/shared-methods.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forum-list',
  templateUrl: './forum-list.component.html',
  styleUrls: ['./forum-list.component.scss'],
})
export class ForumListComponent {
  // View-related decorator
  @ViewChild('op') op: any;

  // Input decorators
  @Input({
    required: true,
  })
  isAdmin: boolean;

  @Input()
  isPublic: boolean = false;

  @Input()
  isYourQuestionVisible: boolean = true;

  // String type
  questionPath: string;
  searchText: string;
  questionContent: string;
  title: string;
  selectedCountry: string;
  selectedCountryForFilter: string;
  // Subject type
  searchTextChanges: Subject<string> = new Subject<string>();

  // Array types
  tagList: Tags[];
  answerAvtars: any[] = [];
  selectedTagsForFilter: any[] = [];
  addAnswer: string[] = [];
  questionid: any[] = [];
  answerID: any[] = [];
  editedAnswer: any[] = [];
  questionsList: ForumQuestion[] = [];
  tagsList: any[] = [];
  selectedTags: any[] = [];
  content: string;
  replies: any = [];
  countries: any[] = [];
  selectedCountryFilterForAdmin: string = 'US';
  listItems: any[] = [
    { name: 'Approve', status: 'APPROVE_MANUAL' },
    { name: 'Reject', status: 'REJECT_MANUAL' },
  ];

  // Object types
  sharableLink = {
    link: null,
  };

  // Number type
  totalQuestions: number;

  // Boolean types
  isSharableDialogOpened: boolean;

  // Object type
  paginationConfig: any = {
    page: 1,
    pageSize: 5,
    q: '',
    tags: [],
    countryShortCode: 'US',
  };

  // Other variables
  statusItems = [
    {
      label: 'Approve',
      icon: 'pi pi-check',
    },
  ];
  usOrUK: string;
  currentItems: any;
  first_name: string;
  last_name: string;
  country: string;
  superAdminUSUK: any = 'US';
  // Add a blank line to separate different types

  constructor(
    private sharedApiService: SharedApiService,
    private commonService: CommonServiceService,
    private router: Router,
    private sharedMethods: SharedMethodsService
  ) {}

  ngOnInit() {
    this.usOrUK = this.router.url.split('/')[3] ?? 'US';
    this.paginationConfig.countryShortCode = this.usOrUK;
    this.getCountries();
    if (this.isYourQuestionVisible) {
      this.questionPath = 'questions';
    } else {
      this.questionPath = 'questions/myquestions';
    }
    this.getQuestionsList();
    this.currentItems = JSON.parse(localStorage.getItem('user') || '');
    // console.log(this.currentItems, localStorage.getItem('user'));
    // if (!this.isAdmin) {
    if (!this.isAdmin) {
      this.superAdminUSUK = this.usOrUK;
    }
    this.getTagsList();

    this.commonService.getSelectedCountry().subscribe((res) => {
      this.selectedCountry = res;
    });
    // }
    if (this.isAdmin) {
      this.selectedCountryFilterForAdmin = this.usOrUK;
    }
  }

  getTagsByCountry() {
    this.tagList = [];
    this.selectedTags = [];
    this.getTagsList();
  }

  onCountrySelected() {
    this.paginationConfig.country = this.selectedCountryForFilter;
    this.questionsList = [];
    this.paginationConfig.page = 1;
    this.getQuestionsList();
  }

  getCountries() {
    this.commonService.getCountries({}).subscribe(
      (item) => {
        this.countries = item;
      },
      (error) => {
        this.commonService.showToast(
          error.error?.error ?? 'Something went wrong',
          'warn',
          3000
        );
      }
    );
  }

  onTagSelected() {
    this.paginationConfig.tags = [...this.selectedTagsForFilter];
    this.paginationConfig.page = 1;
    this.paginationConfig.countryShortCode = this.usOrUK;
    this.questionsList = [];
    this.getQuestionsList();
  }

  openInNewTab() {
    if (this.sharableLink) window.open(this.sharableLink.link, '_blank');
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.sharableLink.link);
    this.commonService.showToast('Link Copied', 'success', 1000);
  }

  shareQuestion(question: ForumQuestion) {
    this.isSharableDialogOpened = !this.isSharableDialogOpened;
    this.sharableLink.link = `${environment.openShareLink}forum?sharableLink=${question.slug}`;
  }

  handleSearchResults(searchResults: any) {
    this.totalQuestions = searchResults.total;
    if (searchResults.data.questions && searchResults.data.questions.length) {
      const list = searchResults.data.questions.map((x) => {
        x['isCommentsOpened'] = false;
        x.isAddAnswerVisible = false;
        x.answerCount = x.AnswerCount;
        if (x.status == 'REJECT_MANUAL') {
          x.statusLabel = 'Rejected';
        }
        if (x.status == 'APPROVE_MANUAL' || x.status == 'APPROVE_AUTO') {
          x.statusLabel = 'Approved';
        }
        return x;
      });
      this.questionsList = list;
    } else {
      this.questionsList = [];
    }
  }

  onSearch(searchText: string) {
    if (!searchText) {
      this.paginationConfig = {
        page: 1,
        pageSize: 5,
        q: '',
      };
      this.getQuestionsList();
    }
    this.paginationConfig.q = searchText;
    return this.sharedApiService.getQuestionListInForum(
      this.questionPath,
      this.paginationConfig
    );
  }

  adminCountryFilter() {
    // this.paginationConfig.country = this.selectedCountryFilterForAdmin;
    this.paginationConfig = {
      page: 1,
      pageSize: 5,
      countryShortCode: this.selectedCountryFilterForAdmin,
    };
    this.questionsList = [];
    this.tagList = [];
    this.getTagsList();
    this.getQuestionsList();
  }

  getQuestionsList() {
    this.sharedApiService
      .getQuestionListInForum(this.questionPath, this.paginationConfig)
      .subscribe(
        (response) => {
          this.totalQuestions = response.total;
          if (response.data.questions && response.data.questions.length) {
            const list = response.data.questions.map((x) => {
              x['isCommentsOpened'] = false;
              x.isAddAnswerVisible = false;
              x.answerCount = x.AnswerCount;
              if (x.status == 'REJECT_MANUAL') {
                x.statusLabel = 'Rejected';
              }
              if (x.status == 'APPROVE_MANUAL' || x.status == 'APPROVE_AUTO') {
                x.statusLabel = 'Approved';
              }
              x.fillTheArrayForRepiles = [];
              for (let i = 0; i < x.answerCount; i++) {
                x.fillTheArrayForRepiles.push(i);
              }
              return x;
            });

            this.questionsList.push(...list);
          }
        },
        (error) => {
          console.log(`Error at :- ${this.constructor.name}:- ${error}`);
          this.commonService.showToast(
            error.error?.error ?? 'Something went wrong',
            'warn',
            3000
          );
        }
      );
  }

  onScroll() {
    if (this.questionsList.length !== this.totalQuestions) {
      this.paginationConfig.page++;
      this.getQuestionsList();
    }
  }

  onTextSearched() {
    if (this.searchText) {
      this.searchTextChanges.next(this.searchText);
    } else {
      this.paginationConfig = {
        page: 1,
        pageSize: 5,
        q: '',
      };
      this.getQuestionsList();
    }
  }

  getTagsList() {
    this.sharedApiService
      .getListInForum(`questions/tags?countryShortName=${this.superAdminUSUK}`)
      .subscribe(
        (res) => {
          this.tagList = res.data.tags;
        },
        (error) => {
          this.commonService.showToast(error.error.error, 'error', 3000);
        }
      );
  }

  addTag(tag: Tag) {
    const isTagPresent = this.selectedTags.find((x) => x == tag.id);
    if (!isTagPresent) {
      this.selectedTags.push(tag.id);
      this.tagList.forEach((x) => {
        if (x.id == tag.id) {
          x.isAdded = true;
        }
      });
    } else {
      this.selectedTags = this.selectedTags.filter((x) => x != tag.id);
      this.tagList.forEach((x) => {
        if (x.id == tag.id) {
          x.isAdded = false;
        }
      });
    }
  }

  addQuestion() {
    if (!this.title) {
      this.commonService.showToast('Please enter Question title', 'warn', 3000);
      return;
    }
    if (!this.content) {
      this.commonService.showToast(
        'Please enter Question Description ',
        'warn',
        3000
      );
      return;
    }
    const data = {
      isCategorList: true,
      name: {
        title: this.title,
        tags: this.selectedTags,
        content: this.content,
        countryShortCode: this.usOrUK,
      },
    };
    this.sharedApiService.saveRecord('questions', data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Question saved successfully',
          'success',
          1000
        );
        this.content = '';
        this.title = '';
        this.selectedTags = [];
        res.data = {
          ...res.data,
          answerCount: 0,
          isAddAnswerVisible: false,
          isCommentsOpened: false,
          isEditMode: false,
          Answers: [],
          User: {
            firstName: this.currentItems.firstName,
            lastName: this.currentItems.lastName,
            email: this.currentItems.email,
            country: this.currentItems.country,
          },
        };
        this.questionsList.unshift(res.data);
      },
      (error) => {
        this.commonService.showToast(
          error.error.error ?? 'Error while saving a question',
          'error',
          3000
        );
      }
    );
  }
  addQuestionSuperAdmin() {
    // Check if any of the required fields are empty
    if (!this.title) {
      this.commonService.showToast('Please enter Question title', 'warn', 3000);
      return;
    }
    if (!this.content) {
      this.commonService.showToast(
        'Please enter Question Description',
        'warn',
        3000
      );
      return;
    }
    if (!this.superAdminUSUK) {
      this.commonService.showToast('Please enter US/UK', 'warn', 3000);
      return;
    }
    if (!this.first_name) {
      this.commonService.showToast('Please enter First Name', 'warn', 3000);
      return;
    }
    if (!this.last_name) {
      this.commonService.showToast('Please enter Last Name', 'warn', 3000);
      return;
    }
    if (!this.country) {
      this.commonService.showToast('Please enter Country', 'warn', 3000);
      return;
    }
    if (!this.selectedTags || this.selectedTags.length === 0) {
      this.commonService.showToast(
        'Please select at least one tag',
        'warn',
        3000
      );
      return;
    }

    // If all fields are filled, prepare the data object
    const data = {
      isCategorList: true,
      name: {
        title: this.title,
        tags: this.selectedTags,
        content: this.content,
        countryShortCode: this.superAdminUSUK,
        firstName: this.first_name,
        lastName: this.last_name,
        country: this.country,
      },
    };

    // Send the data to the server
    this.sharedApiService.saveRecord('questions/dummy', data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Question saved successfully',
          'success',
          1000
        );
        // Reset form fields
        this.title = '';
        this.content = '';
        this.first_name = '';
        this.last_name = '';
        this.country = '';
        this.selectedTags = [];
        this.tagList.forEach((x) => {
          x.isAdded = false;
        });
        // Update local questions list with the new question
        this.paginationConfig.page = 1;
        this.questionsList = [];
        this.paginationConfig.countryShortCode = this.superAdminUSUK;
        this.selectedCountryFilterForAdmin = this.superAdminUSUK;
        this.getQuestionsList();
      },
      (error) => {
        this.commonService.showToast(
          error.error.error ?? 'Error while saving a question',
          'error',
          3000
        );
      }
    );
  }

  saveAnswer(question: ForumQuestion, index: number) {
    if (this.addAnswer[index] == null) {
      this.commonService.showToast('Please enter an answer', 'warn', 3000);
      return;
    }
    this.questionid[index] = question.id;
    const data = {
      name: {
        content: this.addAnswer[index],
        questionid: this.questionid[index],
      },
      isCategorList: true,
    };

    this.sharedApiService.saveRecord('questions/answer', data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Answer saved successfully',
          'success',
          1000
        );
        this.addAnswer[index] = null;
        this.questionid[index] = null;
        question.answerCount = question.answerCount + 1;
        if (!question.isCommentsOpened) {
          this.createData(res, index, true);
        }
        //
      },
      (error) => {
        this.commonService.showToast(
          error.error.error ?? 'Error while saving an answer',
          'success',
          1000
        );
      }
    );
  }
  saveAnswerSuperAdmin(question: ForumQuestion, index: number) {
    // Validate input fields
    if (!this.addAnswer[index]) {
      this.commonService.showToast('Please enter an answer', 'warn', 3000);
      return;
    }
    if (!this.first_name) {
      this.commonService.showToast('Please enter First Name', 'warn', 3000);
      return;
    }
    if (!this.last_name) {
      this.commonService.showToast('Please enter Last Name', 'warn', 3000);
      return;
    }
    if (!this.country) {
      this.commonService.showToast('Please enter Country', 'warn', 3000);
      return;
    }

    // Prepare data for submission
    this.questionid[index] = question.id;
    const data = {
      name: {
        content: this.addAnswer[index],
        questionid: this.questionid[index],
        firstName: this.first_name,
        lastName: this.last_name,
        country: this.country,
      },
      isCategorList: true,
    };

    // Submit answer
    this.sharedApiService.saveRecord('questions/answer/dummy', data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Answer saved successfully',
          'success',
          1000
        );
        this.addAnswer[index] = null; // Reset input field
        this.first_name = '';
        this.last_name = '';
        this.country = '';
        question.answerCount += 1; // Increment answer count
        if (!question.isCommentsOpened) {
          this.createData(res, index, true); // Update UI
        }
      },
      (error) => {
        this.commonService.showToast(
          error.error.error ?? 'Error while saving an answer',
          'error',
          3000
        );
      }
    );
  }

  toggleToEditAnswer(
    question: ForumQuestion,
    answer: Answer,
    index: number,
    kIndex: number
  ) {
    if (answer.isEditMode) {
      this.updateAnswer(question, answer, index, kIndex);
    } else {
      answer.isEditMode = true;
      this.editedAnswer[kIndex] = answer.content;
      if (!answer.isEditMode || !question.isCommentsOpened) {
        this.editedAnswer[index] = null;
      }
    }
  }

  deleteAnswer(question: ForumQuestion, answer: Answer, kindex: number) {
    this.sharedApiService
      .deleteRecord(`questions/answer/${answer.id}`)
      .subscribe(
        (res) => {
          question.Answers.splice(kindex, 1);
          question.answerCount = question.answerCount - 1;
          this.commonService.showToast(
            'Answer deleted successfully',
            'success',
            1000
          );
        },
        (error) => {
          this.commonService.showToast(
            error.error.error ?? 'Error while deleting an answer',
            'success',
            1000
          );
        }
      );
  }

  updateAnswer(
    question: ForumQuestion,
    answer: Answer,
    index: number,
    kIndex: number
  ) {
    this.questionid[index] = question.id;
    const data = {
      name: {
        content: this.editedAnswer[kIndex],
        questionid: this.questionid[index],
      },
      isCategorList: true,
    };
    this.sharedApiService
      .updateRecord(`questions/answer/${answer.id}`, data)
      .subscribe((res) => {
        answer.isEditMode = true;
        answer.replyCount = answer.Comments.length;
        this.commonService.showToast(
          'Answer updated successfully',
          'success',
          1000
        );
        this.createData(answer, index, false, kIndex);
      });
  }

  createData(res: any, index: number, isSave: boolean, kIndex?: number) {
    const savedAnswer = {
      ...res,
      Comments: [],
      isRepliesOpen: true,
      isEditMode: false,
      replyCount: res.replyCount,
      User: {
        firstName: this.currentItems.firstName,
        lastName: this.currentItems.lastName,
        email: this.currentItems.email,
        country: this.currentItems.country,
      },
    };
    if (isSave) {
      if (this.isAdmin) {
        this.questionsList[index].Answers.unshift(savedAnswer);
      } else {
        this.questionsList[index]['Answers'].push(savedAnswer);
      }
    } else {
      this.questionsList[index].Answers[kIndex] = savedAnswer;
    }
  }

  cancelEditAnswerMode(answer: Answer, index: number) {
    answer.isEditMode = false;
    this.editedAnswer[index] = null;
  }

  changeStatus(data, overlaypanel: OverlayPanel, question: ForumQuestion) {
    const record = {
      isCategorList: true,
      name: { status: data.option.status },
    };
    this.sharedApiService
      .updateRecord(`questions/${question.id}/status`, record)
      .subscribe(
        (res) => {
          this.commonService.showToast(
            ` Question ${data.option.name} successfully`,
            'success',
            1000
          );
        },
        (err) => {
          this.commonService.showToast('Something', 'success', 1000);
        }
      );
    overlaypanel.hide();
  }

  loadQuestionDetails(question: ForumQuestion) {
    const detailPageRoute: string = this.isAdmin
      ? 'admin'
      : `student/admission/${this.usOrUK}`;
    this.router.navigate([
      `${detailPageRoute}/question-details/${question.id}`,
    ]);
    localStorage.setItem('userCategory', JSON.stringify(this.isAdmin ? 1 : 2));
  }

  showAnswers(question: ForumQuestion) {
    question.isCommentsOpened = !question.isCommentsOpened;

    if (question.answerCount > 0) {
      this.sharedApiService
        .getListInForum(`questions/id/${question.id}`)
        .subscribe((res) => {
          question.Answers = res.data.Answers.splice(0, 3);
          question.Answers.forEach((y) => {
            y.isRepliesOpen = false;
            y.isEditMode = false;
            y.isAddReplyTextboxVisible = false;
            y.replyCount = y.Comments.length;
          });
        });
    }
  }

  showNestedComments(answer: Answer) {
    answer.isRepliesOpen = !answer.isRepliesOpen;
  }

  showAddAnswerTextBox(question: ForumQuestion) {
    question.isAddAnswerVisible = !question.isAddAnswerVisible;
  }

  showReplyTextBox(answer: Answer) {
    answer.isAddReplyTextboxVisible = !answer.isAddReplyTextboxVisible;
  }

  navigateToTags() {
    this.router.navigate([`admin/tag-list`]);
  }

  addReply(answer: Answer, index: number, question: ForumQuestion) {
    if (this.replies[index] == null || this.replies[index] == '') {
      this.commonService.showToast('Please enter a reply', 'warn', 3000);
      return;
    }
    answer.isRepliesOpen = true;
    answer.isAddReplyTextboxVisible = false;
    const record = {
      isCategorList: true,
      name: {
        answerid: answer.id,
        content: this.replies[index],
        questionid: question.id,
      },
    };
    this.sharedMethods.saveReply(
      answer,
      record,
      this.replies,
      index,
      this.currentItems
    );
  }
}
