import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { mainConstants } from 'src/app/shared/constants/main.constants';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
})
export class StudentProfileComponent implements OnInit {
  blockSpecial: RegExp = /^[^<>*!#%^&()+/:;|"{}]+$/;
  public steps = mainConstants.studentProfileTabs;
  currentTab: any;
  activeComponent: any;
  isStudentPortal: boolean;

  constructor(
    private commonService: CommonServiceService,
    public router: Router,
    private title: Title,
    private activatedRouter: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('admin/manage-students/student-details/')) {
      this.isStudentPortal = false;
    } else {
      this.isStudentPortal = true;
    }
    this.commonService.isSideMenuShown.next(false);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateActiveTabState();
      });

    this.router.events.subscribe((item) => {
      this.steps.forEach((x) => {
        const url = x.path.split('./')[1];
        if (!x['isChildren'] && !x['isChildren']?.length) {
          if (this.router.url.includes(url)) {
            this.title.setTitle('AlgoEd Student | ' + x['title']);
          }
        } else {
          x.isChildren.forEach((y) => {
            const childUrl = y.path.split('/')[1];
            if (this.router.url.includes(childUrl)) {
              this.title.setTitle('AlgoEd Student | ' + y['title']);
            }
          });
        }
      });
    });
    this.updateActiveTabState();

    this.activatedRouter.url.subscribe((res) => {});
  }

  updateActiveTabState(): void {
    const url = this.router.url;

    this.steps.forEach((step) => {
      // Here, adjust the logic based on how your URLs are structured
      // This is a basic example for direct matching; you might need more complex logic
      step.isActive = url.includes(step.path);
      if (step.isChildren) {
        step.isChildren.forEach((child) => {
          if (
            (step.path.includes('./')
              ? step.path.split('./')[1]
              : url.includes(step.path)) &&
            url.includes(step.path.split('./')[1])
          ) {
            step.isActive = true; // Set parent active if any child matches
          }
        });
      }
      const stepPath = step.path.split('./')[1];
      const isRouteMatch = url.includes(stepPath);

      if (isRouteMatch) {
        step.isActive = true;
      } else {
        step.isActive = false;
      }
    });
  }

  ngOnDestroy() {
    this.commonService.isSideMenuShown.next(true);
  }

  onActivate(e) {
    console.log('Router Component', e);
    this.activeComponent = e;
    const arr = [
      '/student/student-profile/standardized-test/sat-scores',
      '/student/student-profile/standardized-test/act-scores',
      '/student/student-profile/standardized-test/oxbridge-scores',
      '/student/student-profile/standardized-test/non-oxbridgess-scores',
    ];
    if (arr.includes(this.router.url)) {
      this.toggleOtherExpandedChild(this.steps[2]);
    }
  }

  goBackOrContinue(isBackOrContinue: string) {
    let route = '';

    // Find the current step among the parent steps
    const currentStep = this.steps.find((step) =>
      this.router.url.includes(step.path.split('./')[1])
    );
    this.currentTab = { ...currentStep };
    if (currentStep) {
      if (isBackOrContinue === 'continue') {
        if (
          !this.router.url.includes(
            'student/student-profile/personality-test'
          ) &&
          this.activeComponent &&
          this.activeComponent['studentProfileForm'] &&
          this.activeComponent['studentProfileForm'].invalid
        ) {
          this.commonService.showToast(
            'Please fill all the required fields',
            'error',
            3000,
            'Info'
          );
          return;
        }
        // Handle the 'continue' case
        if (currentStep.isChildren) {
          if (
            this.activeComponent['standardTest'] &&
            this.activeComponent['standardTest']['stFormComponent'] &&
            this.activeComponent['standardTest']['stFormComponent'][
              'studentProfileForm'
            ] &&
            this.activeComponent['standardTest']['stFormComponent'][
              'studentProfileForm'
            ].invalid
          ) {
            this.commonService.showToast(
              'Please fill all the required fields',
              'error',
              3000,
              'Info'
            );
            return;
          }
          const currentChildStep = currentStep.isChildren.find((child) =>
            this.router.url.includes(child.path)
          );

          if (currentChildStep) {
            route = currentChildStep.nextRouter;
          } else {
            route = currentStep.isChildren[0].path;
          }
        } else {
          route = currentStep.nextRouter;
        }
      } else if (isBackOrContinue === 'back') {
        if (currentStep.isChildren) {
          const currentChildStep = currentStep.isChildren.find((child) =>
            this.router.url.includes(child.path)
          );

          if (currentChildStep) {
            route = currentChildStep.backRouter;
          } else {
            route = currentStep.backRouter;
          }
        } else {
          route = currentStep.backRouter;
        }
      }
    }

    if (route) {
      this.router.navigate([`/student/student-profile${route}`]);
    }
    if (isBackOrContinue === 'continue') {
      this.commonService.callChildMethod.next(route);
    }
    if (this.router.url.includes('super-curricular-activities')) {
      this.router.navigate(['/student']);
    }
  }

  toggleOtherExpandedChild(step) {
    this.currentTab = { ...step };
    console.log(this.currentTab.id);
    this.steps.forEach((x) => {
      if (x.id != step.id) {
        x.isExpanded = false;
      } else {
        x.isExpanded = true;
      }
    });
  }
}
