<section class="login-container">
  <div class="card-container">
    <p-card>
      <div class="card-padding">
        <center>
          <img src="../../../assets/icons/logo-2.svg" alt="" />
        </center>
        <h3>{{ title }}</h3>
        <form class="d-flex flex-column gap-3" action="POST">
          <ng-container *ngIf="!isForgotPassword">
            <div class="p-input-icon-left">
              <i class="pi pi-user"></i>
              <input
                pInputText
                class="p-inputtext-lg"
                placeholder="Please Enter an Email"
                [(ngModel)]="loginUsername"
                [disabled]="isResetPassPage"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="p-input-icon-left p-input-icon-right">
              <i class="pi pi-lock"></i>
              <input
                [type]="showPass ? 'text' : 'password'"
                pInputText
                class="p-inputtext-lg"
                placeholder="Password"
                [(ngModel)]="loginPassword"
                [ngModelOptions]="{ standalone: true }"
              />
              <i
                class="pi"
                [ngClass]="{ 'pi-eye': !showPass, 'pi-eye-slash': showPass }"
                (click)="showPassFn()"
              ></i>
            </div>
            <div class="p-input-icon-left p-input-icon-right" *ngIf="isResetPassPage">
              <i class="pi pi-lock"></i>
              <input
                [type]="!showPass ? 'text' : 'password'"
                pInputText
                class="p-inputtext-lg"
                placeholder="Confirm Password"
                [(ngModel)]="confirmloginPassword"
                [ngModelOptions]="{ standalone: true }"
              />
              <i
                class="pi"
                [ngClass]="{ 'pi-eye': !showPass, 'pi-eye-slash': showPass }"
                (click)="showPassFn()"
              ></i>
            </div>
            
          </ng-container>
          <ng-container *ngIf="isForgotPassword">
            <div class="p-input-icon-left">
              <i class="pi pi-user"></i>
              <input
                pInputText
                class="p-inputtext-lg"
                placeholder="Please Enter an Email"
                [(ngModel)]="forgotUserName"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </ng-container>

          <div
            class="d-flex justify-content-end text-primary cursor-pointer"
            *ngIf="this.loginId !== 1 && !isForgotPassword && !isResetPassPage"
            (click)="isForgotPassword = !isForgotPassword"
          >
            <small> Forgotten password? </small>
          </div>
          <!--<div class="alert alert-danger" *ngIf="loginErr">{{loginErr}}</div>-->
          <div
            class="d-flex justify-content-end text-primary cursor-pointer"
            *ngIf="this.loginId !== 1 && isForgotPassword"
            (click)="isForgotPassword = !isForgotPassword"
          >
            <small> Login </small>
          </div>
          <button
            *ngIf="!isForgotPassword"
            pButton
            pRipple
            type="submit"
            [label]="isResetPassPage ? 'Set Password' : 'Login'"
            class="p-button-raised display-block p-button-lg my-0"
            [disabled]="!loginUsername || !loginPassword || submitted"
            (click)="adminLogin()"
          ></button>
          <button
            *ngIf="this.loginId !== 1 && isForgotPassword"
            pButton
            pRipple
            type="submit"
            label="Submit"
            class="p-button-raised display-block p-button-lg my-0"
            [disabled]="!loginUsername"
            (click)="forgotPassword()"
          ></button>
        </form>
      </div>
    </p-card>
  </div>
</section>
<!-- <p-toast></p-toast> -->
