<div class="d-flex flex-column h-100 overflow-auto">
  <div class="personal-information-container">
    <h1>Change Password</h1>
    <p>{{ currentDate | date : "hh:mm a, d MMM YYY" }}</p>
    <p-card class="d-block w-100">
      <form action="POST">
        <div class="grid-template-container">
          <div class="input-group">
            <label for="Current Password" class="block">Current Password</label>
            <input
              id="Current Password"
              [(ngModel)]="oldPass"
              [ngModelOptions]="{ standalone: true }"
              type="username"
              aria-describedby="Current Password-help"
              class="p-inputtext-lg"
              pInputText
            />
          </div>
          <div class="input-group">
            <label for="New Password" class="block">New Password</label>
            <input
              id="New Password"
              [(ngModel)]="newPass"
              [ngModelOptions]="{ standalone: true }"
              type="username"
              aria-describedby="New Password-help"
              class="p-inputtext-lg"
              pInputText
            />
          </div>
          <div class="input-group">
            <label for="Confirm Password" class="block">Confirm Password</label>
            <input
              id="Confirm Password"
              [(ngModel)]="confirmPass"
              [ngModelOptions]="{ standalone: true }"
              type="username"
              aria-describedby="Confirm Password-help"
              class="p-inputtext-lg"
              pInputText
            />
          </div>
        </div>

        <div class="center-buttons">
          <button
            pButton
            pRipple
            type="submit"
            label="Update Password"
            [disabled]="
              newPass !== confirmPass || !oldPass || !newPass || !confirmPass
            "
            (click)="onSubmit()"
            class="p-primary p-button-lg full-width"
          ></button>
        </div>
      </form>
    </p-card>
  </div>
</div>
