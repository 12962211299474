<div class="payment_modal">
  <div
    class="flex-without-justify gap20 align-center payment_logo mt-3"
    (click)="closeDialog()"
  >
    <i class="pi pi-arrow-left"></i>
    <img alt="logo" src="../../assets/icons/logo-2.svg" />
  </div>

  <div class="purchase-payment-container">
    <div
      class="border rounded p-3 h-100 overflow-hidden relative-box d-flex flex-column overflow-hidden h-100"
    >
      <ng-container *ngIf="competitionDetails">
        <h3>
          {{ competitionDetails?.name }}
          {{ "- " + competitionDetails?.registration_stage }} Registration
        </h3>
      </ng-container>
      <form
        class="h-100 overflow-hidden"
        (ngSubmit)="confirmPurchase()"
        novalidate
      >
        <ng-container *ngIf="clientSecretID && publicKey">
          <div class="d-flex h-100 overflow-hidden">
            <div id="card-element" class="flex-grow-1 overflow-auto"></div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>
