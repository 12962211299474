export const SHARE_ICONS = [
  {
    src: '../../../../assets/share-icons/fb.png',
    alt: 'Facebook',
    content: 'Share on Facebook',
    id: 1,
  },
  {
    src: '../../../../assets/share-icons/twitter.png',
    alt: 'Twitter',
    content: 'Share on X',
    id: 2,
  },
  {
    src: '../../../../assets/share-icons/linkedin.png',
    alt: 'LinkedIn',
    content: 'Share on Linkedin',
    id: 3,
  },
  {
    src: '../../../../assets/share-icons/whatsapp.png',
    alt: 'WhatsApp',
    content: 'Share via whatsapp',
    id: 4,
  },
  {
    src: '../../../../assets/share-icons/snapchat.png',
    alt: 'Snapchat',
    content: 'Share on snapchat',
    id: 5,
  },
  {
    src: '../../../../assets/share-icons/gmail.png',
    alt: 'Gmail',
    content: 'Share via Email',
    id: 6,
  },
];
