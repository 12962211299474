import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../../common-service.service';
import { SHARE_ICONS } from '../../constants/share-icons.constants';
import { ShareButtonClickService } from '../../services/share-button-click.service';

@Component({
  selector: 'app-certificate-share-card',
  templateUrl: './certificate-share-card.component.html',
  styleUrls: ['./certificate-share-card.component.scss'],
})
export class CertificateShareCardComponent {
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  @Input() isSharePageOpen = false;
  @Input() isLoggedInUser;
  @Output() closeShareModal = new EventEmitter<boolean>();
  @Output() linkedProfile: EventEmitter<any> = new EventEmitter<any>();

  public shareIcons = SHARE_ICONS;
  public deviceType: string;
  public isMobileView: boolean;

  constructor(
    private shareButtonClickService: ShareButtonClickService,
    private router: Router,
    private commonService: CommonServiceService
  ) {}

  ngOnInit(): void {
    this.checkDeviceType();
  }

  handleButtonClick(buttonId: number): void {
    const fullUrl = this.setEncryptedUrl();
    this.shareButtonClickService.handleButtonClick(buttonId, fullUrl);
  }

  closeSharePage(event): void {
    this.isSharePageOpen = false;
  }

  openSharePage(event: any) {
    const fullUrl = this.setEncryptedUrl();
    if (navigator.share) {
      navigator
        .share({
          title: 'AlgoEd | Awards & Certifications',
          text: 'AlgoEd is a platform that hosts curated, prestigious competitions for middle and high school students.',
          url: `${fullUrl}`,
        })
        .catch((err) => {
          console.error('Error sharing:', err);
        });
    } else {
      this.isSharePageOpen = !this.isSharePageOpen;
    }
  }

  addToLinkedProfile() {
    this.linkedProfile.emit();
  }

  setEncryptedUrl(): string {
    const baseUrl = environment.openShareLink.endsWith('/')
      ? environment.openShareLink.slice(0, -1)
      : environment.openShareLink;

    const routerUrl = this.router.url.startsWith('/')
      ? this.router.url.slice(1)
      : this.router.url;

    return `${baseUrl}/${routerUrl}`;
  }

  copyToClipboard() {
    const fullUrl = this.setEncryptedUrl();

    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        this.commonService.showToast('Link Copied', 'success', 1000);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  }
}
