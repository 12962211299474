import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CompetitionAwardData } from '../models/competition.interface';
import html2pdf from 'html2pdf.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-certificate-canvas',
  templateUrl: './certificate-canvas.component.html',
  styleUrls: ['./certificate-canvas.component.scss'],
})
export class CertificateCanvasComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  @ViewChild('certificateCanvas', { static: false })
  canvasRef!: ElementRef<HTMLCanvasElement>;

  @Input() competitionAwardData: CompetitionAwardData;

  private ctx!: CanvasRenderingContext2D;
  private backgroundImg = new Image();
  private algoedLogo = new Image();
  private pennsemLogo = new Image();
  private awardBadgeImg = new Image();
  private goldenLineImg = new Image();
  public imageBaseURL: string = environment.apiUrl;
  public deviceType: string;
  public isMobileView: boolean;

  constructor() {}


  ngOnInit(): void {
    this.checkDeviceType();
    this.competitionAwardData;
  }

  ngAfterViewInit(): void {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    this.backgroundImg.src =
      '../../../assets/images/certifications/canvas-background.png';
    this.algoedLogo.src =
      '../../../assets/images/certifications/algoed-logo.png';
    this.pennsemLogo.src =
      '../../../assets/images/certifications/pennsem-logo.png';
    this.goldenLineImg.src =
      '../../../assets/images/certifications/golden-line.png';

    // Award Badge
    const getAwardBadgeImage = this.competitionAwardData.images.find(
      (image) => {
        return image.type === 'certificate';
      }
    ).image;
    this.awardBadgeImg.src = getAwardBadgeImage
      ? this.imageBaseURL + 'common/getfile/' + getAwardBadgeImage
      : null;
    this.awardBadgeImg.crossOrigin = 'Anonymous';

    // Ensure all images are loaded before drawing
    let imagesLoaded = 0;
    const checkIfAllImagesAreLoaded = () => {
      imagesLoaded++;
      if (imagesLoaded === 5 && this.competitionAwardData) {
        this.drawCertificate();
      }
    };

    this.backgroundImg.onload = checkIfAllImagesAreLoaded;
    this.algoedLogo.onload = checkIfAllImagesAreLoaded;
    this.pennsemLogo.onload = checkIfAllImagesAreLoaded;
    this.awardBadgeImg.onload = checkIfAllImagesAreLoaded;
    this.goldenLineImg.onload = checkIfAllImagesAreLoaded;
  }

  downloadPDFWithoutLibrary() {
    const canvasElement = this.canvasRef.nativeElement;
    const mobileViewCanvasHeight = this.isMobileView ? 148 : 160;
    // PDF options
    const options = {
      margin: 0,
      filename: `${this.competitionAwardData.team_member.name}_certificate.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 1,
        useCORS: true,
        allowTaint: false,
      },
      jsPDF: {
        unit: 'px',
        format: [canvasElement.width - 250, canvasElement.height - mobileViewCanvasHeight],
        orientation: 'landscape',
      },
    };

    // Convert the canvas to a PDF
    html2pdf()
      .from(canvasElement)
      .set(options)
      .save()
      .catch((error) => console.error('Error generating PDF: ', error));
  }

  downloadImage() {
    const canvas = this.canvasRef.nativeElement;
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/jpg'); // Get image as png
    link.download = `${this.competitionAwardData.team_member.name}_certificate.jpg`;
    link.click();
  }

  private drawCertificate() {
    const canvas = this.canvasRef.nativeElement;
    const ctx = this.ctx;

    // Draw background image
    ctx.drawImage(this.backgroundImg, 0, 0, canvas.width, canvas.height);

    // Content area setup
    const contentWidth = canvas.width * 0.5;
    const contentX = 50;
    const contentCenterX = contentX + contentWidth / 1.75;

    // Position the badge on the right side
    const badgeWidth = 220;
    const badgeHeight = 300;
    const badgeX = canvas.width - badgeWidth - 15;
    const badgeY = canvas.height - badgeHeight - 125;
    ctx.drawImage(this.awardBadgeImg, badgeX, badgeY, badgeWidth, badgeHeight);

    // Draw logos
    const logoY = 50;
    const algoedLogoWidth = 100;
    const pennsemLogoWidth = 160;
    const pennsemLogoHeight = 40;
    const algoedLogoHeight = 45;
    const logoSpacing = 20;
    const totalLogosWidth = algoedLogoWidth + pennsemLogoWidth + logoSpacing;
    const logosStartX = contentCenterX - totalLogosWidth / 2;

    ctx.drawImage(
      this.pennsemLogo,
      logosStartX,
      logoY,
      pennsemLogoWidth,
      pennsemLogoHeight
    );
    ctx.drawImage(
      this.algoedLogo,
      logosStartX + pennsemLogoWidth + logoSpacing,
      logoY,
      algoedLogoWidth,
      algoedLogoHeight
    );

    // Main content starting point
    const textYStart = 160;

    // Certifies text
    ctx.font = '18px Helvetica';
    let text = 'This certifies that'.toUpperCase();
    let textWidth = ctx.measureText(text).width;
    ctx.fillStyle = '#000';
    ctx.fillText(text, contentCenterX - textWidth / 2, textYStart);

    // Name
    ctx.font = '56px GoodVibrations';
    ctx.fillStyle = '#b88a28';
    const fullName = this.competitionAwardData?.team_member.name;
    text = fullName
      .split(' ')
      .map(
        (word) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase()
      )
      .join(' ');

    textWidth = ctx.measureText(text).width;
    ctx.fillText(text, contentCenterX - textWidth / 2, textYStart + 75);

    // Line below name
    const lineWidth = 480;
    ctx.beginPath();
    ctx.moveTo(contentCenterX - lineWidth / 2, textYStart + 112);
    ctx.lineTo(contentCenterX + lineWidth / 2, textYStart + 112);
    ctx.strokeStyle = '#222222';
    ctx.lineWidth = 2;
    ctx.stroke();

    // Split school name into two lines if necessary
    ctx.fillStyle = '#004785';
    ctx.font = '20px Helvetica';
    let schoolName = this.competitionAwardData?.team?.school ?? '';
    const words = schoolName.split(' ');

    let firstLine = '';
    let secondLine = '';

    if (words.length > 3) {
      firstLine = words.slice(0, 3).join(' '); // First three words in the first line
      secondLine = words.slice(3).join(' '); // Rest of the words in the second line
    } else {
      firstLine = schoolName; // If it's three words or less, it's one line
    }

    // Draw the first line of the school name
    let firstLineWidth = ctx.measureText(firstLine.toUpperCase()).width;
    ctx.fillText(
      firstLine.toUpperCase(),
      contentCenterX - firstLineWidth / 2,
      textYStart + 160
    );

    // If there's a second line, draw it and shift subsequent content downwards
    let additionalSpacing = 0;
    if (secondLine) {
      let secondLineWidth = ctx.measureText(secondLine.toUpperCase()).width;
      ctx.fillText(
        secondLine.toUpperCase(),
        contentCenterX - secondLineWidth / 2,
        textYStart + 190
      );
      additionalSpacing = 40; // Shift content down by 40px to accommodate the second line
    }

    // "Has won" text on one line
    const fontSize = this.isMobileView ? '18px' : '22px';
    
    ctx.font = `${fontSize} Helvetica`;
    ctx.fillStyle = '#16161B';
    const hasWonText = 'Has won'.toUpperCase();
    const hasWonTextWidth = ctx.measureText(hasWonText).width;

    ctx.fillText(
      hasWonText,
      contentCenterX - hasWonTextWidth / 2,
      textYStart + 200 + additionalSpacing
    );

    // Draw "Champion" text and "PlaceName" text beside it
    ctx.font = `bold ${fontSize} Helvetica`;
    ctx.fillStyle = '#BD8E33';
    const championText =
      this.competitionAwardData?.competition_award?.award_title?.toUpperCase() ??
      '';
    const championTextWidth = ctx.measureText(championText).width;

    // Define the placeName text (you need to ensure placeName is available in the data)
    const getPlaceName =
      this.competitionAwardData?.award_details?.award_category == 'country'
        ? this.competitionAwardData?.country?.country
        : this.competitionAwardData?.award_details?.award_category == 'regional'
        ? this.competitionAwardData?.region?.name
        : '';
    const placeName = getPlaceName ? '-' + ' ' + getPlaceName : '';

    const placeNameWidth = ctx.measureText(placeName).width;

    // Calculate the total width of the combined "Champion" and "Place Name" text
    const totalTextWidth = championTextWidth + placeNameWidth + 8; // 8px gap between ChampionText and PlaceName

    // Calculate the starting X position so that both texts are left-aligned relative to the content center
    const championTextX = contentCenterX - totalTextWidth / 2;
    const placeNameX = championTextX + championTextWidth + 8; // Position placeName beside championText

    // Draw "Champion" text
    ctx.fillText(
      championText,
      championTextX,
      textYStart + 250 + additionalSpacing
    );

    // Draw "PlaceName" text beside "Champion"
    ctx.font = `bold ${fontSize} Helvetica`; // Adjust the font for PlaceName if needed
    ctx.fillStyle = '#BD8E33';
    ctx.fillText(placeName, placeNameX, textYStart + 250 + additionalSpacing);

    // "IN" text below Has won and Champion
    ctx.font = `${fontSize} Helvetica`;
    ctx.fillStyle = '#000';
    const inText = 'IN';
    const inTextWidth = ctx.measureText(inText).width;
    ctx.fillText(
      inText,
      contentCenterX - inTextWidth / 2 - 10,
      textYStart + 295 + additionalSpacing
    );

    // PennSEM Business Case Competition with line break
    ctx.font = '600 34px Playfair Display';
    ctx.fillStyle = '#AC002F';
    const splitTextCompetitionName: any =
      this.competitionAwardData?.competition?.name.split(' ') ?? '';
    const splitTextCompetitionNameLength = splitTextCompetitionName.length;
    let businessText =
      splitTextCompetitionNameLength > 1
        ? splitTextCompetitionName[0] + ' ' + splitTextCompetitionName[1]
        : splitTextCompetitionName[0];
    let businessTextWidth = ctx.measureText(businessText).width;
    ctx.fillText(
      businessText,
      contentCenterX - businessTextWidth / 2,
      textYStart + 350 + additionalSpacing
    );

    let competitionText =
      splitTextCompetitionNameLength > 2
        ? splitTextCompetitionName.slice(2).join(' ')
        : '';
    let competitionTextWidth = ctx.measureText(competitionText).width;
    ctx.fillText(
      competitionText,
      contentCenterX - competitionTextWidth / 2,
      textYStart + 400 + additionalSpacing
    );

    // Golden-line image before the date
    const goldenLineWidth = 250;
    const goldenLineHeight = 1;
    const goldenLineX = contentCenterX - goldenLineWidth / 2; // Center the line
    const goldenLineY = textYStart + 420 + additionalSpacing; // Position above the date

    ctx.drawImage(
      this.goldenLineImg,
      goldenLineX,
      goldenLineY,
      goldenLineWidth,
      goldenLineHeight
    );

    // Date
    ctx.font = '400 16px Helvetica';
    ctx.fillStyle = '#5C5B5C';
    const createdAt = this.competitionAwardData?.award?.createdAt;
    let formattedDate = '';
    if (createdAt) {
      const date = new Date(createdAt);
      formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    }

    text = `Date: ${formattedDate}`?.toUpperCase() ?? '';
    textWidth = ctx.measureText(text).width;
    ctx.fillText(
      text,
      contentCenterX - textWidth / 2,
      textYStart + 450 + additionalSpacing
    );
  }

  capitalizeFullName(name) {
    return name
      .split(' ')
      .map((word) => {
        if (word.length === 0) return word;
        return word.charAt(0).toUpperCase() + word.slice(1).toUpperCase();
      })
      .join(' ');
  }
}
