import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
  selector: 'app-view-award-list',
  templateUrl: './view-award-list.component.html',
  styleUrls: ['./view-award-list.component.scss'],
})
export class ViewAwardListComponent {
  public awardList: any = {};
  public previousRounds: any;

  constructor(
    private commonService: CommonServiceService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router
  ) {}

  ngOnInit() {
    this.previousRounds = this.config.data?.previousRounds;

    if (
      this.config.data?.previousRounds &&
      this.config.data?.previousRounds?.length > 0
    ) {
      {
        for (let i = 0; i < this.config.data?.previousRounds?.length; i++) {
          this.awardList[this.config.data?.previousRounds[i].round_number] =
            null;
          this.getAwardsDetails(this.config.data?.previousRounds[i]);
        }
      }
    }
  }

  getAwardsDetails(round) {
    this.commonService
      .getList(
        `users/competitions/awards/${this.config?.data?.competitionData?.id}/${round.round_number}`
      )
      .subscribe(
        (res) => {
          this.awardList[round.round_number] = res && res.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  viewAward(award: any) {
    const userData =
      localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    const teamAwardId = award.team_award_id;

    if (userData) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/certification'], {
          queryParams: { team_award_id: teamAwardId, user_id: userData.id },
        })
      );

      window.open(url, '_blank');
    }
  }
}
