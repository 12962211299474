import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDate',
})
export class SortByDatePipe implements PipeTransform {
  transform(items: any[], field: string): any[] {
    if (!items) return [];
    if (!field) return items;

    items.sort((a, b) => {
      const date1 = new Date(a[field]);
      const date2 = new Date(b[field]);

      return date1.getTime() - date2.getTime();
    });

    return items;
  }
}
