import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { DragDropModule } from 'primeng/dragdrop';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonStylesModule } from './common-styles/common-styles.module';
import { JwtService } from './shared/jwt.service';
import { SharedModule } from './shared/shared.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
// import { EssaysComponent } from './website/essays/essays.component';

import { NgOptimizedImage } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { CertificateAwardComponent } from './certificate-award/certificate-award.component';
import { CertificateCanvasComponent } from './certificate-award/certificate-canvas/certificate-canvas.component';
import { CertificateFooterComponent } from './certificate-award/certificate-footer/certificate-footer.component';
import { DetectLoaderInterceptor } from './detect-loader.interceptor';
import { ExploreCompetitionsComponent } from './explore-competitions/explore-competitions.component';
import { FinalSubmissionDialogComponent } from './final-submission-dialog/final-submission-dialog.component';
import { NgxEditorModule } from 'ngx-editor';
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ExploreCompetitionsComponent,
    FinalSubmissionDialogComponent,
    CertificateAwardComponent,
    CertificateFooterComponent,
    CertificateCanvasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    CommonStylesModule,
    BrowserAnimationsModule,
    ToastModule,
    DynamicDialogModule,
    DragDropModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    NgOptimizedImage
  ],
  providers: [
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DetectLoaderInterceptor,
      multi: true,
    },
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
