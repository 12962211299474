<div class="d-flex flex-column h-100 gap-3">
  <div class="card d-none d-lg-block">
    <div class="card-body">
      <div>
        <h3 class="m-0 page-card-title">Create Groups</h3>
      </div>
      <form
        class="d-flex flex-column gap-3 mt-3"
        [formGroup]="groupForm"
        (ngSubmit)="createGroup()"
      >
        <!-- Enter group name -->
        <div class="w-100">
          <input
            type="text"
            pInputText
            placeholder="Enter group name"
            formControlName="name"
          />
        </div>
        <!-- End: Enter group name -->

        <!-- select country -->
        <div class="w-100">
          <p-dropdown
            class="w-100 full-width"
            placeholder="Select a Country"
            [filter]="true"
            [options]="['US', 'UK']"
            formControlName="countryShortCode"
            [appendTo]="'body'"
          >
          </p-dropdown>
          <!-- End: select country -->
        </div>
        <div>
          <p-button type="submit" class="custom-ternary text-nowrap">
            <div class="d-flex align-items-center">
              <span class="icon icon-rounded-plus me-2"></span>
              <span>Create Group</span>
            </div>
          </p-button>
        </div>
      </form>
    </div>
  </div>

  <div class="card flex-grow-1 overflow-hidden">
    <div class="card-body d-flex flex-column overflow-hidden">
      <div
        class="d-flex justify-content-between align-items-center gap-3 flex-wrap"
      >
        <h3 class="m-0 page-card-title">Select Group</h3>
        <div class="d-lg-none">
          <p-button
            class="custom-ternary text-nowrap"
            (click)="openDialogForGroup()"
          >
            <div class="d-flex align-items-center">
              <span class="icon icon-rounded-plus me-2"></span>
              <span>Create Group</span>
            </div>
          </p-button>
        </div>
      </div>
      <div class="d-flex flex-column h-100 overflow-hidden gap-3 mt-3">
        <div class="w-100 px-2 py-1">
          <app-search
            #searchComponent
            [searchMaxWidth]="'initial'"
            [searchFunction]="getGroupListBySearch.bind(this)"
            (searchResults)="handleSearchResults($event)"
          ></app-search>
        </div>
        <ng-container *ngIf="!groupsList || groupsList.length === 0">
          <div class="text-center">
            <p class="text-danger">No groups found</p>
          </div>
        </ng-container>
        <ul class="group-list my-0 flex-grow-1 overflow-auto">
          <li
            class="group-item d-flex align-items-center flex-nowrap gap-3"
            [ngClass]="{ 'selected-group': group.id === highlightedGroup }"
            *ngFor="let group of groupsList"
          >
            <div class="group-icon flex-shrink-0">
              {{ group?.name.charAt(0) | uppercase }}
            </div>
            <span class="flex-grow-1" [title]="group.name | titlecase"
              >{{ group?.name | titlecase }} ({{
                group?.countryShortCode
              }})</span
            >
            <span *ngIf="group.id !== highlightedGroup">
              <p-button
                size="small"
                (click)="viewGroupDetails(group)"
                [outlined]="true"
                class="custom-ternary text-nowrap"
                label="View Details"
              >
              </p-button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="Header"
  [(visible)]="groupDialogVisible"
  [modal]="true"
  [style]="{ width: '90vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <form
    class="d-flex flex-column gap-3 mt-3"
    [formGroup]="groupForm"
    (ngSubmit)="createGroup()"
  >
    <!-- Enter group name -->
    <div class="w-100">
      <input
        type="text"
        pInputText
        placeholder="Enter group name"
        formControlName="name"
      />
    </div>
    <!-- End: Enter group name -->

    <!-- select country -->
    <div class="w-100">
      <p-dropdown
        class="w-100 full-width"
        placeholder="Select a Country"
        [filter]="true"
        [options]="['US', 'UK']"
        formControlName="countryShortCode"
        [appendTo]="'body'"
      >
      </p-dropdown>
      <!-- End: select country -->
    </div>
    <div>
      <p-button type="submit" class="custom-ternary text-nowrap">
        <div class="d-flex align-items-center">
          <span class="icon icon-rounded-plus me-2"></span>
          <span>Create Group</span>
        </div>
      </p-button>
    </div>
  </form>
</p-dialog>
