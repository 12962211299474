<div class="media-details-container flex-grow-1 h-100 overflow-hidden">
  <div class="container-lg px-0 d-flex flex-column gap-3 h-100">
    <div class="row px-3">
      <div class="col-md-3 col-6 mb-0 d-flex align-items-center">
        <p-button
          *ngIf="deviceType !== 'Mobile'"
          class="back-btn"
          [rounded]="true"
          [outlined]="true"
          severity="secondary"
          (click)="navigateToBack()"
        >
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.052 3.78349L9.99313 0.724609C9.74074 0.472216 9.48473 0.344238 9.23225 0.344238C8.88559 0.344238 8.4808 0.607901 8.4808 1.35101V2.39239C6.25844 2.48925 4.18305 3.40067 2.60012 4.98351C0.923554 6.65998 0.000127838 8.88897 -3.05176e-05 11.2599C-2.68808e-05 11.343 0.0261004 11.4239 0.074653 11.4913C0.123206 11.5587 0.191724 11.6091 0.270509 11.6354C0.349295 11.6617 0.434356 11.6626 0.513653 11.6379C0.59295 11.6132 0.662466 11.5641 0.71236 11.4977C2.58146 9.00979 5.39545 7.53948 8.4808 7.42341V8.44876C8.4808 9.19182 8.88559 9.45554 9.23223 9.45554H9.23231C9.48475 9.45554 9.74076 9.32756 9.99313 9.07519L13.052 6.01626C13.3493 5.71905 13.513 5.32258 13.513 4.89988C13.513 4.47725 13.3493 4.08075 13.052 3.78349Z"
              fill="#A4A4A4"
            />
          </svg>
          <span class="ms-2">Back</span>
        </p-button>

        <button
          class="btn btn-back-sm btn-transparent d-flex align-items-center shadow-none p-1 gap-3"
          (click)="navigateToBack()"
          *ngIf="deviceType == 'Mobile'"
        >
          <svg
            width="17"
            height="12"
            viewBox="0 0 17 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.08099 11.253C6.40599 10.928 6.40599 10.403 6.08099 10.078L2.85599 6.83633H15.4977C15.956 6.83633 16.331 6.46133 16.331 6.003C16.331 5.54466 15.956 5.16966 15.4977 5.16966H2.85599L6.08932 1.93633C6.41432 1.61133 6.41432 1.08633 6.08932 0.761328C5.76432 0.436328 5.23932 0.436328 4.91432 0.761328L0.247656 5.41966C-0.0773437 5.74466 -0.0773437 6.26966 0.247656 6.59466L4.90599 11.253C5.23099 11.5697 5.76432 11.5697 6.08099 11.253Z"
              fill="#595959"
            />
          </svg>
          <span class="btn-text">Go back</span>
        </button>
      </div>
      <div class="search-mobile-wrapper col-md-9 col-6">
        <app-search
          class="w-100 media-search"
          [searchMaxWidth]="'700px'"
          [placeholder]="'Search Questions'"
          [searchFunction]="onSearch.bind(this)"
          (searchResults)="handleSearchResults($event)"
        ></app-search>
      </div>
    </div>
    <div
      class="video-detail flex-column flex-lg-row h-100 overflow-hidden"
      [ngStyle]="{ 'margin-bottom': !isUserLoggedIn ? '200px' : '0' }"
    >
      <div class="video-player-container flex-grow-1">
        <!-- Video Player -->
        <div class="px-0 px-lg-3" *ngIf="!islessonType">
          <div class="video-player">
            <ng-container *ngIf="videoDetail?.channel == 2">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item"
                  [src]="videoDetail.videoUrl | safeUrl"
                  width="100%"
                  allowfullscreen
                ></iframe>
              </div>
            </ng-container>
            <ng-container *ngIf="videoDetail?.channel == 3 && !videoLoader">
              <div [innerHtml]="videoForLoad"></div>
            </ng-container>
            <ng-container *ngIf="videoDetail?.channel == 1">
              <div class="youtube-video-wrapper">
                <video
                  class="embed-responsive-item"
                  width="100%"
                  controls
                  oncontextmenu="return false;"
                  [controlsList]="'nodownload'"
                >
                  <source [src]="localS3URL" type="video/mp4" />
                </video>
              </div>
            </ng-container>
            <div
              *ngIf="videoLoader"
              class="d-flex justify-content-center align-items-center loader-container"
            >
              <p-progressSpinner
                styleClass="w-2rem h-2rem"
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              ></p-progressSpinner>
            </div>
          </div>
        </div>
        <!-- End: Video Player -->

        <!-- videoNotes  -->
        <div *ngIf="videoNotes && islessonType">
          <ngx-doc-viewer
            [url]="baseURL"
            [viewer]="viewer"
            style="width: 100%; height: 65vh"
          ></ngx-doc-viewer>
        </div>
        <!-- End: videoNotes  -->

        <!-- video description -->
        <div
          class="d-flex align-items-center mt-3 justify-content-between gap-3 px-3"
        >
          <div>
            <h4 class="video-title my-0">
              {{ videoDetail?.title }}
            </h4>
          </div>
          <div>
            <p-button
              [rounded]="true"
              [outlined]="true"
              severity="secondary"
              (click)="share()"
              *ngIf="deviceType !== 'Mobile'"
            >
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.052 3.78349L9.99313 0.724609C9.74074 0.472216 9.48473 0.344238 9.23225 0.344238C8.88559 0.344238 8.4808 0.607901 8.4808 1.35101V2.39239C6.25844 2.48925 4.18305 3.40067 2.60012 4.98351C0.923554 6.65998 0.000127838 8.88897 -3.05176e-05 11.2599C-2.68808e-05 11.343 0.0261004 11.4239 0.074653 11.4913C0.123206 11.5587 0.191724 11.6091 0.270509 11.6354C0.349295 11.6617 0.434356 11.6626 0.513653 11.6379C0.59295 11.6132 0.662466 11.5641 0.71236 11.4977C2.58146 9.00979 5.39545 7.53948 8.4808 7.42341V8.44876C8.4808 9.19182 8.88559 9.45554 9.23223 9.45554H9.23231C9.48475 9.45554 9.74076 9.32756 9.99313 9.07519L13.052 6.01626C13.3493 5.71905 13.513 5.32258 13.513 4.89988C13.513 4.47725 13.3493 4.08075 13.052 3.78349Z"
                  fill="#A4A4A4"
                />
              </svg>
              <span class="share-btn-text ms-2 cursor-pointer">Share</span>
            </p-button>

            <button
              class="btn btn-transparent d-flex shadow-none"
              (click)="share()"
              *ngIf="deviceType == 'Mobile'"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3859 6.04755L13.3114 1.97298C12.9752 1.63678 12.6341 1.46631 12.2978 1.46631C11.8361 1.46631 11.2969 1.81752 11.2969 2.80738V4.19454C8.33657 4.32356 5.57205 5.53761 3.46352 7.64604C1.23026 9.87916 0.000210938 12.8483 0 16.0065C4.84435e-06 16.1172 0.0348076 16.225 0.0994819 16.3148C0.164156 16.4045 0.255426 16.4717 0.360372 16.5067C0.465318 16.5417 0.578623 16.5429 0.684251 16.5099C0.789878 16.477 0.882476 16.4117 0.948938 16.3233C3.43867 13.0092 7.18703 11.0507 11.2969 10.8961V12.2619C11.2969 13.2517 11.8361 13.603 12.2978 13.603H12.2979C12.6342 13.603 12.9752 13.4325 13.3114 13.0963L17.3859 9.0217C17.7819 8.62581 18 8.09769 18 7.53463C18 6.97167 17.7819 6.44352 17.3859 6.04755Z"
                  fill="#1D1D1D"
                />
              </svg>
            </button>
          </div>
        </div>
        <!-- End: video description -->

        <!-- Notes & Quiz cards -->
        <div
          class="notes-quiz-card mt-4 px-3"
          *ngIf="isQuizAvailable || (!islessonType && videoDetail?.notes)"
        >
          <p-card
            class="flex-grow-1"
            *ngIf="!islessonType && videoDetail?.notes"
          >
            <div
              class="d-flex flex-column align-items-center align-items-xl-start gap-2"
            >
              <div class="flex-shrink-0">
                <h6 class="card-title my-0">Notes</h6>
              </div>

              <div
                class="d-flex flex-column-reverse justify-content-center flex-wrap justify-content-xl-between flex-xl-row w-100"
              >
                <div class="flex-shrink-0 text-center">
                  <button
                    pButton
                    class="p-primary mt-4"
                    label="Check Notes"
                    [disabled]="!videoDetail?.notes"
                    (click)="startQuiz(true)"
                  ></button>
                </div>
                <figure class="media-card-bg mb-0">
                  <img
                    class="img-fluid"
                    src="../../../../assets/images/notes-bg.svg"
                    alt="Quiz Bg"
                  />
                </figure>
              </div>
            </div>
          </p-card>
          <p-card class="flex-grow-1" *ngIf="isQuizAvailable">
            <div
              class="d-flex flex-column align-items-center align-items-xl-start gap-2"
            >
              <div class="flex-shrink-0">
                <h6 class="card-title my-0">Quiz</h6>
                <!-- [disabled]="isQuizAvailable" -->
              </div>
              <div
                class="d-flex flex-column-reverse justify-content-center flex-wrap justify-content-xl-between flex-xl-row w-100"
              >
                <div class="flex-shrink-0 text-center">
                  <button
                    (click)="startQuiz(false)"
                    [disabled]="!isQuizAvailable"
                    pButton
                    class="p-primary mt-4"
                    size="sm"
                    label="Take Quiz"
                  ></button>
                </div>
                <figure class="media-card-bg mb-0">
                  <img
                    class="img-fluid"
                    src="../../../../assets/images/quiz-bg.svg"
                    alt="Quiz Bg"
                  />
                </figure>
              </div>
            </div>
          </p-card>
        </div>
        <!-- End: Notes & Quiz cards -->

        <!-- when in mobile device list becomes horizontal -->
        <div class="video-list pt-3 mw-100 px-3" *ngIf="deviceType == 'Mobile'">
          <h4 class="section-title mt-0">Similar Lessons</h4>
          <div
            class="video-items horizontal d-flex overflow-auto"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [horizontal]="true"
            [scrollWindow]="false"
            (scrolled)="loadMoreData()"
          >
            <p-card class="video-card" *ngFor="let video of videoList">
              <div class="cursor-pointer" (click)="watchVideo(video)">
                <figure class="img-container m-0">
                  <img
                    width="100"
                    height="200"
                    [ngSrc]="
                      video.thumbnail
                        ? imageBaseURL + 'common/getfile/' + video.thumbnail
                        : '../../assets/algoed-aboutus-images/no-thumbnail.jpg'
                    "
                    alt="{{ video.title }}"
                    class="img-fluid"
                  />
                  <div class="play-container">
                    <div class="play-btn">
                      <span class="pi pi-play"></span>
                    </div>
                  </div>
                </figure>
                <div
                  class="d-flex align-items-start justify-content-between gap-2"
                >
                  <div>
                    <h6 class="card-title">{{ video.title }}</h6>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
        <!-- End: when in mobile device list becomes horizontal -->
      </div>

      <!-- border -->
      <div class="media-separator" *ngIf="deviceType !== 'Mobile'"></div>
      <!-- End: border  -->

      <!-- Video List -->
      <div
        class="video-list h-100 overflow-hidden"
        *ngIf="deviceType !== 'Mobile'"
      >
        <div class="video-items" #videoItemsContainer (scroll)="onScroll()">
          <p-card
            class="video-card"
            *ngFor="let video of videoList"
            (click)="watchVideo(video)"
          >
            <div>
              <figure class="img-container m-0">
                <img
                  width="100"
                  height="200"
                  [ngSrc]="
                    video.thumbnail
                      ? imageBaseURL + 'common/getfile/' + video.thumbnail
                      : '../../assets/algoed-aboutus-images/no-thumbnail.jpg'
                  "
                  alt="{{ video.title }}"
                  class="img-fluid"
                />
                <div class="play-container">
                  <div class="play-btn">
                    <span class="pi pi-play"></span>
                  </div>
                </div>
              </figure>
              <div>
                <h6 class="card-title">{{ video.title }}</h6>
                <!-- <p class="card-desc mb-0">{{ video.description }}</p> -->
              </div>
            </div>
          </p-card>
          <ng-container *ngIf="!videoList.length">
            <div class="text-danger text-center">No Videos Found</div>
          </ng-container>
        </div>
      </div>
      <!-- End: Video List -->
    </div>
    <app-non-user-footer *ngIf="!isUserLoggedIn"></app-non-user-footer>
  </div>
</div>
