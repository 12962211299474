import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeEmbeddedCheckout,
  StripeEmbeddedCheckoutOptions,
} from '@stripe/stripe-js';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { UsersService } from 'src/app/shared/users.service';
// import {moment} from 'moment'
import { HostListener } from '@angular/core';
import { MastersService } from 'src/app/shared/masters.service';

@Component({
  selector: 'app-purchase-payment',
  templateUrl: './purchase-payment.component.html',
  styleUrls: ['./purchase-payment.component.scss'],
})
export class PurchasePaymentComponent implements OnInit {
  // @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  dialogData;
  isConfirm: boolean = false;
  subscriptionData;
  isSubscriptionPayment: boolean = false;
  action;
  amount;
  subs: Subscription;
  isDisabled: boolean = false;
  emailId: any;
  nameoncard: any;
  savedCard;
  competitionDetails: any;
  countryShortName: string; // for non subscription one time payment
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '500',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripeTest: UntypedFormGroup;
  studentData;

  cardLast4: string;
  invoiceFor: boolean = false;
  invoiceData: any;
  submitted: boolean = false;
  invoiceDataAfterApi = [];
  invoiceDataSubtotal;
  currentUser;
  isExistingStripe: boolean = false;
  clientSecretID: string;
  publicKey: string;
  stripe: any;
  elements;
  paymentFor: string;
  dialogStyle = {
    width: window.innerWidth > 700 ? '40vw' : '95vw',
    height: window.innerWidth > 700 ? '70vh' : 'auto',
  };

  constructor(
    private commonService: CommonServiceService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private usersService: UsersService,
    private mastersService: MastersService
  ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeDialog({ isBackPressed: true });
  }
  checkout;
  ngOnInit(): void {
    this.dialogData = this.config.data;
    if (typeof this.config.data.studentData == 'string') {
      this.studentData = JSON.parse(this.dialogData.studentData);
    } else {
      this.studentData = this.dialogData.studentData;
    }
    this.competitionDetails = this.dialogData.competitionDetails ?? null;

    this.isSubscriptionPayment = this.dialogData.isSubscriptionPayment;
    this.subscriptionData = this.dialogData.subscriptionData;

    this.paymentFor = this.dialogData.paymentFor;

    this.countryShortName = this.dialogData.countryShortName; // for non subscription one time payment

    this.amount = this.dialogData.amount;

    this.mastersService.getPublicKey().subscribe((res) => {
      this.publicKey = res.data.stripe_pk;
      if (this.paymentFor == 'essayReview') {
        const essayData = this.dialogData.essayData;
        this.getClientKeyForEssayReview(essayData);
      } else if (this.paymentFor == 'essayPackage') {
        const data = {
          isCategorList: true,
          name: {
            bundle_id: this.dialogData.bundle_id,
          },
        };
        this.getClientEssayPackage(data);
      } else if (this.paymentFor == 'competition') {
        let data: any = {
          name: { team_id: this.dialogData.team_id },
          isCategorList: true,
        };
        if (this.dialogData.isPayForEveryone) {
          data.name.mode = 'team';
          data.name.user_id = [...this.dialogData.user_id];
        }
        this.getClientKeyForCompetition(data);
      }
    });
  }

  getClientEssayPackage(data: any) {
    this.commonService
      .saveRecord(`users/essay/bundles/purchase/init`, data)
      .subscribe(
        async (res) => {
          setTimeout(async () => {
            this.clientSecretID = res.data.clientSecret;
            this.stripe = (window as any).Stripe(this.publicKey);
            const checkout = await this.stripe.initEmbeddedCheckout({
              clientSecret: res?.data?.clientSecret,
            });
            checkout.mount('#card-element');
          }, 300);
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            4000
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      );
  }

  getClientKeyForEssayReview(essayData) {
    this.commonService.saveRecord(`users/essay/edits`, essayData).subscribe(
      async (secret) => {
        this.clientSecretID = secret.data.clientSecret;
        setTimeout(async () => {
          this.stripe = (window as any).Stripe(this.publicKey);
          const checkout = await this.stripe.initEmbeddedCheckout({
            clientSecret: secret?.data?.clientSecret,
          });
          checkout.mount('#card-element');
        }, 300);
      },
      (error) => {
        this.commonService.showToast(
          error?.error?.error ?? 'Something went wrong',
          'error',
          error?.error?.message
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    );
  }

  getClientKeyForCompetition(data) {
    this.mastersService.getClientKeyForCompetition(data).subscribe(
      async (secret) => {
        this.clientSecretID = secret.data.clientSecret;
        this.stripe = (window as any).Stripe(this.publicKey);
        this.checkout = await this.stripe.initEmbeddedCheckout({
          clientSecret: this.clientSecretID,
        });
        this.checkout.mount('#card-element');
      },
      (error) => {
        this.commonService.showToast(
          error?.error?.error ?? 'Something went wrong',
          'error',
          2000
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    );
  }

  initEmbeddedCheckout(
    options: StripeEmbeddedCheckoutOptions
  ): Observable<StripeEmbeddedCheckout> {
    return this.stripe.initEmbeddedCheckout(options);
  }

  get f() {
    return this.stripeTest.controls;
  }

  chargeForSubscriptionRenew(token?) {}

  makePaymentInvoice() {}
  closeDialog(param?) {
    // this.checkout.unmount();
    if (param) {
      this.ref.close(param);
    } else {
      this.ref.close({ close: true });
    }
    window.location.reload();
  }

  confirmPurchase() {
    // this.createToken();
  }

  private getUserData() {
    this.usersService
      .getStudentData({
        data: { userId: this.currentUser.userId || this.currentUser.id },
      })
      .subscribe((item) => {
        this.currentUser = item.data[0];
        this.currentUser.id = this.currentUser.userId;

        this.commonService.$curUser.next(this.currentUser);
        localStorage.setItem('user', JSON.stringify(this.currentUser));
      });
  }

  redirectToBilling() {
    this.closeDialog();
    let dataObj = {
      stripe_customerID: this.currentUser.stripe_customerID,
    };

    this.usersService
      .createCustomerPortalSession({
        data: dataObj,
      })
      .subscribe((item) => {
        window.open(item.data, '_blank');
      });
  }
}
