import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  RendererFactory2,
} from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { delay, retryWhen, take } from 'rxjs/operators';
import { ApiService } from './shared/api.service';
import { CommonServiceService } from './shared/common-service.service';
import { mainConstants } from './shared/constants/main.constants';
import { LoaderService } from './shared/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // Object type

  // Regular expressions
  blockSpecial: RegExp = /^[^<>*!#%^&()+/:;|"{}]+$/;
  blockSpace: RegExp = /[^\s]/;

  // Public variable
  // products: any;

  // Renderer2
  renderer: Renderer2;

  // Boolean type
  loaded = false;

  titleAndUrl = mainConstants.metaTagsTitle;

  constructor(
    private commonService: CommonServiceService,
    private apiService: ApiService,
    private router: Router,
    private metaService: Meta,
    private titleService: Title,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
    public loader: LoaderService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    // this.load();

    this.apiService
      .generateValidateToken({
        client_id: 'ED',
        secret_key: 'ED1',
        grant_type: 'ED',
        token: '',
      })
      .pipe(
        retryWhen((errors) => {
          return errors.pipe(delay(5000), take(10));
        })

        //   )
      )
      .subscribe(
        (item) => {
          if (item.access_token) {
            localStorage.setItem('access_token', item.access_token);
            this.commonService.$curAccessToken.next(item.access_token);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  ngOnInit() {
    this.router.events.subscribe((item) => {
      if (item instanceof NavigationEnd) {
        this.commonService.currentURL.next(item.url);
        //reset scroll
        window.scrollTo(0, 0);
        this.titleAndUrl.forEach((x) => {
          if (item.url.includes(x.url)) {
            this.titleService.setTitle(x.title);
            this.metaService.updateTag({
              property: 'og:title',
              content: x.title,
            });
          }
        });
        const url = this.router.url;
        const setTitle = this.titleService.setTitle.bind(this.titleService);
        const updateTag = this.metaService.updateTag.bind(this.metaService);
        let description: string;

        if (url === '/admin-login') {
          setTitle(mainConstants.TITLES.ADMIN_LOGIN);
        } else if (url === '/organisation-admin-login') {
          setTitle(mainConstants.TITLES.ORG_ADMIN_LOGIN);
        } else if (
          url === '/explore-competitions' ||
          url === '/public/competitions' ||
          url === '/student/competitions/startup'
        ) {
          description = mainConstants.DESCRIPTIONS.COMPETITIONS;
        } else if (
          url === '/public/student-videos/US' ||
          url === '/public/student-videos/UK' ||
          url === '/student/admission/US/tipsAndAdvice' ||
          url === '/student/admission/UK/tipsAndAdvice'
        ) {
          description = mainConstants.DESCRIPTIONS.STUDENT_VIDEOS;
        } else if (
          url === '/student/admission/US/doc-review' ||
          url === '/public/doc-review'
        ) {
          description = mainConstants.DESCRIPTIONS.DOC_REVIEW;
        } else {
          description = mainConstants.DESCRIPTIONS.DEFAULT;
        }

        updateTag({
          property: 'og:description',
          content: description,
        });
      }
    });
  }
}
