<div class="card shadow-none mt-3" [formGroup]="studentProfileForm">
  <div class="card-body">
    <div class="student-header-part">
      <h4>Tell us your career interest (for UK)</h4>
      <p class="sub-text">
        Please tell us your career interests so that we can give you
        personalized advice on choosing school courses, super-curricular
        activities, university subject, and application destinations.
      </p>
    </div>
    <br />
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <div class="form-group">
          <label for="Curriculum" class="block"
            >Which career(s) are you inclined to pursue?</label
          >
          <p-multiSelect
            placeholder="Select Career"
            [options]="fav"
            [(ngModel)]="selectedCareer"
            (ngModelChange)="onSubjectChange($event, 'careerId')"
            optionValue="id"
            optionLabel="name"
            [ngModelOptions]="{ standalone: true }"
          ></p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <div class="form-group">
          <label for="Curriculum" class="block"
            >If others, please specify(Optional)</label
          >
          <input
            id="Confirm Password"
            type="username"
            aria-describedby="your text here..."
            class="p-inputtext-lg"
            formControlName="others"
            pInputText
          />
        </div>
      </div>
    </div>
    <div class="input-group">
      <p class="sub-text">Why?</p>
      <textarea
        [rows]="10"
        [cols]="30"
        pInputTextarea
        formControlName="why_interst_in_given_career"
      ></textarea>
    </div>

    <p class="sub-text">
      What have you done to further your interest in this/these careers
      (including job experience, related extra-curricular activities, reading)?
    </p>
    <textarea
      [rows]="10"
      [cols]="30"
      formControlName="what_you_have_done_to_further_interest_in_the_given_career"
      pInputTextarea
    >
    </textarea>

    <footer
      *ngIf="isStudent"
      class="d-flex justify-content-center gap-3 border-top p-3 mt-3 pb-0"
    >
      <button pButton type="button" class="p-cancel-btn">
        <span class="flex-nowrap">Cancel</span>
      </button>
      <button pButton type="button" (click)="saveData()" class="p-primary">
        <span class="flex-nowrap">Save</span>
      </button>
    </footer>
  </div>
</div>
