<div class="standarized-test-container">
    <div class="student-header-part">
        <h4>Standardized Test <img src="../../../../assets/icons/standardized-book.svg" alt="" srcset=""></h4>
        <p class="sub-text">Your standardized test result(s) tells us where you stand in comparison to students from other secondary schools. Please enter your result(s) here so that we can give you personalized advice on whether you need to retake the test or apply test optional, if this option is available.
        </p>
    </div>

    <div class="standarized-test-list">
        <div class="flex without-p-card">
            <h3>SAT Scores</h3>
            <button pButton pRipple type="button" label="Add Entry" class=" p-primary" (click)="showAddEntry('sat')" [disabled]="satScores?.length >= 5"></button>
        </div>

        <div class="sat-new-entry border-box" *ngIf="newSatEntry">
               <h4 class="red">Add SAT scores</h4>
                <br/>
                <div class="grid-template-container">
                <label>Composite Score
                </label>
                <input pInputText [(ngModel)]="satObj.sat_score_composite"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Math Score
                </label>
                <input pInputText [(ngModel)]="satObj.sat_score_math_score"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Reading and Writing Score
                </label>
                <input pInputText [(ngModel)]="satObj.sat_score_reading_and_writing_score"/>
                </div>
                <br/>
                <br/>
               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="showAddEntry('sat')"> 
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveSatScore()"
                        class="full-width p-primary"> 
                    </button>

                </div>

        </div>

        <div class="table-with-rounded-corners" *ngFor="let score of satScores; let i = index">
        <div class="flex without-p-card" *ngIf="isMobile && !score.isEditEntry">
            <span></span>
            <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('sat',i,'edit')">
            </button>
        </div>
            
            <p-table [value]="[{}]" responsiveLayout="scroll" *ngIf="!score.isEditEntry">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Entry {{i + 1}}</th>
                        <th></th>
                        <th>
                            <div class="flex-without-justify td-align-end">
                                <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('sat',i,'edit')">
                                </button>
                                <button pButton pRipple type="button" label="Delete" class="p-danger p-button-sm" (click)="deleteSatScore(i)">
                                </button>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr class="hide-mobile-tr">
                        <td>
                            CompositeSscore

                        </td>
                        <td>
                        Math Score
                        </td>
                        <td>
                            Reading and Writing Score
                        </td>
                    </tr>
                    <tr>
                        <td><span class="table-mobile-hide">Composite score</span>
                            {{score.sat_score_composite }}
                        </td>
                        <td><span class="table-mobile-hide">Math score</span>
                            {{score.sat_score_math_score }}
                        </td>
                        <td><span class="table-mobile-hide">Reading and writing score</span>
                            {{score.sat_score_reading_and_writing_score }}
                        </td>

                    </tr>
                </ng-template>
            </p-table>
            <div class="border-box" *ngIf="score.isEditEntry">
          <h4 class="red">Update SAT scores</h4>
                <br/>
                <div class="grid-template-container">
                <label>Composite Score
                </label>
                <input pInputText [(ngModel)]="score.sat_score_composite"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Math Score
                </label>
                <input pInputText [(ngModel)]="score.sat_score_math_score"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Reading and Writing Score
                </label>
                <input pInputText [(ngModel)]="score.sat_score_reading_and_writing_score"/>
                </div>
                <br/>
                <br/>
               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="editEntry('sat',i,'cancel')">
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveSatScore(i)"
                        class="full-width p-primary">
                    </button>

                </div>
                </div>
        </div>

    </div>

    <div class="standarized-test-list">
        <div class="flex without-p-card">
            <h3>ACT Score</h3>
            <button pButton pRipple type="button" label="Add Entry" class=" p-primary" (click)="showAddEntry('act')" [disabled]="actScores?.length >= 5"></button>
        </div>
    

        <div class="sat-new-entry border-box" *ngIf="newActEntry">
                <h4 class="red">Add ACT scores</h4>
                <br/>
                <div class="grid-template-container"> 
                <label>Composite Score
                </label>
                <input pInputText [(ngModel)]="actObj.act_score_composite"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>English Score
                </label>
                <input pInputText [(ngModel)]="actObj.act_score_english"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Math Score
                </label>
                <input pInputText [(ngModel)]="actObj.act_score_math"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Science Score
                </label>
                <input pInputText [(ngModel)]="actObj.act_score_science"/>                
                </div>

                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Reading Score
                </label>
                <input pInputText [(ngModel)]="actObj.act_score_reading"/>    
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Writing Score
                </label>
                <input pInputText [(ngModel)]="actObj.act_score_writing"/>
                </div>
                <br/>
                <br/>
               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="showAddEntry('act')">
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveActScore()"
                        class="full-width p-primary">
                    </button>

                </div>

        </div>        
        <div class="table-with-rounded-corners" *ngFor="let score of actScores; let i = index">
        <div class="flex without-p-card" *ngIf="isMobile && !score.isEditEntry">
            <span></span>
            <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('act',i,'edit')">
            </button>
        </div>
                
            <p-table [value]="[{}]" responsiveLayout="scroll" *ngIf="!score.isEditEntry">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Entry 1</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            <div class="flex-without-justify td-align-end">
                                <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('act',i,'edit')">
                                </button>
                                <button pButton pRipple type="button" label="Delete" class="p-danger  p-button-sm" (click)="deleteActScore(i)">
                                </button>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr class="hide-mobile-tr">
                        <td>Composite Score</td>
                        <td>English Score</td>
                        <td>Math Score</td>
                        <td>Science Score</td>
                        <td>Reading Score</td>
                        <td>Writing Score</td>

                    </tr>
                    <tr>
                        <td><span class="table-mobile-hide">Composite score</span>
                            {{score.act_score_composite}}
                        </td>
                        <td><span class="table-mobile-hide">English score</span>
                            {{score.act_score_english }}
                        </td>
                        <td><span class="table-mobile-hide">Math score</span>
                            {{score.act_score_math }}
                        </td>
                        <td><span class="table-mobile-hide">Science score</span>
                            {{score.act_score_science }}
                        </td>

                        <td><span class="table-mobile-hide">Reading score</span>
                            {{score.act_score_reading }}
                        </td>
                        <td><span class="table-mobile-hide">Writing score</span>
                            {{score.act_score_writing}}
                        </td>

                    </tr>
                </ng-template>
            </p-table >
            <div class="add-record-container border-box" *ngIf="score.isEditEntry">
                <h4 class="red">Update ACT scores</h4>
                <br/>
                <div class="grid-template-container"> 
                <label>Composite Score
                </label>
                <input pInputText [(ngModel)]="score.act_score_composite"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>English Score
                </label>
                <input pInputText [(ngModel)]="score.act_score_english"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Math Score
                </label>
                <input pInputText [(ngModel)]="score.act_score_math"/>
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Science Score
                </label>
                <input pInputText [(ngModel)]="score.act_score_science"/>                
                </div>

                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Reading Score
                </label>
                <input pInputText [(ngModel)]="score.act_score_reading"/>    
                </div>
                <br/>
                <br/>
                <div class="grid-template-container">
                <label>Writing Score
                </label>
                <input pInputText [(ngModel)]="score.act_score_writing"/>
                </div>
                <br/>
                <br/>

               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="editEntry('act',i,'cancel')">
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveActScore(i)"
                        class="full-width p-primary">
                    </button>

                </div>
            </div>

        </div>
    </div>

    <div class="standarized-test-list">
        <div class="flex without-p-card">
            <h3>Oxbridge Standardized Test Scores<small> (Single entry)</small></h3>
        </div>
        <div class="table-with-rounded-corners" *ngFor="let score of oxbridgeScores;let i = index">
        <div class="flex without-p-card" *ngIf="isMobile && !score.isEditEntry">
            <span></span>
            <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('oxb',i,'edit')">
            </button>
        </div>
            <p-table [value]="oxbridgeScores" responsiveLayout="scroll" *ngIf="!score.isEditEntry">
                <ng-template pTemplate="header">
                    <tr class="hide-mobile-tr">
                        <th></th>
                        <th></th>
                        <th>
                            <div class="flex-without-justify td-align-end">
                                <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('oxb',i,'edit')">
                            </button>
                            <!--<button pButton pRipple type="button" label="Cancel" class="p-danger  p-button-sm">-->
                            <!--</button>-->
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr class="hide-mobile-tr">
                        <td>Test</td>
                        <td *ngIf="!isMobile"></td>
                        <td>Score</td>
                    </tr>
                    <tr>
                        <td><span class="table-mobile-hide">Test</span>
                            {{item.oxbridge_standardized_test}}
                        </td>
                        <td *ngIf="!isMobile"></td>

                        <td> <span class="table-mobile-hide">Score</span>
                            {{item.oxbridge_standardized_test_scores}}
                        </td>

                    </tr>
                </ng-template>
            </p-table>
                <div class="add-record-container border-box" *ngIf="oxbridgeScores[0].isEditEntry">
                <h4 class="red">Add/Update Oxbridge scores</h4>
                <br/>
                <div class="oxb-input-container">
                <label>Standardized test
                </label>
                <p-dropdown [options]="nonOxbOptions" placeholder="Select a test" optionLabel="value" optionValue="value" class="full-width"
                [(ngModel)]="oxbridgeScores[0].oxbridge_standardized_test"></p-dropdown>

                </div>
                <br/>
                <br/>
                <div class="oxb-input-container">
                <label>Standardized test score
                </label>

                <input pInputText [(ngModel)]="oxbridgeScores[0].oxbridge_standardized_test_scores"/>
                </div>
                <br/>
                <br/>
               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="editEntry('oxb',i,'cancel')">
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveOxbridgeScore()"
                        class="full-width p-primary">
                    </button>

                </div>
            </div>
        </div>
    </div>

    <div class="standarized-test-list non-oxb-container">
        <div class="flex without-p-card">
            <h3>Non-Oxbridge UK Standardized Test Scores <small>(0-5 entries)</small></h3>
            <button pButton pRipple type="button" label="Add Entry" class=" p-primary" (click)="showAddEntry('nonOxb')" [disabled]="nonOxbridgeScores?.length >= 5"></button>
        </div>
             <div class="add-record-container border-box" *ngIf="newNonOxbEntry">
                <h4 class="red">Add Non Oxbridge scores</h4>
                <br/>
                <div class="oxb-input-container">


                <label>Standardized test
                </label>
                    <p-dropdown [options]="nonOxbOptions" placeholder="Select a test" optionLabel="value" optionValue="value" class="full-width" [(ngModel)]="nonOxbridgeObj.non_oxbridge_standardized_test"></p-dropdown>
</div>
                <br/>
                <br/>
                <div class="oxb-input-container">

                <label>Standardized test score
                </label>
                <input pInputText [(ngModel)]="nonOxbridgeObj.non_oxbridge_standardized_test_scores"/>
                </div>

                <br/>
                <br/>
               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="showAddEntry('nonOxb')">
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveNonOxbridgeScore()"
                        class="full-width p-primary">
                    </button>

                </div>
            </div>
                <br/>
                <br/>

        <div class="table-with-rounded-corners" *ngFor="let score of nonOxbridgeScores;let i = index">
        <div class="flex without-p-card" *ngIf="isMobile && !score.isEditEntry">
            <span></span>
            <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('nonOxb',i,'edit')">
            </button>
        </div>
            <p-table [value]="[{}]" responsiveLayout="scroll" *ngIf="!score.isEditEntry">
                <ng-template pTemplate="header">
                    <tr class="hide-mobile-tr">
                        <th>Entry {{i + 1}}</th>
                        <th></th>
                        <th>
                            <div class="flex-without-justify td-align-end">
                                <button pButton pRipple type="button" label="Edit" class="p-grey p-button-sm" (click)="editEntry('nonOxb',i,'edit')">
                                </button>
                                <button pButton pRipple type="button" label="Delete" class="p-danger p-button-sm" (click)="deleteProfileOxbridgeScores(i)">
                                </button>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                     <tr class="hide-mobile-tr">
                        <td>Test</td>
                        <td *ngIf="!isMobile"></td>
                        <td>Score</td>
                    </tr>
                    <tr>
                        <td><span class="table-mobile-hide">Test</span>
                            {{score.non_oxbridge_standardized_test}}
                        </td>
                        <td *ngIf="!isMobile"></td>

                        <td> <span class="table-mobile-hide">Score</span>
                            {{score.non_oxbridge_standardized_test_scores}}
                        </td>

                    </tr>
                </ng-template>
            </p-table>
             <div class="add-record-container border-box" *ngIf="score.isEditEntry">
                <h4 class="red">Update Non Oxbridge scores</h4>
                <br/>
                <div class="oxb-input-container">
                    
                <label>Standardized test
                </label>
                    <p-dropdown [options]="nonOxbOptions" optionLabel="value" optionValue="value" class="full-width" [(ngModel)]="score.non_oxbridge_standardized_test"></p-dropdown>
                </div>

                <br/>
                <br/>
                <div class="oxb-input-container">

                <label>Standardized test score
                </label>

                <input pInputText [(ngModel)]="score.non_oxbridge_standardized_test_scores"/>
                </div>

                <br/>
                <br/>
               <div class="flex-without-justify">
                    <button pButton pRipple type="button" label="Cancel"
                        class="full-width p-button-outlined p-danger" (click)="editEntry('nonOxb',i,'cancel')">
                    </button>
                    <button pButton pRipple type="Cancel" label="Save" (click)="saveNonOxbridgeScore(i)"
                        class="full-width p-primary">
                    </button>

                </div>
            </div>
        </div>
    </div>
</div>
