<div class="card-padding">
  <div class="text-lg-start text-center">
    <img src="../../../assets/icons/logo-2.svg" alt="" />
  </div>
  <h3 class="text-lg-start text-center mt-2">Login</h3>
  <form class="d-flex flex-column gap-3">
    <div>
      <div class="mb-2">
        <label for="Email" class="block">Email</label>
      </div>
      <div class="p-input-icon-left w-100">
        <i class="pi pi-user"></i>
        <input
          pInputText
          class="p-inputtext-lg"
          placeholder="Email ID"
          [(ngModel)]="email"
          [disabled]="true"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </div>
    <div>
      <div class="mb-2">
        <label for="Email" class="block">New Password</label>
      </div>
      <div class="p-input-icon-left w-100 p-input-icon-right">
        <i class="pi pi-lock"></i>
        <input
          [type]="showPass ? 'text' : 'password'"
          pInputText
          class="p-inputtext-lg"
          [placeholder]="'New Password'"
          [(ngModel)]="password"
          [ngModelOptions]="{ standalone: true }"
        />
        <i
          style="cursor: pointer"
          class="pi"
          [ngClass]="{ 'pi-eye': !showPass, 'pi-eye-slash': showPass }"
          (click)="showPassFn()"
        ></i>
      </div>
    </div>
    <div>
      <div class="mb-2">
        <label for="Email" class="block">Confirm Password</label>
      </div>
      <div class="p-input-icon-left w-100 p-input-icon-right">
        <i class="pi pi-lock"></i>
        <input
          [type]="cnfPassFn ? 'text' : 'password'"
          pInputText
          class="p-inputtext-lg"
          [placeholder]="'Confirm Password'"
          [(ngModel)]="confirmPassword"
          [ngModelOptions]="{ standalone: true }"
        />
        <i
          style="cursor: pointer"
          class="pi"
          [ngClass]="{ 'pi-eye': !cnfPassFn, 'pi-eye-slash': cnfPassFn }"
          (click)="showConfirmPassFn()"
        ></i>
        <!-- <i style="cursor: pointer" class="pi"></i> -->
      </div>
      <small
        class="text-danger"
        *ngIf="confirmPassword?.length > 0 && password !== confirmPassword"
        >Password should be same</small
      >
    </div>
    <button
      pButton
      type="submit"
      [label]="'Set Password & Login'"
      class="p-button-raised display-block p-button-lg mb-0"
      [disabled]="
        password?.length === 0 ||
        confirmPassword?.length === 0 ||
        password !== confirmPassword
      "
      (click)="orgAdminLogin()"
    ></button>
  </form>
</div>
