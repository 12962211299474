import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { Chart, ChartOptions, ChartType } from 'chart.js';
import { TimeSpentData } from 'src/app/organisation/or-shared/or.interface';
import {
  chartConstants,
  externalTooltipHandler,
  verticalBarHoverPlugin,
} from '../../constants/chart.constants';

@Component({
  selector: 'app-algoed-graph',
  templateUrl: './algoed-graph.component.html',
  styleUrls: ['./algoed-graph.component.scss'],
})
export class AlgoedGraphComponent {
  @ViewChild('myChart') myChartRef: ElementRef<HTMLCanvasElement>;

  @Input() graphType: ChartType;
  @Input() data: TimeSpentData;
  @Input() tooltipText: string;
  @Input() barColors: any;
  myChart: any;
  public barChartOptions: ChartOptions = chartConstants.barChartOptions;
  public barChartLabels: string[] = [
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
  ];
  public barChartType: ChartType = 'line';
  public barChartLegend = true;
  chartWidth: string;
  public barChartPlugins = [];

  constructor() {}

  ngOnInit() {
    const dataPointWidth = 80;
    const totalWidth = 8 * dataPointWidth;

    if (this.data.labels.length > 6) {
      this.chartWidth = totalWidth + 'px';
    }
  }

  ngOnChanges(changes) {
    if (changes.data.previousValue) {
      this.data = null;
      this.data = changes.data.currentValue;
      this.loadChart(this.data);
    }
  }

  ngAfterViewInit() {
    this.loadChart(this.data);
  }

  loadChart(values: any) {
    const ctx = this.myChartRef.nativeElement.getContext('2d');
    if (this.myChart) {
      this.myChart.destroy();
    }
    const gradient = ctx.createLinearGradient(
      0,
      this.myChartRef.nativeElement.clientHeight,
      0,
      0
    );
    gradient.addColorStop(0, 'rgba(255, 99, 132, 0)');
    gradient.addColorStop(1, 'rgba(255, 99, 132, 0.5)');
    this.myChart = new Chart(ctx, {
      type: this.graphType as ChartType,
      data: {
        labels: [...values.labels],
        datasets: [
          {
            data: [...values.data],
            fill: 'start',
            tension: 0.4,
            backgroundColor:
              this.graphType === 'line'
                ? gradient
                : this.barColors.map((color) => color.replace('0.2', '1')),
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false, // this will remove the x-axis grid lines
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: this.graphType === 'line' ? true : false,
              drawOnChartArea: true,
              drawTicks: false,
              color: function (context) {
                if (context.tick.value === 0) {
                  return 'transparent';
                }
                return 'rgba(0, 0, 0, 0.1)'; // Light color for grid lines
              },
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: externalTooltipHandler,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y;
                }
                label += ` `;

                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          filler: {
            propagate: false,
          },
        },
      },
      plugins: [this.graphType == 'line' && verticalBarHoverPlugin],
    });
    this.myChart.update();
  }
}
