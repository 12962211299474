<ng-container *ngIf="!isLessonType && !isImage">
  <div class="quiz-container" *ngIf="!showResults">
    <!-- <p-progressBar [value]="value" [style]="{ height: '10px' }"></p-progressBar> -->

    <div class="progressbar mb-4">
      <div class="progress-status" [style]="{ width: value + '%' }"></div>
      <div
        class="progress-dot"
        *ngFor="let _ of [].constructor(questionsCount); let i = index"
      ></div>
    </div>

    <div class="questions-list">
      <div *ngFor="let question of questions; let i = index">
        <ng-container *ngIf="i == questionSelected">
          <!-- <p>Questions {{ i + 1 }}/{{ questionsCount }}</p> -->
          <h4>Q.{{ i + 1 }} {{ question.questionText }}</h4>
          <ul>
            <ng-container
              *ngFor="let answer of question.answers; let j = index"
            >
              <li
                [ngClass]="
                  setAnswer &&
                  setAnswer[i]?.question == i &&
                  setAnswer[i]?.answer === j
                    ? 'active'
                    : ''
                "
                (click)="activeAnswer(i, j)"
              >
                <span>
                  {{ alphabet[j] }}
                </span>
                <div>{{ answer.answerText }}</div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </div>

      <div class="flex-without-justify justify-center mt-4">
        <button
          pButton
          type="button"
          label="Previous"
          class="p-grey p-button-sm"
          *ngIf="questionSelected > 0"
          (click)="prevQuestion()"
        ></button>
        <button
          pButton
          type="button"
          label="Complete"
          class="p-grey p-button-sm"
          *ngIf="
            questionSelected >= 0 &&
            questionSelected + 1 == questionsCount &&
            !showResults
          "
          (click)="showResultsFun()"
        ></button>
        <button
          pButton
          type="button"
          label="Next"
          class="p-primary p-button-sm"
          *ngIf="questionSelected + 1 < questionsCount"
          (click)="nextQuestion()"
        ></button>
      </div>
    </div>
  </div>

  <!--setAnswer.length > 0  && -->
  <div class="quiz-container left-align" *ngIf="showResults">
    <h2>
      Result: <span>{{ totalCorrect }}/{{ totalQuestions }}</span>
    </h2>

    <div *ngFor="let question of questions; let i = index">
      <!--<ng-container *ngIf="i== questionSelected && setAnswer[i]?.question == i">-->
      <ng-container>
        <div class="questions-list-result">
          <h4>Q.{{ i + 1 }} {{ question.questionText }}</h4>
          <ul>
            <ng-container
              *ngFor="let answer of question.answers; let j = index"
            >
              <li
                [ngClass]="{
                  active:
                    setAnswer &&
                    setAnswer[i]?.question == i &&
                    setAnswer[i]?.answer === j,
                  success: answer.isCorrect == 1
                }"
              >
                <span>
                  {{ alphabet[j] }}
                </span>
                <div>{{ answer.answerText }}</div>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isLessonType">
  <ngx-doc-viewer
    [url]="baseURL"
    [viewer]="'google'"
    style="width: 100%; height: 100%"
  ></ngx-doc-viewer>
</ng-container>

<ng-container *ngIf="isImage">
  <figure class="image-container">
    <img class="img-fluid w-100" [src]="baseURL" alt="Sample Image" />
  </figure>
</ng-container>
