import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../shared/users.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CommonServiceService } from '../../shared/common-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changepasswordForm: UntypedFormGroup;
  submitted = false;
  oldPass = '';
  newPass = '';
  confirmPass = '';
  subs: Subscription;
  currentUser;
  currentDate;
  userDetails;
  constructor(
    private usersService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonServiceService
  ) {}

  ngOnInit(): void {
    this.currentDate = new Date();
    this.changepasswordForm = this.formBuilder.group({
      oldPass: ['', Validators.required],
      newPass: ['', [Validators.required, Validators.minLength(6)]],
      confirmPass: ['', Validators.required],
    });
    this.subs = this.commonService.$curUser.subscribe((item) => {
      this.currentUser = item && JSON.parse(item);
      console.log(this.currentUser);
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get f() {
    return this.changepasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newPass.length < 8) {
      this.commonService.showToast(
        'Password should be atleast 8 characters long',
        'warn',
        2500
      );
      return;
    }
    if (this.newPass != this.confirmPass) {
      this.commonService.showToast('Password does not match', 'warn', 2500);
      return;
    }

    const data = {
      oldPass: this.oldPass,
      newPass: this.newPass,
    };
    this.usersService
      .editStudentDataFromStudentProfile({ data: data })
      .subscribe(
        (response) => {
          this.commonService.showToast(
            'Password changed successfully!',
            'success',
            2000,
            'Info'
          );
          // this.currentUser.password = btoa(this.newPass);
          this.oldPass = '';
          this.newPass = '';
          this.confirmPass = '';
          // localStorage.setItem('user', JSON.stringify(response?.data));
          // this.commonService.$curUser.next(this.currentUser);
        },
        (error) => {
          this.commonService.showToast(
            error.error.error,
            'warn',
            2000,
            'Info'
          );
          console.log(error);
          // return false;
        }
      );
  }
}
