import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'renderContent'
})
export class RenderContentPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string): SafeHtml {
    // Decode HTML entities
    const decodedContent = this.decodeHtmlEntities(content);
    
    // Sanitize and render the content
    return this.sanitizer.bypassSecurityTrustHtml(decodedContent);
  }

  private decodeHtmlEntities(html: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
