import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-download-award-card',
  templateUrl: './download-award-card.component.html',
  styleUrls: ['./download-award-card.component.scss'],
})
export class DownloadAwardCardComponent {

  @Output() downloadAward: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadToPdfAward: EventEmitter<any> = new EventEmitter<any>();

  downloadImage() {
    this.downloadAward.emit();
  }

  pdfDownload() {
    this.downloadToPdfAward.emit();
  }
}
