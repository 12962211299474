<div class="d-flex flex-column gap-3">
  <p-button
    *ngIf="!isSuperAdmin"
    [link]="true"
    [routerLink]="['/organisation/app-shell/invite-students']"
  >
    <span class="pi pi-angle-left"></span>
    <span>Go Back</span>
  </p-button>

  <!-- Profile card -->
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-2 d-flex">
          <figure class="avatar lg cursor-pointer m-auto">
            <span class="fw-normal white-space-nowrap">{{
              globalData?.user_data?.firstName &&
                (globalData?.user_data?.firstName[0]?.toUpperCase() ?? "")
            }}</span>
          </figure>
        </div>
        <div class="col-12 col-md-10">
          <div class="">
            <div class="avatar-label fw-bold">
              {{ globalData?.user_data?.firstName }}
              {{ globalData?.user_data?.lastName }}
            </div>
            <div>
              <span class="avatar-email">{{
                globalData?.user_data?.email
              }}</span>
            </div>
            <div class="user-details mt-3">
              <div class="d-flex align-items-center gap-3">
                <span class="pi pi-map-marker"></span>
                <span>{{ globalData?.user_data?.country | titlecase }}</span>
              </div>
              <div class="d-flex align-items-center gap-3 mt-2">
                <span class="pi pi-calendar"></span>
                <span
                  >Joined {{ globalData?.user_data?.Created_at | date }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: Profile card -->

  <div class="row g-3">
    <!-- Time Spent -->
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div
            class="d-flex gap-3 flex-wrap align-items-center justify-content-between mb-3"
          >
            <h3 class="m-0">Time Spent</h3>
            <div>
              <p-selectButton
                [options]="yearOption"
                [(ngModel)]="timeSpentFilter"
                (onChange)="
                  onTimeSpentChange(
                    'timeSpentData',
                    this.timeSpentFilter,
                    'time_spent'
                  )
                "
                optionLabel="label"
                optionValue="value"
                [allowEmpty]="false"
                class="sm"
              ></p-selectButton>
            </div>
          </div>

          <!-- graph -->
          <div *ngIf="timeSpentData">
            <app-algoed-graph
              [graphType]="'bar'"
              [data]="timeSpentData"
              [barColors]="['#3348C6', '#D0D7FF']"
            ></app-algoed-graph>
          </div>
          <!-- graph -->
        </div>
      </div>
    </div>
    <!-- End: Time Spent -->
    <!-- Lesson Completed -->
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div
            class="d-flex gap-3 flex-wrap align-items-center justify-content-between mb-3"
          >
            <h3 class="m-0">Lesson Completed</h3>
            <div>
              <p-selectButton
                [options]="yearOption"
                [(ngModel)]="lessonCompletedFilter"
                (onChange)="
                  onTimeSpentChange(
                    'lessonCompletedData',
                    this.lessonCompletedFilter,
                    'lesson_completed'
                  )
                "
                optionLabel="label"
                optionValue="value"
                [allowEmpty]="false"
                class="sm custom-ternary"
              ></p-selectButton>
            </div>
          </div>

          <!-- graph -->
          <div *ngIf="lessonCompletedData">
            <app-algoed-graph
              [graphType]="'line'"
              [data]="lessonCompletedData"
            ></app-algoed-graph>
          </div>
          <!-- graph -->
        </div>
      </div>
    </div>
    <!-- End: Lesson Completed -->
    <!-- Questions -->
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div
            class="d-flex gap-3 flex-wrap align-items-center justify-content-between mb-3"
          >
            <h3 class="m-0">Questions Asked</h3>
            <div>
              <p-selectButton
                [options]="yearOption"
                [(ngModel)]="questionsFilter"
                (onChange)="
                  onTimeSpentChange(
                    'questionsData',
                    this.questionsFilter,
                    'question_asked'
                  )
                "
                [allowEmpty]="false"
                optionLabel="label"
                optionValue="value"
                class="sm"
              ></p-selectButton>
            </div>
          </div>

          <!-- graph -->
          <div *ngIf="questionsData">
            <app-algoed-graph
              [graphType]="'bar'"
              [data]="questionsData"
              [barColors]="['#006AFF', '#99C3FF']"
            ></app-algoed-graph>
          </div>
          <!-- graph -->
        </div>
      </div>
    </div>
    <!-- End: Questions -->
  </div>
</div>
