import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { QueryParamsFields } from '../../or-shared/or.interface';
import { OrAPIService } from '../../services/or-api.service';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss'],
})
export class StudentListComponent {
  // Input decorator
  @Input() selectedGroup: any;
  @Input() studentListPathApi: string;
  @Input() singleGroupDetailsApi: string;
  @Input() assignStudentToGroup: string;

  // Array types
  selectedStudents: any[] = [];
  studentList: any[] = [];
  gradYearList: number[] = [];

  // FormGroup type
  studentsFilter: FormGroup;

  // Number types
  totalRecords: number;
  rows: number = 5;
  first: number = 0;
  currentPage: number = 1;
  groupID: number;

  // Object type
  groupDetails: any;
  subscriptions: any[] = [];

  // Object type
  queryParams: QueryParamsFields = {
    page: 1,
    pageSize: 10,
  };

  constructor(
    private apiService: OrAPIService,
    private commonService: CommonServiceService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.getStudentList();
    this.studentsFilter = this.buildForm();
    this.subscriptions.push(
      this.commonService.$selectedGroup.subscribe((group) => {
        if (group) {
          this.groupDetails = group;
          this.groupID = +group.id;
          this.getGroupDetails();
        }
      })
    );
  }

  buildForm(): FormGroup {
    return this.fb.group({
      name: [],
      email: [],
      gradYear: [],
      groups: [],
      filterGroups: [],
    });
  }

  getStudentList() {
    this.apiService
      .getList(this.queryParams, this.studentListPathApi)
      .subscribe(
        (res: any) => {
          this.studentList = res.data.students;
          this.totalRecords = res.total;
        },
        (error) => {
          this.commonService.showToast(
            error.error?.error ?? 'Something went wrong',
            'warn',
            3000
          );
        }
      );
  }

  filterStudentList() {
    const filterFormValue = this.studentsFilter.value;
    this.queryParams = {
      page: 1,
      pageSize: 5,
      ...filterFormValue,
    };
    this.getStudentList();
  }

  clearFilters() {
    this.studentsFilter.reset();
    this.queryParams = {
      page: 1,
      pageSize: 5,
    };
    this.getStudentList();
  }

  getGroupDetails() {
    this.apiService.getById(this.singleGroupDetailsApi, this.groupID).subscribe(
      (res: any) => {
        this.selectedStudents = res.data.OrganizationStudents;
      },
      (error) => {
        this.commonService.showToast(
          'Error while fetching group details',
          'error',
          1000
        );
      }
    );
  }

  onPage(e: any) {
    const pageNumber: number = e.first / e.rows;
    this.queryParams = {
      page: pageNumber + 1,
      pageSize: e.rows,
      isAccepted: this.queryParams.isAccepted,
    };
    this.getStudentList();
  }

  updateStudents() {
    const groupID = this.groupID;
    let students = [];
    students = this.selectedStudents.map((x) => x.id);
    const data = {
      isCategorList: true,
      name: { students: students },
    };
    this.apiService.assignStudentToGroup(this.assignStudentToGroup,groupID, data).subscribe(
      (res) => {
        this.commonService.showToast(
          'Students updated successfully',
          'success',
          1000
        );
      },
      (error) => {
        this.commonService.showToast('Somethings went wrong', 'error', 3000);
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription && subscription.unsubscribe();
    });
  }
}
