import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  baseURL: string = environment.apiUrl + 'vimeo';
  baseURLVimeo: string = environment.vimeoUrl;

  baseLocalS3URL: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  //change
  storeVideoToS3(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/storeVideoToS3`, object);
  }

  //getAllVideos
  getAllVideos(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getAllVideos`, object);
  }

  //changeTitle
  changeTitle(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeTitle`, object);
  }

  saveRecord(data: any): Observable<any> {
    return this.http.post(`${this.baseLocalS3URL}admin/videos/add`, data);
  }

  //upload
  upload(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/upload`, object);
  }

  uploadVideoToGetLocalPath(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadVideoToGetLocalPath`, object);
  }

  uploadLocalVideoToGetLocalPath(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadVideoToGetLocalPath`, object);
  }

  uploadVideoToGetLocalPathNew(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadVideoToGetLocalPath`, object, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadVideoToGetS3StoragePath(object: any): Observable<any> {
    return this.http.post(`${this.baseLocalS3URL}admin/videos/upload`, object, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadVideoQandA(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadVideoQandA`, object);
  }

  changeTitleQandAVideo(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeTitleQandAVideo`, object);
  }

  deleteQandAVideo(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteQandAVideo`, object);
  }

  getAllInspirationalVideos(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getAllInspirationalVideos`, object);
  }

  deleteVideo(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteVideo`, object);
  }

  deleteVideoFromList(id: number): Observable<any> {
    return this.http.delete(`${this.baseLocalS3URL}admin/videos/${id}`);
  }

  uploadVideoInspiration(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadVideoInspiration`, object);
  }

  getAllRegularVideos(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getAllRegularVideos`, object);
  }

  changeTitleVideoModule(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeTitleVideoModule`, object);
  }

  uploadVideoNotes(formdata: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadVideoNotes`, formdata);
  }

  /**
   * Retrieves a Vimeo video iframe based on the provided request.
   *
   * @param {any} req - The request object to specify the video iframe.
   * @return {Observable<any>} An Observable containing the Vimeo video iframe.
   */
  getVimeoVideoIframe(req: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Authorization', 'Bearer f2201799defdbc91cccc4ee24ca80511')
      .set('Content-Type', 'application/json');
    return this.http.get(
      `${this.baseURLVimeo}/${req}?responsive=true&height=450px&width=100%&max-height=450px`,
      { headers: headers }
    );
  }
  updateVideoOrderBy(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/updateVideoOrderBy`, object);
  }

  updateQandAVideoOrderBy(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/updateQandAVideoOrderBy`, object);
  }

  getVimeoChannelIds(object: any): Observable<any> {
    // return this.http.post(`${this.baseURL}/getVimeoChannelIds`, object);  old PROD
    return this.http.get(
      `${this.baseLocalS3URL}common/vimeo/channels/${object.data.id}`
    );
  }

  //Upload Reading Module
  uploadReadingModule(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/uploadReadingModule`, object);
  }

  deleteModuleNotes(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteModuleNotes`, object);
  }
  deleteModuleTask(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteModuleTask`, object);
  }
}
