<section class="new-login-wrapper">
  <div class="login-page-loader" *ngIf="isLoading$ | async; else content">
    <!-- Optional: Add a loading spinner or placeholder here -->
    <p class="invisible">Loading...</p>
  </div>
  <ng-template #content>
    <div
      [ngClass]="{
        'login-static-section': true,
        'auth-left-content align-items-start justify-content-start':
          !competitionImages ||
          competitionImages.login_image_desktop == null ||
          competitionImages.login_image_mobile == null,
        'competitions-bg': competitionImages && isImageAvailableAfterLoad
      }"
    >
      <figure
        class="m-0"
        *ngIf="
          competitionImages && isImageAvailableAfterLoad;
          else defaultImage
        "
      >
        <img
          [ngSrc]="
            isMobileView
              ? imageBaseURL +
                'common/getfile/' +
                competitionImages?.login_image_mobile
              : imageBaseURL +
                'common/getfile/' +
                competitionImages?.login_image_desktop
          "
          class="img-fluid hero-img"
          alt="Login bg"
          width="100"
          height="100"
        />
      </figure>

      <!-- Default new login View -->
      <ng-template #defaultImage>
        <div
          class="position-relative default-content d-flex flex-column h-100 gap-4 justify-content-between"
        >
          <section>
            <h2 class="login-title mt-0 mx-0">
              Curated
              <span class="text-competitions p-2">Competitions</span> and
              <br />
              <span class="text-research">Research</span> Opportunities
            </h2>

            <p class="text-subtitle mt-0">for a Richer Education</p>

            <p class="text-follow mt-0">Follow us on</p>
            <div class="social-icons d-flex align-items-center gap-3 gap-lg-4">
              <a
                href="https://www.instagram.com/algoed.official/"
                class="d-flex flex-shrink-0"
                target="_blank"
              >
                <img
                  src="../../assets/images/login/insta.png"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.facebook.com/algoed.official"
                class="d-flex flex-shrink-0"
                target="_blank"
              >
                <img src="../../assets/images/login/fb.png" alt="Fb" />
              </a>
            </div>
          </section>

          <section class="d-flex flex-column gap-4 gap-xxl-5 flex-shrink-0">
            <!-- dot group -->
            <div class="d-flex flex-column flex-grow-1">
              <img
                class="img-plus"
                src="../../assets/images/login/plus.png"
                alt="plus"
              />
              <img
                class="img-dot-group"
                src="../../assets/images/login/dot-group.png"
                alt="Dots"
              />
            </div>
            <div class="d-none d-lg-block flex-shrink-0">
              <img
                class="logo-w"
                src="../../assets/icons/logo-2.svg"
                alt="Logo"
              />
            </div>
          </section>
        </div>

        <img
          class="img-circle"
          src="../../assets/images/login/circle.svg"
          alt="cricle"
        />
        <!-- <img
          class="img-play"
          src="../../assets/images/login/play.svg"
          alt="Play"
        /> -->
        <!-- banner girl -->
        <img
          class="login-banner-girl"
          src="../../assets/images/login/login-banner.png"
          alt="Banner"
        />
      </ng-template>
      <!-- Default new View -->
    </div>
  </ng-template>

  <app-student-login
    *ngIf="!isOrganisationInvite"
    class="login-content-section flex-grow-1"
  ></app-student-login>

  <app-or-invite-signup
    *ngIf="isOrganisationInvite"
    class="login-content-section flex-grow-1"
  ></app-or-invite-signup>
</section>
