import { Component } from '@angular/core';
import { CommonServiceService } from '../../common-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mainConstants } from '../../constants/main.constants';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-grade-andparent-email',
  templateUrl: './grade-andparent-email.component.html',
  styleUrls: ['./grade-andparent-email.component.scss'],
})
export class GradeAndparentEmailComponent {
  studentForm: FormGroup;
  nextSixYears = mainConstants.nextSixYears;

  constructor(
    private commonservice: CommonServiceService,
    private fb: FormBuilder,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit() {
    const emailRegex = mainConstants.emailRegex;
    this.studentForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      phone: [''],
      parentEmailId: [
        '',
        [Validators.required, Validators.pattern(emailRegex)],
      ],
      country: [''],
      gender: [''],
      current_grade: ['', Validators.required],
      sendMarketingEmails: [''],
      isEighteenPlus: [''],
      school: [''],
      unitedStates: [''],
      category: [null],
    });
  }

  onSubmitDetails() {
    if (
      this.studentForm.controls['parentEmailId'] &&
      this.studentForm.controls['parentEmailId']?.errors &&
      this.studentForm.controls['parentEmailId']?.errors['pattern']
    ) {
      this.commonservice.showToast(
        'Please enter a valid email address',
        'warn',
        2000,
        'Info'
      );
      return;
    }
    if (!this.studentForm.valid) {
      this.commonservice.showToast(
        'Please fill all the required fields',
        'warn',
        2000,
        'Info'
      );
      return;
    }
    let data = this.studentForm.value;
    // data['Updated_by'] = this.userData.id || this.userData.userId;
    // data['userId'] = this.userData.id || this.userData.userId;
    // this.isDetailFormVisible = false;
    this.commonservice.editStudentDataFromStudentProfile({ data }).subscribe(
      (item) => {
        this.commonservice.showToast(
          'User Info updated',
          'success',
          2000,
          'Info'
        );
        this.commonservice.$curUser.next(item.data);
        localStorage.setItem('user', JSON.stringify(item.data));
        this.dialogRef.close(item.data);
      },
      (err) => {
        this.commonservice.showToast(
          'Something went wrong!',
          'warn',
          2000,
          'Info'
        );
      }
    );
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
