import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundPercentage',
})
export class RoundPercentagePipe implements PipeTransform {
  transform(value: any, round: any) {
    const roundDetails = value.rounds.find((round) => {
      return round.round_number == value.current_round;
    });

    if (roundDetails && round.round_number == value.current_round) {
      const percentage =
        (roundDetails.fully_submitted / roundDetails.total_submissions) * 100;
      console.log('round percentage', percentage);
      return percentage;
    } else {
      return 0;
    }
  }
}
