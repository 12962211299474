import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MastersService {
  baseURL: string = environment.apiUrl + 'masters';
  baseURLPayment: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  addVideo(id: number, object: any): Observable<any> {
    return this.http.post(`${this.baseURLPayment}admin/videos`, object);
  }

  updateVideo(id: number, object: any): Observable<any> {
    return this.http.put(
      `${this.baseURLPayment}admin/videos/${id}/update`,
      object
    );
  }

  getVideoDetailsByID(id: number): Observable<any> {
    return this.http.get(`${this.baseURLPayment}admin/video/${id}`);
  }

  //addDocumentData
  addDocumentData(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addDocumentData`, object);
  }

  //addDiaryCategory
  addDiaryCategory(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addDiaryCategory`, object);
  }
  //changeDocumentData
  changeDocumentData(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeDocumentData`, object);
  }

  //changeDiaryCategory
  changeDiaryCategory(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeDiaryCategory`, object);
  }
  //removeDocument
  removeDocument(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeDocument`, object);
  }

  //removeDiaryCategory
  removeDiaryCategory(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeDiaryCategory`, object);
  }

  //removeDiaryCategory
  addGradeLevel(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addGradeLevel`, object);
  }

  //changeGradeLevel
  changeGradeLevel(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeGradeLevel`, object);
  }

  //removeGradeFromGradeLevels
  removeGradeFromGradeLevels(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeGradeFromGradeLevels`, object);
  }

  // get email templates
  getEmailTemplates(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getEmailTemplates`, object);
  }

  // get both us uk program eligibility
  getProgramEligibility(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getProgramEligibility`, object);
  }

  // update both us uk program eligibility
  updateProgramEligibility(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/updateProgramEligibility`, object);
  }

  //getUniversity feature Groups
  getUniversity_featureGroup(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getUniversity_featureGroup`, object);
  }

  //getMaster_featureGroup
  getMaster_featureGroup(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getMaster_featureGroup`, object);
  }

  //getMaster_features
  getMaster_features(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getMaster_features`, object);
  }

  //addUniversity_featureGroup
  addUniversity_featureGroup(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addUniversity_featureGroup`, object);
  }

  //removeUniversity_featureGroup
  removeUniversity_featureGroup(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/removeUniversity_featureGroup`,
      object
    );
  }

  //getVideoTaskCategories
  getVideoTaskCategories(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getVideoTaskCategories`, object);
  }

  //addVideoTaskCategory
  addVideoTaskCategory(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addVideoTaskCategory`, object);
  }

  //changeVideoTaskCategory
  changeVideoTaskCategory(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeVideoTaskCategory`, object);
  }

  //changeVideoTaskCategory
  removeVideoTaskCategory(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeVideoTaskCategory`, object);
  }

  //addVideoTaskCategoryDetails
  addVideoTaskCategoryDetails(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addVideoTaskCategoryDetails`,
      object
    );
  }

  //changeVideoTaskCategoryDetails
  changeVideoTaskCategoryDetails(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/changeVideoTaskCategoryDetails`,
      object
    );
  }

  //removeVideoTaskCategoryDetails
  removeVideoTaskCategoryDetails(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/removeVideoTaskCategoryDetails`,
      object
    );
  }

  // change Email Template Status
  changeEmailTemplateStatus(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeEmailTemplateStatus`, object);
  }

  changeEmailTemplate(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeEmailTemplate`, object);
  }

  getTerms(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getTerms`, object);
  }

  addTerm(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addTerm`, object);
  }

  changeTerm(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeTerm`, object);
  }

  removeTerm(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeTerm`, object);
  }

  addCredit_essay(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addCredit_essay`, object);
  }

  changeCredit_essay(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changeCredit_essay`, object);
  }

  removeCredit_essay(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/removeCredit_essay`, object);
  }
  getCredit_essay(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getCredit_essay`, object);
  }

  getCredit_activityList(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getCredit_activityList`, object);
  }

  addUpdateCredit_activityList(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/addUpdateCredit_activityList`,
      object
    );
  }

  getPricing_inspitationSeriesVideos(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getPricing_inspitationSeriesVideos`,
      object
    );
  }

  changePricing_inspitationSeriesVideos(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/changePricing_inspitationSeriesVideos`,
      object
    );
  }

  getPaymentSettings(): Observable<any> {
    return this.http.post(`${this.baseURL}/getPaymentSettings`, {});
  }

  changePaymentSetting(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changePaymentSetting`, object);
  }

  changePlanMaster(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/changePlanMaster`, object);
  }

  getCalendarCreditsRequired(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getCalendarCreditsRequired`, object);
  }

  updateCalendarCreditsRequired(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/updateCalendarCreditsRequired`,
      object
    );
  }
  getActivityUserCalendar(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getActivityUserCalendar`, object);
  }

  addActivityUserCalendar(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/addActivityUserCalendar`, object);
  }
  updateActivityUserCalendar(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/updateActivityUserCalendar`, object);
  }
  deleteActivityUserCalendar(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/deleteActivityUserCalendar`, object);
  }

  getCalendarActivityTypeForms(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getCalendarActivityTypeForms`,
      object
    );
  }

  getCountries(object?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}common/countries`);
    // return this.http.post(`${this.baseURL}/getCountries`, object); prod
  }

  getCareersFromMaster(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getCareersFromMaster`, object);
  }

  getVideoTaskCategoriesDetails(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/getVideoTaskCategoriesDetails`,
      object
    );
  }

  getNotifications(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getNotifications`, object);
  }

  updateNotification(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/updateNotification`, object);
  }

  getSubjectList(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getSubjectList`, object);
  }
  updateVideoTaskCategoryOrderBy(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/updateVideoTaskCategoryOrderBy`,
      object
    );
  }

  getStripeKey(object: any): Observable<any> {
    return this.http.post(`${this.baseURL}/getStripeSetting`, {});
  }

  getClientKey(amount: any): Observable<any> {
    return this.http.post(`${this.baseURLPayment}users/stripe`, amount);
  }

  getClientKeyForCompetition(data: any): Observable<any> {
    return this.http.post(
      `${this.baseURLPayment}users/competitions/payment/init`,
      data
    );
  }

  getPublicKey(): Observable<any> {
    return this.http.get(`${this.baseURLPayment}users/stripe/key`);
  }

  mergeTemplate(draft: any[] = [], assigned: any[] = []) {
    const validDraft = Array.isArray(draft) ? draft : [];
    const validAssigned = Array.isArray(assigned) ? assigned : [];
    const draftWithFlag = validDraft.map((item) => ({
      ...item,
      isAssigned: false,
    }));
    const assignedWithFlag = validAssigned.map((item) => ({
      ...item,
      isAssigned: true,
    }));
    const mergedArray = [...draftWithFlag, ...assignedWithFlag];
    return mergedArray;
  }
}
