<div>
  <div class="d-grid share-items gap-3 mt-2">
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      *ngFor="let button of sharableButtons"
    >
      <span class="share-icon text-center cursor-pointer" (click)="handleButtonClick(button.id)">
        <!-- <i class="{{ button.icon }}  share-icon-size"></i> -->
        <img
          [src]="button.icon"
          [width]="button.id == 1 ? 35 : 60"
          alt=""
          srcset=""
          class="img-fluid"
        />
      </span>
      <p class="share-text">{{ button.name }}</p>
    </div>
  </div>
  <div class="p-inputgroup w-full md:w-30rem link-parent mt-3">
    <input
      class="link-text-box"
      type="text"
      pInputText
      placeholder="Link"
      [(ngModel)]="shareVideoLink"
      [disabled]="true"
    />
    <span
      class="p-inputgroup-addon cursor-pointer copy-btn"
      (click)="copyToClipboard()"
      >Copy</span
    >
  </div>
</div>
