<div class="card-container">
  <div class="auth-content card-padding">
    <div class="step_one">
      <div class="text-lg-start text-center algoed_logo">
        <img src="../../assets/icons/logo-2.svg" alt="algoedlogo" srcset="" />
      </div>
      <div class="d-flex justify-content-center justify-content-lg-start my-3 my-lg-4">
        <span
          class="cursor-pointer pi pi-arrow-left me-2 mt-1 pt-1"
          *ngIf="stepNumber !== 1 && isFromLogin"
          (click)="goBack()"
        ></span>
        <h3 class="text-lg-start my-0 text-center">
          {{
            stepNumber == 3
              ? "Enter the 6-digit code sent to you at: " + emailid
              : "Create your account"
          }}
        </h3>
      </div>
      <form [formGroup]="singupForm" class="card-container h-100">
        <div class="form-group mb-4" *ngIf="stepNumber == 2">
          <div class="mb-2">
            <label for="Email" class="block mb-0">Full Name</label>
          </div>
          <div class="w-100">
            <input
              pInputText
              class="p-inputtext-lg"
              placeholder="Enter your full name"
              formControlName="fullName"
            />
          </div>
        </div>
        <div class="form-group mb-4" *ngIf="stepNumber == 1 || stepNumber == 2">
          <div class="mb-2">
            <label for="Email" class="block mb-0">Email</label>
          </div>
          <div class="w-100">
            <input
              pInputText
              class="p-inputtext-lg"
              placeholder="Enter your email address"
              formControlName="email"
            />
          </div>
        </div>
        <div class="form-group mb-4" *ngIf="stepNumber == 2">
          <div class="mb-2">
            <label for="Password" class="block mb-0">Password</label>
          </div>
          <div class="form-control-password">
            <input
              id="password"
              [type]="showPass ? 'text' : 'password'"
              formControlName="password"
              pInputText
              placeholder="Password"
              class="p-inputtext-lg"
            />
            <i
              style="cursor: pointer"
              class="pi"
              [ngClass]="{ 'pi-eye': !showPass, 'pi-eye-slash': showPass }"
              (click)="showPassFn()"
            ></i>
          </div>
          <small
            class="text-danger"
            *ngIf="password.errors && password.errors['minlength']"
          >
            Password must be at least 8 characters
          </small>
        </div>
        <div
          class="d-flex align-items-start gap-3 mb-4"
          *ngIf="stepNumber == 2"
        >
          <p-checkbox
            class="primary-blue"
            name="groupname"
            formControlName="sendMarketingEmails"
            [binary]="true"
          ></p-checkbox>
          <span class="subscribe-text">
            {{ marketingLabel }}
          </span>
        </div>
        <ng-container *ngIf="stepNumber == 3">
          <div class="my-4 mb-3">
            <ng-otp-input
              (onInputChange)="onOtpChange($event)"
              [config]="{ length: 6 }"
            ></ng-otp-input>
          </div>
          <div class="mt-3">
            <span *ngIf="otpTimer" class="otpTimer">{{ otpTimer }}</span>
          </div>
          <div class="mt-2 mb-4 resend-text">
            Didn't receive the code?
            <span class="cursor-pointer" (click)="sendOtp()">Resend</span>
          </div>
        </ng-container>
        <div>
          <button
            class="btn btn-auth-primary w-100 d-flex justify-content-center align-items-center gap-2"
            (click)="createAccount()"
            [disabled]="singupForm.invalid || (stepNumber == 3 && !otp)"
          >
            <span *ngIf="stepNumber == 1" class="d-flex">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3334 0.666748H2.66671C1.65837 0.666748 0.842541 1.49175 0.842541 2.50008L0.833374 13.5001C0.833374 14.5084 1.65837 15.3334 2.66671 15.3334H17.3334C18.3417 15.3334 19.1667 14.5084 19.1667 13.5001V2.50008C19.1667 1.49175 18.3417 0.666748 17.3334 0.666748ZM17.3334 4.33341L10 8.91675L2.66671 4.33341V2.50008L10 7.08341L17.3334 2.50008V4.33341Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>
              {{
                stepNumber == 1
                  ? "Continue with Email"
                  : stepNumber == 2
                  ? "Create Account"
                  : "Verify"
              }}</span
            >
          </button>
        </div>
        <ng-container *ngIf="stepNumber == 2">
          <div class="my-4 terms-text text-center">
            By signing up, you confirm that you have read, understood and agree
            to be bound by the
            <a
              class="text-decoration-underline"
              [href]="redirectLinks.termsAndConditions"
              target="_blank"
              >Terms & Conditions</a
            >
            and consent to the
            <a
              class="text-decoration-underline"
              [href]="redirectLinks.privacyPolicy"
              target="_blank"
              >Privacy Policy</a
            >.
          </div>
        </ng-container>
        <ng-container>
          <div class="follow-text dark my-4" *ngIf="stepNumber == 1">
            <span>or</span>
          </div>
          <div
            class="g-auth-btn-wrapper"
            [style.visibility]="stepNumber == 1 ? 'visible' : 'hidden'"
          >
            <div
              class="d-none"
              id="g_id_onload"
              data-client_id="649575828392-a3nal40mm05noc64bmb8tgcmhme6fdf3.apps.googleusercontent.com"
              data-context="signin"
              data-ux_mode="popup"
              data-callback="handleCredentialResponse"
              data-auto_prompt="false"
            ></div>

            <div
              id="g_id_signin"
              class="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="center"
              data-width="280"
            ></div>
          </div>
          <div class="border-seperator my-4" *ngIf="stepNumber == 1"></div>
        </ng-container>
        <div class="text-center" *ngIf="stepNumber !== 3">
          <span class="auth-text" (click)="navigateToLogin()"
            >Already have an account? <a style="cursor: pointer">Login</a></span
          >
        </div>
      </form>
    </div>
  </div>
</div>
