import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'registrationCheck',
})
export class RegistrationCheckPipe implements PipeTransform {
  transform(item: any): boolean {
    if (item.status == 'Finished') {
      console.log(item);
      if (!item || !item.rounds) {
        return false;
      }

      const roundDetails = item.rounds.find((round) => {
        return round.round_number == item.current_round;
      });

      const roundEnd = new Date(roundDetails.round_end);
      const currentTime = new Date();
      const totalSubmissions = roundDetails.total_submissions;
      const fullySubmitted = roundDetails.fully_submitted;

      return currentTime > roundEnd && totalSubmissions - fullySubmitted > 0;
    } else {
      return false;
    }
  }
}
