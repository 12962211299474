import { Component } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import {
  QueryParamsFields,
  StudentInvitation,
} from '../../or-shared/or.interface';
import { OrAPIService } from '../../services/or-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-or-invite-student',
  templateUrl: './or-invite-student.component.html',
  styleUrls: ['./or-invite-student.component.scss'],
})
export class OrInviteStudentComponent {
  private searchSubject = new Subject<string>();

  inviteStudentsList: StudentInvitation[] = [];
  tempList: StudentInvitation[] = [];

  totalRecords: number;
  inviteStudentEmailId: string;
  user: number;
  isFileUploading: boolean;
  isDeleteDialogVisible: boolean;
  studentsToDelete: any[] = [];
  finalStudentToDelete: any;
  inviteFilter: any = null;
  searchText: string = null;
  invitePath: string = 'org/invite';
  inviteStudentEndpoints: string = 'org/inviteAll';
  studentDetailsPagePath: string = 'admin/organisation-details/';

  deleteEndPoint: string;

  queryParams: QueryParamsFields = {
    page: 1,
    pageSize: 10,
    isAccepted: null,
  };

  constructor(
    private apiService: OrAPIService,
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getEndPoints();
    this.triggerSearch();
  }

  getEndPoints() {
    this.user = +this.router.url.split('/')[3];
    const route = this.router.url;

    switch (route) {
      case `/admin/organisation-details/${this.user}/manage-students`:
        this.invitePath = `admin/organizations/${this.user}/students/invite`;
        this.inviteStudentEndpoints = `admin/organizations/${this.user}/students/invite`;
        this.inviteStudentEndpoints = `admin/organizations/${this.user}/students/invite/bulk`;
        this.deleteEndPoint = `admin/organizations/${this.user}/students/remove`;
        break;

      case `/organisation/app-shell/invite-students`:
        this.invitePath = 'org/invite';
        this.inviteStudentEndpoints = 'org/inviteAll';
        this.deleteEndPoint = 'org/students/remove';
        break;
    }
  }

  toggleDeleteDialog(studentDetails: any, isopen: boolean) {
    this.isDeleteDialogVisible = true;
    let objToDelete;
    if (this.studentsToDelete.length) {
      objToDelete = {
        inviteid: this.studentsToDelete.map((x) => x.id),
        id: this.studentsToDelete.map((x) => x.orgStudentId),
      };
    }
    const isCorrectButtonPressed = this.studentsToDelete.find(
      (student) => student.id == studentDetails.id
    );
    if (!isCorrectButtonPressed) {
      this.studentsToDelete = [];
      objToDelete = {
        inviteid: [],
        id: [],
      };
      objToDelete = {
        inviteid: [studentDetails.id],
        id: [studentDetails.orgStudentId],
      };
    } else {
    }
    this.finalStudentToDelete = { ...objToDelete };
  }

  deleteStudents(isDelete: boolean) {
    if (isDelete) {
      this.apiService
        .saveData(this.deleteEndPoint, {
          isCategorList: true,
          name: this.finalStudentToDelete,
        })
        .subscribe(
          (res) => {
            this.commonService.showToast(
              'Record Deleted Successfully',
              'success',
              1000
            );
            this.getInvitedStudents();
            this.isDeleteDialogVisible = false;
          },
          (err) => {
            this.commonService.showToast('Delete Invite Failed', 'warn', 1000);
            this.isDeleteDialogVisible = false;
            this.commonService.showToast('error', 'error', err.error);
          }
        );
    } else {
      this.studentsToDelete = [];
      this.finalStudentToDelete = null;
      this.isDeleteDialogVisible = false;
    }
  }

  onSearch(searchText: string) {
    if (!searchText) {
      this.queryParams = {
        page: 1,
        pageSize: 5,
        q: '',
      };
      this.getInvitedStudents();
    }
    this.queryParams.q = searchText;
    return this.apiService.getList(this.queryParams, this.invitePath);
  }

  handleSearchResults(searchResults: any) {
    this.totalRecords = searchResults.total;
    if (searchResults.data.invites && searchResults.data.invites.length) {
      this.inviteStudentsList = searchResults.data.invites;
      this.tempList = searchResults.data.invites;
      this.totalRecords = searchResults.data.total;
    } else {
      this.inviteStudentsList = [];
    }
  }

  searchStudent() {
    if (!this.searchText) {
      this.inviteStudentsList = this.tempList;
    }
    this.searchSubject.next(this.searchText);
  }

  triggerSearch() {
    this.searchSubject
      .pipe(
        debounceTime(700),
        distinctUntilChanged(),
        switchMap((searchText: string) => {
          if (searchText) {
            this.searchText = searchText;
            return this.apiService.getList(this.queryParams, this.invitePath);
          } else {
            return [];
          }
        })
      )
      .subscribe(
        (res) => {
          this.inviteStudentsList = res.data.invites;
          this.tempList = res.data.invites;
          this.totalRecords = res.data.total;
        },
        (error) => {
          this.commonService.showToast(
            error.error?.error ?? 'Something went wrong',
            'warn',
            3000
          );
        }
      );
  }

  getInvitedStudents() {
    this.apiService.getList(this.queryParams, this.invitePath).subscribe(
      (res: any) => {
        this.inviteStudentsList = res.data.invites;
        this.tempList = res.data.invites;
        this.totalRecords = res.data.total ?? this.inviteStudentsList.length;
      },
      (error: any) => {
        this.commonService.showToast(error.error.error, 'error', 1000);
      }
    );
  }

  getInviteStudentsList() {
    this.queryParams = {
      page: 1,
      pageSize: 5,
      isAccepted: this.inviteFilter == 0 ? '0' : this.inviteFilter,
    };
    this.getInvitedStudents();
  }

  onPage(e: any) {
    const pageNumber: number = e.first / e.rows;
    this.queryParams = {
      page: pageNumber + 1,
      pageSize: e.rows,
      isAccepted: this.queryParams.isAccepted,
    };
    this.getInvitedStudents();
  }

  inviteStudent(record: any) {
    if (!this.inviteStudentEmailId) {
      this.commonService.showToast('Please enter email', 'error', 1000);
      return;
    }
    this.inviteStudentEmailId = record?.email ?? this.inviteStudentEmailId;
    const data = {
      isCategorList: true,
      name: { emailValues: this.inviteStudentEmailId },
    };
    this.apiService.inviteStudent(this.inviteStudentEndpoints, data).subscribe(
      (res) => {
        this.getInvitedStudents();
        this.inviteStudentEmailId = null;
        this.commonService.showToast('Student Invited', 'success', 1000);
      },
      (error) => {
        this.inviteStudentEmailId = null;
        this.commonService.showToast(error.error.error, 'error', 1000);
      }
    );
  }

  handleFileInput(event: Event) {
    this.isFileUploading = true;
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      const formData = new FormData();
      formData.append('file', selectedFile);
      const data = {
        isCategorList: true,
        name: formData,
      };
      this.apiService
        .inviteBulkStudents(this.inviteStudentEndpoints, data)
        .subscribe(
          (response) => {
            console.log('File upload successful', response);
            this.commonService.showToast(
              'File uploaded successfully',
              'success',
              1000
            );
            this.isFileUploading = false;
          },
          (error) => {
            this.commonService.showToast(error.error, 'error', 1000);
            this.isFileUploading = false;
          }
        );
    }
  }

  public videDetails(organisation) {
    this.user = +this.router.url.split('/')[3];
    const route = this.router.url;

    switch (route) {
      case `/admin/organisation-details/${this.user}/manage-students`:
        this.studentDetailsPagePath = `admin/organisation-details/${this.user}/student-details/${organisation.orgStudentId}`;
        break;

      case `/organisation/app-shell/invite-students`:
        this.studentDetailsPagePath = `organisation/app-shell/student-details/${organisation.orgStudentId}`;
        break;
    }
    if (organisation.orgStudentId) {
      this.router.navigate([
        this.studentDetailsPagePath
      ]);
    }
  }
}
