import { Injectable } from '@angular/core';
import { CommonServiceService } from '../common-service.service';

@Injectable({
  providedIn: 'root',
})
export class ShareButtonClickService {
  constructor(private commonService: CommonServiceService ) {}

  handleButtonClick(buttonId: number, shareLink: string): void {
    const encodedShareLink = encodeURIComponent(shareLink);

    switch (buttonId) {
      case 1:
        // Facebook
        const facebookLink =
          'https://www.facebook.com/sharer/sharer.php?u=' + encodedShareLink;
        this.openNewTab(facebookLink);
        break;
      case 2:
        // Twitter
        const twitterLink =
          'https://twitter.com/intent/tweet?url=' + encodedShareLink;
        this.openNewTab(twitterLink);
        break;
      case 3:
        // LinkedIn
        const linkedinLink =
          'https://www.linkedin.com/sharing/share-offsite/?url=' +
          encodedShareLink;
        this.openNewTab(linkedinLink);
        break;
      case 4:
        // WhatsApp
        const whatsappLink =
          'https://api.whatsapp.com/send?text=' + encodedShareLink;
        this.openNewTab(whatsappLink);
        break;
      case 5:
        // Snapchat
        const snapchatLink = 'https://www.snapchat.com/add/' + encodedShareLink;
        this.openNewTab(snapchatLink);
        break;
      case 6:
        // Gmail
        const gmailLink =
          'https://mail.google.com/mail/?view=cm&fs=1&su=Subject&body=' +
          encodedShareLink;
        this.openNewTab(gmailLink);
        break;
      case 100:
        // Copy Link
        this.copyToClipboard(shareLink);
        break;
      default:
        console.warn('Invalid button ID');
    }
  }

  openNewTab(url: string): void {
    window.open(url, '_blank');
  }

  copyToClipboard(shareLink: string): void {
    navigator.clipboard.writeText(shareLink);
    this.commonService.showToast('Link Copied', 'success', 1000);
  }
}
