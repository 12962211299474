import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
declare const google: any;
@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  sendToken(idToken: string): Observable<any> {
    return this.http.post(`${this.baseUrl}users/googleAuth`, { idToken });
  }

  createScriptElement() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    // script.onload = this.loadGoogle;
    return script;
  }
}
