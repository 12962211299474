import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/services/admin.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { mainConstants } from 'src/app/shared/constants/main.constants';
import { PersonalityTestTypes } from 'src/app/shared/models/shared.interface';

@Component({
  selector: 'app-personality-test',
  templateUrl: './personality-test.component.html',
  styleUrls: ['./personality-test.component.scss'],
})
export class PersonalityTestComponent implements OnInit {
  testTypes: PersonalityTestTypes[] = mainConstants.personalityTestTypes;
  subscription: Subscription;
  selectedGrade: any;
  isStudentPortal: boolean;

  constructor(
    private adminService: AdminService,
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('admin/manage-students/student-details/')) {
      this.getAdminStudentData();
      this.isStudentPortal = false;
    } else {
      this.isStudentPortal = true;
      this.getPersonalityTest();
    }
    this.subscription = this.commonService
      .triggerChildMethod()
      .subscribe((res) => {
        this.saveTest();
      });
  }

  getAdminStudentData() {
    this.adminService.studentData.asObservable().subscribe((res) => {
      this.selectedGrade =
        res?.profile_data?.personality_test?.personalityTestCode;
    });
  }

  getPersonalityTest() {
    this.commonService
      .getQuestionDetails('users/profile/personality')
      .subscribe(
        (res) => {
          this.selectedGrade = res?.data?.personalityTestCode ?? null;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  saveTest() {
    if (this.isStudentPortal) {
      const testData = {
        personalityTestCode: this.selectedGrade,
      };
      const finalData = {
        isCategorList: true,
        name: testData,
      };
      this.commonService
        .saveRecord('users/profile/personality', finalData)
        .subscribe(
          (res) => {
            this.commonService.showToast(
              'Personality Test updated',
              'success',
              3000,
              'Info'
            );
          },
          (error) => {
            this.commonService.showToast(
              'Personality Test not updated',
              'error',
              3000
            );
          }
        );
    } else {
      this.commonService.showToast(
        'Admin Cant Update Personality Test',
        'error',
        2000
      );
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
