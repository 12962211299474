<div class="my-3">
  <div
    class="timeline-toppers"
    [ngClass]="{
      'most-lessons': keyName === 'topLessonsCompleted',
      'most-time': keyName === 'topUser',
      'most-questions': keyName === 'topQuestionAsked',
    }"
  >
    <div class="position-relative" *ngIf="secondRank">
      <div class="topper-details second text-center">
        <figure
          class="topper-avatar mx-auto"
          [ngClass]="{
          'most-lessons-avatar': keyName === 'topLessonsCompleted',
          'most-time-avatar': keyName === 'topUser',
          'most-questions-avatar': keyName === 'topQuestionAsked',
        }"
        >
          <span class="fw-normal white-space-nowrap">{{
            secondRank?.firstName[0].toUpperCase()
          }}</span>
        </figure>
        <h6 class="topper-title mx-auto">
          {{ secondRank?.firstName | titlecase }}
        </h6>
        <p class="topper-email text-truncate w-100 mx-auto">
          {{ secondRank?.email }}
        </p>
        <span class="toppper-time">{{
          keyName == "topUser"
            ? (secondRank?.active_hours | number:'1.1-1') + "min"
            : keyName == "topLessonsCompleted"
            ? secondRank?.lesson_completed
            : secondRank?.topQuestionAsked
        }}</span>
      </div>

      <div class="topper second">
        <span class="topper-numbering">2</span>
      </div>
    </div>
    <div class="position-relative" *ngIf="firstRank">
      <div class="topper-details first text-center">
        <figure
          class="topper-avatar mx-auto"
          [ngClass]="{
          'most-lessons-avatar': keyName === 'topLessonsCompleted',
          'most-time-avatar': keyName === 'topUser',
          'most-questions-avatar': keyName === 'topQuestionAsked',
        }"
        >
          <span class="fw-normal white-space-nowrap">{{
            firstRank?.firstName[0].toUpperCase()
          }}</span>
        </figure>
        <h6 class="topper-title mx-auto">
          {{ firstRank?.firstName | titlecase }}
        </h6>
        <p class="topper-email text-truncate w-100 mx-auto">
          {{ firstRank?.email }}
        </p>
        <span class="toppper-time">{{
          keyName == "topUser"
            ? (firstRank?.active_hours | number:'1.1-1') + "min"
            : keyName == "topLessonsCompleted"
            ? firstRank?.lesson_completed
            : firstRank?.topQuestionAsked
        }}</span>
      </div>

      <div class="topper first">
        <span class="topper-numbering">1</span>
      </div>
    </div>
    <div class="position-relative" *ngIf="thirdRank">
      <div class="topper-details third text-center">
        <figure
          class="topper-avatar mx-auto"
          [ngClass]="{
          'most-lessons-avatar': keyName === 'topLessonsCompleted',
          'most-time-avatar': keyName === 'topUser',
          'most-questions-avatar': keyName === 'topQuestionAsked',
        }"
        >
          <span class="fw-normal white-space-nowrap">{{
            thirdRank?.firstName[0].toUpperCase()
          }}</span>
        </figure>
        <h6 class="topper-title mx-auto">
          {{ thirdRank?.firstName | titlecase }}
        </h6>
        <p class="topper-email text-truncate w-100 mx-auto">
          {{ thirdRank?.email }}
        </p>
        <span class="toppper-time">{{
          keyName == "topUser"
            ? (thirdRank?.active_hours | number:'1.1-1') + "min"
            : keyName == "topLessonsCompleted"
            ? thirdRank?.lesson_completed
            : thirdRank?.topQuestionAsked
        }}</span>
      </div>
      <div class="topper third">
        <span class="topper-numbering">3</span>
      </div>
    </div>
  </div>
</div>

<ul class="student-list my-0">
  <ng-container *ngIf="topRecord?.length">
    <li
      class="student-item d-flex align-items-center flex-nowrap gap-2"
      *ngFor="let student of topRecord; let i = index"
    >
      <div class="student-fig-wrapper flex-shrink-0">
        <figure class="avatar m-0">
          {{ student?.firstName[0].toUpperCase() }}
        </figure>
        <span class="student-fig-number">{{ i + 4 }}</span>
      </div>
      <div class="flex-grow-1 overflow-hidden">
        <div class="text-truncate">{{ student?.firstName | titlecase }}</div>
        <p class="small text-truncate m-0">{{ student?.email }}</p>
      </div>
      <span class="text-nowrap">{{ student?.active_hours | number:'1.1-1' }}</span>
    </li>
  </ng-container>
  
  <ng-container *ngIf="!topRecord?.length && !firstRank">
    <div class="text-danger text-center">No Records Found</div>
  </ng-container>
</ul>
<!-- <p-overlayPanel #op>
  <div class="custom-overlay-content">
    <div class="avatar-wrapper">
      <img src="" alt="Avatar" class="avatar" />
    </div>
    <div class="user-details">
      <div class="name">Lydia Wilson</div>
      <div class="email">itslydia@algoed.com</div>
      <div class="time">30min</div>
    </div>
  </div>
</p-overlayPanel> -->
