import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
  selector: 'app-invite-team-member',
  templateUrl: './invite-team-member.component.html',
  styleUrls: ['./invite-team-member.component.scss'],
})
export class InviteTeamMemberComponent {
  @Input() teamID: number;
  @Input() memberData: any;

  @Output() getUpdatedTeamMembers: EventEmitter<any> = new EventEmitter<any>();

  emailToInvite: string;
  isEmailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private commonService: CommonServiceService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    if (!this.teamID) {
      this.teamID = this.config.data?.teamID;
      this.memberData = this.config.data?.memberData;
    }
  }

  inviteTeamMember() {
    this.emailToInvite = this.emailToInvite && this.emailToInvite.toLowerCase();
    if (
      !this.emailToInvite ||
      !this.emailToInvite.match(this.isEmailRegex) ||
      this.memberData.member_count >= this.memberData.max_team_members
    ) {
      if (!this.emailToInvite) {
        this.commonService.showToast('Please enter email', 'warn', 1000);
      } else if (!this.emailToInvite.match(this.isEmailRegex)) {
        this.commonService.showToast('Please enter valid email', 'warn', 1000);
      } else {
        this.commonService.showToast(
          'Maximum team size achieved',
          'warn',
          1000
        );
      }
      return;
    }
    const finalObj = {
      isCategorList: true,
      name: { email: this.emailToInvite },
    };
    this.commonService
      .saveRecord(
        `users/competitions/teams/invitemember/${this.teamID}`,
        finalObj
      )
      .subscribe(
        (res) => {
          this.commonService.showToast(
            'Invitation sent successfully',
            'success',
            1000
          );
          this.emailToInvite = '';
          if (this.config.data?.teamID) {
            this.ref.close();
          }
          this.getUpdatedTeamMembers.emit();
        },
        (error) => {
          console.log(error.error.error);
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            1000
          );
        }
      );
  }
}
