import { ChartOptions } from 'chart.js';
import { toFont } from 'chart.js/helpers';
class ChartConstants {
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
}

export const verticalBarHoverPlugin = {
  id: 'myChart',
  afterDraw: (chart) => {
    if (chart.tooltip && chart.tooltip.getActiveElements().length > 0) {
      const ctx = chart.ctx;
      const activeElement = chart.tooltip.getActiveElements()[0];
      const { element } = activeElement;
      const { x, y } = element.getCenterPoint();
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      const width = chart.scales.x.width / chart.data.labels.length;

      // Create gradient
      const gradient = ctx.createLinearGradient(0, bottomY, 0, topY);
      gradient.addColorStop(0, 'rgba(255, 99, 132, 0.8)'); // dark color at the bottom
      gradient.addColorStop(1, 'rgba(255, 99, 132, 0)'); // transparent color at the top

      // Draw rectangle with gradient
      ctx.save();
      ctx.fillStyle = gradient;
      ctx.fillRect(x - width / 2, topY, width, bottomY - topY);
      ctx.restore();
    }
  },
};

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map((b) => b.lines);

    const tableHead = titleLines
      .map((title) => `<tr><th>${title}</th></tr>`)
      .join('');
    const tableBody = bodyLines
      .map((body) => `<tr><td>${body}</td></tr>`)
      .join('');

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = `${tableHead}${tableBody}`;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transition = 'opacity 0.2s ease';
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.padding =
    tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
  let fadeOutTimeout;

  // Inside the part of your handler where you hide the tooltip:
  if (fadeOutTimeout) clearTimeout(fadeOutTimeout);
  fadeOutTimeout = setTimeout(() => {
    tooltipEl.style.opacity = '0'; // Fade out
  }, 200);

  // And clear the timeout if the tooltip becomes active again:
  if (tooltipModel.opacity === 1) {
    if (fadeOutTimeout) clearTimeout(fadeOutTimeout);
    tooltipEl.style.opacity = '1'; // Fade in
  }
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
  tooltipEl.style.borderRadius = '8px';
  tooltipEl.style.color = 'black';
  tooltipEl.style.boxShadow = '0px 6px 10px rgba(0, 0, 0, 0.3)';
  tooltipEl.style.minWidth = '100px';
  tooltipEl.style.minHeight = '50px';
  const bodyFont = toFont(tooltipModel.options.bodyFont);
  const bodyFontString =
    bodyFont.string || `${bodyFont.size}px ${bodyFont.family}`;
  tooltipEl.style.font = bodyFontString;
};

export const chartConstants = new ChartConstants();
